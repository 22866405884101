
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลกล้องวงจรปิดของสถานตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-8 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    สามารถเพิ่ม,แก้ไข,ลบข้อมูล ได้ในหน้านี้ (สามารถกดที่เครื่องหมาย <i class="fa fa-caret-right" aria-hidden="true"></i> ที่คอลั่ม หมายเลขอ้างอิง เพื่อดูข้อมูลเพิ่มเติม)
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <button @click="getadd()" class="btn btn-primary"><i class="fa fa-search"></i> เพิ่มข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model data log -->
              <div class="modal fade" id="model-add" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">เพิ่มข้อมูล</b>
                            <button type="button" @click="closeModaladd()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tro_name_id_value" :searchable="true" v-model="dataadd[0]" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ยี่ห้อ</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[1]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >รุ่นกล้อง</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[2]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อโฮสต์</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[3]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >HTTP พอร์ต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[4]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >RTSP พอร์ต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[5]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >Username</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[6]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >Password</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[7]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >บริษัทที่ทำการติดตั้ง</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[8]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ละติจูต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[9]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ลองจิจูต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataadd[10]' class="form-control" type="text">
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="adddata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaladd()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model data log -->
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">แก้ไขข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tro_name_id_value" :searchable="true" v-model="dataedit[0]" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ยี่ห้อ</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[1]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >รุ่นกล้อง</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[2]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อโฮสต์</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[3]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >HTTP พอร์ต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[4]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >RTSP พอร์ต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[5]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >Username</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[6]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >Password</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[7]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >บริษัทที่ทำการติดตั้ง</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[8]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ละติจูต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[9]' class="form-control" type="text">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ลองจิจูต</label>
                                <div class="col-sm-8">
                                  <input v-model='dataedit[10]' class="form-control" type="text">
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                  <th>หมายเลขอ้างอิง</th>
                                  <th>ชื่อสถานตรวจสภาพรถ</th>
                                  <th class="none">ยี่ห้อ</th>
                                  <th class="none">ชื่อโฮสต์</th>
                                  <th class="none">http พอร์ท</th>
                                  <th>Username</th>
                                  <th>รหัสผ่าน</th>
                                  <th class="none">rtsp พอร์ท</th>
                                  <th class="none">บริษัทที่ทำการติดตั้ง</th>
                                  <th>รุ่นกล้อง</th>
                                  <th class="none">ละติจูต</th>
                                  <th class="none">ลองจิจูต</th>
                                  <th class="none">สถานะ ออนไลน์/ออฟไลน์</th>
                                  <th class="none">เข้าระบบล่าสุดเมื่อ</th>
                                  <th>จัดการ</th>
                                </tr>
                            </thead>
                            <template #column-14="props">
                              <div class="btn-group">
                                <button @click="login(props.cellData,props.rowData)" type="button" class="btn btn-info"><i class="fa fa-sign-in" aria-hidden="true"></i></button>
                                <button @click="geteditdata(props.cellData,props.rowData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_add: null,
    modal_edit: null,
})
function openModaladd(){ state.modal_add.show()}
function closeModaladd(){state.modal_add.hide()}
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const dataadd = ref([]);
const dataedit = ref([]);
const dataeditid = ref('');

const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}

onMounted(() => {
  getdata_table();
  state.modal_add = new Modal('#model-add', {});
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`cctv`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([
        res.data.datas[i].id,
        res.data.datas[i].nameaddresscar,
        res.data.datas[i].brand,
        res.data.datas[i].hostname,
        res.data.datas[i].port,
        res.data.datas[i].username,
        res.data.datas[i].password,
        res.data.datas[i].rtsp_port,
        res.data.datas[i].company,
        res.data.datas[i].version,
        res.data.datas[i].lat,
        res.data.datas[i].lon,
        res.data.datas[i].status_check == 1?'<h6><span class=\"badge badge-success\">ออนไลน์</span></h6>':'<h6><span class=\"badge badge-danger\">ออฟไลน์</span></h6>',
        new Date(res.data.datas[i].login_last).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),
        res.data.datas[i].id,
      ]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

async function getadd(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    if(tro_name_id_value.value.length >= 1){
      Swal.close()
      openModaladd()
    }else{
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id
      });
      if(res.data.status == 1){
        tro_name_id_value.value = res.data.datas.datag4;
        Swal.close()
        openModaladd()
      }
    }
}

async function adddata() {
  if(!dataadd.value[0] || !dataadd.value[1] || !dataadd.value[2] || !dataadd.value[3] || !dataadd.value[4] || !dataadd.value[5] || !dataadd.value[6] || !dataadd.value[7]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const res = await axios.post(`cctv`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 3,
      datas: [dataadd.value[0],dataadd.value[1],dataadd.value[2],dataadd.value[3],dataadd.value[4],dataadd.value[5],dataadd.value[6],dataadd.value[7],dataadd.value[8],dataadd.value[9],dataadd.value[10]],
    });
    if(res.data.status == 1){
      dataadd.value = []
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaladd()
      Swal.fire({
        title: "สำเร็จ",
        text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เพิ่มข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

async function geteditdata(id,data) {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    if(tro_name_id_value.value.length >= 1){
        dataeditid.value = ''
        dataedit.value = []
        dataeditid.value = id
        dataedit.value[0] = {nameaddresscar: data[1]}
        dataedit.value[1] = data[9]
        dataedit.value[2] = data[2]
        dataedit.value[3] = data[3]
        dataedit.value[4] = data[4]
        dataedit.value[5] = data[7]
        dataedit.value[6] = data[5]
        dataedit.value[7] = data[6]
        dataedit.value[8] = data[8]
        dataedit.value[9] = data[10]
        dataedit.value[10] = data[11]
        Swal.close();
        openModaledit()
    }else{
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id
      });
      if(res.data.status == 1){
        tro_name_id_value.value = res.data.datas.datag4;
        dataeditid.value = ''
        dataedit.value = []
        dataeditid.value = id
        dataedit.value[0] = {nameaddresscar: data[1]}
        dataedit.value[1] = data[9]
        dataedit.value[2] = data[2]
        dataedit.value[3] = data[3]
        dataedit.value[4] = data[4]
        dataedit.value[5] = data[7]
        dataedit.value[6] = data[5]
        dataedit.value[7] = data[6]
        dataedit.value[8] = data[8]
        dataedit.value[9] = data[10]
        dataedit.value[10] = data[11]
        Swal.close();
        openModaledit()
      }
    }
}

async function editdata() {
  // console.log(dataedit.value[0])
  if(!dataedit.value[1]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`cctv`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 2,
      datas: [dataeditid.value,dataedit.value[0],dataedit.value[1],dataedit.value[2],dataedit.value[3],dataedit.value[4],dataedit.value[5],dataedit.value[6],dataedit.value[7],dataedit.value[8],dataedit.value[9],dataedit.value[10]],
    });
    if(res.data.status == 1){
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaledit()
      Swal.fire({
        title: "สำเร็จ",
        text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "แก้ไขข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 5,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

async function login(id,data){
  Swal.fire({
    title: "ต้องการเข้าสู่ระบบ ?",
    text: "ท่านต้องนำข้อมูล Username และ Password ของโฮสนั้นไปกรอกให้ถูกต้องด้วย",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`cctv`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 4,
        datas: [id],
      });
      if(res.data.status == 1){
        Swal.fire({
          title:'อัพเดทข้อมูลการเข้าสู่ระบบเรียบร้อยแล้ว !', 
          text: 'กำลังพาท่านไปยังโฮส ...',
          icon:'success', 
        }).then(() => {
                window.open( data[3],'_blank')
        });
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "พาท่านไปยังโฮสไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
  console.log(data[3])
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>