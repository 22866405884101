
<template>
    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลผู้ตรวจสภาพรถ (ลาออก / ถูกยกเลิกบัตร)"/>

    <div class="row project-cards">
        <div class="col-md-12 project-list">
            <div class="card">
                <div class="mb-2 row">
                    <div class="col-md-6 d-flex">
                        <ul class="nav nav-tabs border-tab" role="tablist">
                            <li class="nav-item">
                                สามารถเพิ่ม,แก้ไข,ลบข้อมูล ได้ในหน้านี้
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <button @click="getadd()" class="btn btn-primary"><i class="fa fa-search"></i> เพิ่มข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
          <!-- model data log -->
          <div class="modal fade" id="model-add" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">เพิ่มข้อมูล</b>
                        <button type="button" @click="closeModaladd()" class="btn-close" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col">
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">RFID CARD</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="inspector_id1">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เลขที่บัตรผู้ตรวจสภาพ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="inspector_num">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <div class="form-group row col-sm-6">
                                <label class="col-sm-4 col-form-label">วันที่ได้รับใบอนุญาต</label>
                                <div class="col-sm-8">
                                <VueDatePicker locale="th" v-model="authorized_date" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                            <div class="form-group row col-sm-6">
                                <label class="col-sm-4 col-form-label">วันที่ใบอนุญาตสิ้นอายุ</label>
                                <div class="col-sm-8">
                                <VueDatePicker locale="th" v-model="exp_date" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <div class="col-sm-9 offset-sm-3">
                                    <div class="ribbon-wrapper card">
                                        <div class="card-body">
                                            <div class="ribbon ribbon-bookmark ribbon-primary">หมายเหตุ</div>
                                            <p><b>กรณีเป็นผู้ควบคุมการตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวช่องบนช่องเดียว</p>
                                            <p><b>กรณีเป็นผู้ควบคุมการตรวจสภาพรถจักรยานยนต์ และเจ้าหน้าที่ตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวผู้ควบคุมการตรวจสภาพรถจักรยานยนต์ ช่องบน และเลขบัตรประจำตัวเจ้าหน้าที่ตรวจสภาพรถช่องล่าง</p>
                                            <p><b>กรณีเป็นเจ้าหน้าที่ตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวช่องบนช่องเดียว</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">ประเภทของผู้ตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="license_type" >
                                        <option value="">กรุณาเลือก</option>
                                        <option value ='1'>ผู้ควบคุมการตรวจสภาพรถ</option>
                                        <option value ='2'>เจ้าหน้าที่ตรวจสภาพรถ</option>
                                        <option value ='3'>ผู้ควบคุมการตรวจสภาพรถจักรยานยนต์</option>
                                        <option value ='4'>เจ้าหน้าที่ตรวจสภาพรถจักรยานยนต์</option>
                                </select>
                            </div>
                          </div>
                            <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">คำนำหน้านาม</label>
                            <div class="col-sm-9">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix" value="นาย">นาย</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix" value="นาง">นาง</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix" value="นางสาว">นางสาว</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix" value="อื่นๆ">อื่นๆ</label>
                                    <input class="form-control" type="text" v-model="prefix_other" placeholder="หากเลือกอื่นๆกรุณากรอกข้อมูลเพิ่มเติม" :disabled="!prefix">
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ชื่อ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="first_name">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">นามสกุล</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="last_name">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เลขที่บัตรประชาชน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="idcard">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">กำหนดชื่อผู้ใช้งาน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="username" >
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">กำหนดรหัสผ่าน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="password" v-model="password" >
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">วุฒิการศึกษา</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="educational" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">มัธยมศึกษาตอนต้น(ม.3)</option>
                                        <option value="2">มัธยมศึกษาตอนปลาย(ม.6)</option>
                                        <option value="3">ประกาศณียบัตรวิชาชีพ (ปวช)</option>
                                        <option value="4">ประกาศณียบัตรวิชาชีพชั้นสูง (ปวส)</option>
                                        <option value="5">ประกาศณียบัตรวิชาชีพทางเทคนิค (ปวท.)</option>
                                        <option value="6">ประกาศณียบัตรประโยคมัธยมศึกษาตอนปลายสายอาชีพ</option>
                                        <option value="7">อนุปริญญา</option>
                                        <option value="8">ปริญญาตรี</option>
                                        <option value="9">ปริญญาโท</option>
                                        <option value="10">ปริญญาเอก</option>
                                        <option value="11">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">วุฒิการศึกษาอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="educational_other">
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">สาขา</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="educational_branch" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">วิศวกรรมยานยนต์</option>
                                        <option value="2">วิศวกรรมเครื่องกล</option>
                                        <option value="3">วิศวกรรมขนส่ง</option>
                                        <option value="4">วิศวกรรมสิ่งแวดล้อม</option>
                                        <option value="5">วิศวกรรมเครื่องกล</option>
                                        <option value="6">วิศวกรรมไฟฟ้า</option>
                                        <option value="7">วิศวกรรมอิเลกทรอนิกส์</option>
                                        <option value="8">วิศวกรรมอุตสาหการ</option>
                                        <option value="9">ช่างยนต์</option>
                                        <option value="10">ช่างกลโรงงาน</option>
                                        <option value="11">ช่างไฟฟ้า</option>
                                        <option value="12">ช่างอิเลกทรอนิกส์</option>
                                        <option value="13">ด้านเทคนิคยานยนต์</option>
                                        <option value="14">เทคนิคเขียนแบบวิศวกรรมเครื่องกลหรือช่างยนต์</option>
                                        <option value="15">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สาขาอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="educational_branch_other">
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">หลักสูตร</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="course" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ ของวิทยาลัยสารพัดช่าง สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                        <option value="2">ระยะสั้น แผนกช่างยนต์ วิชาช่างเครื่องยนต์ ระยะเวลาเรียน 600 ชม. ของโรงเรียนฝึกอาชีพกรุงเทพมหานคร (ดินแดง ๑)</option>
                                        <option value="3">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ ของวิทยาลัยเทคนิค สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                        <option value="4">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">หลักสูตรอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="course_other">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สถาบันที่ศึกษาเพิ่มเติม</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="institute_info">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ผ่านการอบรมและทดสอบจากกรมการขนส่งทางบก รุ่นที่/ปี</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="training_generation">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ที่อยู่ผู้ตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="car_inspector_address"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">จังหวัด/เขต(ที่กำกับดูแล)</label>
                            <div class="col-sm-9">
                                <multiselect :options="searchcounty_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" @select="onSelectCountyEdit"/>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เบอร์โทรศัพท์</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="tel">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เบอร์มือถือ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="phone">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">รูปถ่าย <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file" @change="B64img1"  accept="image/*">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">รูปบัตรช่างผู้ตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file" @change="B64img2" accept="image/*">
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">ปัจจุบันทำงานอยู่ที่ สถานตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                              <multiselect :options="tro_name_id_value" :searchable="true" v-model="manageinspectioncenter_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ตั้งแต่ วันที่</label>
                            <div class="col-sm-9">
                              <VueDatePicker locale="th" v-model="manageinspectioncenter_date" auto-apply :enable-time-picker="false"/>
                            </div>
                          </div>
                                  <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สถานะ</label>
                            <div class="col-sm-9">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" :value="0">ระงับการใช้งาน</label>
                                      <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" value="1">ใช้งานปกติ</label>
                                      <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" value="2">ลาออก</label>
                                      <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" value="3">ถูกยกเลิกบัตร</label>
                                      <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" value="4">สิ้นสุดใบอนุญาต(เปลี่ยนประเภท)</label>
                                      <label class="d-block"><input class="radio_animated" type="radio"  v-model="status" value="5">สิ้นสุดใบอนุญาต(ขาดการต่ออายุ)</label>
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ประวัติการทำงาน</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="history"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ประวัติการกระทำผิด</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="wrongdoing"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ข้อมูลหรือเอกสารอื่นๆ ตามที่กรมการขนส่งทางบกกำหนดรูปแบบไฟล์ pdf <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file"  @change="B64pdf" accept="application/pdf">
                            </div>
                          </div>
                        </div>
                      </div> 
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="adddata()" class="btn btn-primary">บันทึกข้อมูล</button>
                      <button type="button" @click="closeModaladd()" class="btn btn-danger" >ปิด</button>
                    </div>
                </div>
            </div>
          </div>
          <!-- end model data log -->
          <!-- model search -->
          <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">แก้ไขข้อมูล</b>
                        <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col">
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">RFID CARD</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="inspector_id1_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เลขที่บัตรผู้ตรวจสภาพ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="inspector_num_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <div class="form-group row col-sm-6">
                                <label class="col-sm-4 col-form-label">วันที่ได้รับใบอนุญาต</label>
                                <div class="col-sm-8">
                                <VueDatePicker locale="th" v-model="authorized_date_edit" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                            <div class="form-group row col-sm-6">
                                <label class="col-sm-4 col-form-label">วันที่ใบอนุญาตสิ้นอายุ</label>
                                <div class="col-sm-8">
                                <VueDatePicker locale="th" v-model="exp_date_edit" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <div class="col-sm-9 offset-sm-3">
                                    <div class="ribbon-wrapper card">
                                        <div class="card-body">
                                            <div class="ribbon ribbon-bookmark ribbon-primary">หมายเหตุ</div>
                                            <p><b>กรณีเป็นผู้ควบคุมการตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวช่องบนช่องเดียว</p>
                                            <p><b>กรณีเป็นผู้ควบคุมการตรวจสภาพรถจักรยานยนต์ และเจ้าหน้าที่ตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวผู้ควบคุมการตรวจสภาพรถจักรยานยนต์ ช่องบน และเลขบัตรประจำตัวเจ้าหน้าที่ตรวจสภาพรถช่องล่าง</p>
                                            <p><b>กรณีเป็นเจ้าหน้าที่ตรวจสภาพรถ</b> ให้ใส่เลขที่บัตรประจำตัวช่องบนช่องเดียว</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">ประเภทของผู้ตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="license_type_edit" >
                                        <option value="">กรุณาเลือก</option>
                                        <option value ='1'>ผู้ควบคุมการตรวจสภาพรถ</option>
                                        <option value ='2'>เจ้าหน้าที่ตรวจสภาพรถ</option>
                                        <option value ='3'>ผู้ควบคุมการตรวจสภาพรถจักรยานยนต์</option>
                                        <option value ='4'>เจ้าหน้าที่ตรวจสภาพรถจักรยานยนต์</option>
                                </select>
                            </div>
                          </div>
                            <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">คำนำหน้านาม</label>
                            <div class="col-sm-9">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix_edit" value="นาย">นาย</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix_edit" value="นาง">นาง</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix_edit" value="นางสาว">นางสาว</label>
                                    <label class="d-block"><input class="radio_animated" type="radio"  v-model="prefix_edit" value="อื่นๆ">อื่นๆ</label>
                                    <input class="form-control" type="text" v-model="prefix_other_edit" placeholder="หากเลือกอื่นๆกรุณากรอกข้อมูลเพิ่มเติม" :disabled="!prefix_edit">
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ชื่อ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="first_name_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">นามสกุล</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="last_name_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เลขที่บัตรประชาชน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="idcard_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">กำหนดชื่อผู้ใช้งาน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="username_edit" >
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">กำหนดรหัสผ่าน</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="password" v-model="password_edit" >
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">วุฒิการศึกษา</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="educational_edit" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">มัธยมศึกษาตอนต้น(ม.3)</option>
                                        <option value="2">มัธยมศึกษาตอนปลาย(ม.6)</option>
                                        <option value="3">ประกาศณียบัตรวิชาชีพ (ปวช)</option>
                                        <option value="4">ประกาศณียบัตรวิชาชีพชั้นสูง (ปวส)</option>
                                        <option value="5">ประกาศณียบัตรวิชาชีพทางเทคนิค (ปวท.)</option>
                                        <option value="6">ประกาศณียบัตรประโยคมัธยมศึกษาตอนปลายสายอาชีพ</option>
                                        <option value="7">อนุปริญญา</option>
                                        <option value="8">ปริญญาตรี</option>
                                        <option value="9">ปริญญาโท</option>
                                        <option value="10">ปริญญาเอก</option>
                                        <option value="11">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">วุฒิการศึกษาอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="educational_other_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">สาขา</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="educational_branch_edit" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">วิศวกรรมยานยนต์</option>
                                        <option value="2">วิศวกรรมเครื่องกล</option>
                                        <option value="3">วิศวกรรมขนส่ง</option>
                                        <option value="4">วิศวกรรมสิ่งแวดล้อม</option>
                                        <option value="5">วิศวกรรมเครื่องกล</option>
                                        <option value="6">วิศวกรรมไฟฟ้า</option>
                                        <option value="7">วิศวกรรมอิเลกทรอนิกส์</option>
                                        <option value="8">วิศวกรรมอุตสาหการ</option>
                                        <option value="9">ช่างยนต์</option>
                                        <option value="10">ช่างกลโรงงาน</option>
                                        <option value="11">ช่างไฟฟ้า</option>
                                        <option value="12">ช่างอิเลกทรอนิกส์</option>
                                        <option value="13">ด้านเทคนิคยานยนต์</option>
                                        <option value="14">เทคนิคเขียนแบบวิศวกรรมเครื่องกลหรือช่างยนต์</option>
                                        <option value="15">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สาขาอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="educational_branch_other_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">หลักสูตร</label>
                            <div class="col-sm-9">
                                <select class="selectpicker form-control" data-live-search="true" v-model="course_edit" >
                                        <option value="null" selected>กรุณาเลือก</option>
                                        <option value="1">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ ของวิทยาลัยสารพัดช่าง สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                        <option value="2">ระยะสั้น แผนกช่างยนต์ วิชาช่างเครื่องยนต์ ระยะเวลาเรียน 600 ชม. ของโรงเรียนฝึกอาชีพกรุงเทพมหานคร (ดินแดง ๑)</option>
                                        <option value="3">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ ของวิทยาลัยเทคนิค สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                        <option value="4">อื่น ๆ</option>
                                </select>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">หลักสูตรอื่น ๆ โปรดระบุ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="course_other_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สถาบันที่ศึกษาเพิ่มเติม</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="institute_info_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ผ่านการอบรมและทดสอบจากกรมการขนส่งทางบก รุ่นที่/ปี</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="training_generation_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ที่อยู่ผู้ตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="car_inspector_address_edit"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">จังหวัด/เขต(ที่กำกับดูแล)</label>
                            <div class="col-sm-9">
                                <multiselect :options="searchcounty_value" :searchable="true" v-model="county_id_edit" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" @select="onSelectCountyEdit"/>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เบอร์โทรศัพท์</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="tel_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">เบอร์มือถือ</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" v-model="phone_edit">
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">รูปถ่าย <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file" @change="B64img1"  accept="image/*">
                              <a v-if="img_profile_edit" class="mt-1" :href="storeAuth.UrlImagesTechProfile+img_profile_edit" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">รูปบัตรช่างผู้ตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file" @change="B64img2" accept="image/*">
                              <a v-if="manageusers_pic_card_edit" class="mt-1" :href="storeAuth.UrlImagesTechCard+manageusers_pic_card_edit" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                            </div>
                          </div>
                          <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label">ปัจจุบันทำงานอยู่ที่ สถานตรวจสภาพรถ</label>
                            <div class="col-sm-9">
                              <multiselect :options="tro_name_id_value" :searchable="true" v-model="manageinspectioncenter_id_edit" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ตั้งแต่ วันที่</label>
                            <div class="col-sm-9">
                              <VueDatePicker locale="th" v-model="manageinspectioncenter_date_edit" auto-apply :enable-time-picker="false"/>
                            </div>
                          </div>
                                  <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">สถานะ</label>
                            <div class="col-sm-9">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="0">ระงับการใช้งาน</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="1">ใช้งานปกติ</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="2">ลาออก</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="3">ถูกยกเลิกบัตร</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="4">สิ้นสุดใบอนุญาต(เปลี่ยนประเภท)</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="status_edit" value="5">สิ้นสุดใบอนุญาต(ขาดการต่ออายุ)</label>
                                </div>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ประวัติการทำงาน</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="history_edit"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ประวัติการกระทำผิด</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" rows="3" v-model="wrongdoing_edit"></textarea>
                            </div>
                          </div>
                          <div class="mb-2 row">
                            <label class="col-sm-3 col-form-label">ข้อมูลหรือเอกสารอื่นๆ ตามที่กรมการขนส่งทางบกกำหนดรูปแบบไฟล์ pdf <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                            <div class="col-sm-9">
                              <input class="form-control" type="file"  @change="B64pdf" accept="application/pdf">
                              <a v-if="file_other_edit" class="mt-1" :href="storeAuth.UrlFileTech+file_other_edit" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                            </div>
                          </div>
                        
                        </div>
                      </div> 
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                      <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                    </div>
                </div>
            </div>
          </div>
          <!-- end model search -->
            <div class="card">
                <div class="card-body">
                    <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                        <thead>
                          <tr>
                            <th>หมายเลขอ้างอิง</th>
                            <th>เลขที่ประจำตัวผู้ตรวจสภาพ</th>
                            <th>ชื่อ</th>
                            <th>นามสกุล</th>
                            <th>สถานะ</th>
                            <th class="none">วันที่ได้รับอนุญาต</th>
                            <th>วันสิ้นอายุใบอนุญาต</th>
                            <th>ชื่อผู้ใช้งาน</th>
                            <th class="none">เลขที่บัตรประชาชน</th>
                            <th>สถานตรวจสภาพที่ทำงานปัจจุบัน</th>
                            <th class="none">เลขที่ใบอนุญาตของสถานตรวจสภาพที่ทำงานปัจจุบัน</th>
                            <th>จังหวัดที่ผู้ตรวจสภาพรถทำงานอยู่</th>
                            <th class="none">ภาพโปรไฟล์ของผู้ตรวจสถาพรถ</th>
                            <th class="none">ภาพบัตรช่างของผู้ตรวจสถาพรถ</th> 
                            <th class="none">เบอร์โทรศัพท์</th>
                            <th class="none">ไฟล์ PDF ที่แนบมา</th>
                            <th>จัดการ</th>
                          </tr>
                        </thead>
                        <template #column-16="props">
                          <div class="btn-group">
                            <button @click="geteditdata(props.cellData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                            <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                          </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
  </div>
<!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
modal_add: null,
modal_edit: null,
modal_del: null,
})
function openModaladd(){ state.modal_add.show()}
function closeModaladd(){state.modal_add.hide()}
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
pdfMake.fonts = {
Sarabun: {
  normal: 'Sarabun-Regular.ttf',
  bold: 'Sarabun-Medium.ttf',
  italics: 'Sarabun-Italic.ttf',
  bolditalics: 'Sarabun-MediumItalic.ttf'  
}
};
doc.defaultStyle.font = "Sarabun";
var i = 1;
}

const datatable = ref([]);
const dataeditid = ref('');
const dataedituser = ref('');

const inspector_id1 = ref('');
const inspector_num = ref('');
const authorized_date = ref('');
const exp_date = ref('');
const license_type = ref('');
const prefix = ref('');
const prefix_other = ref('');
const first_name = ref('')
const last_name = ref('');
const idcard = ref('');
const username = ref('');
const password = ref('');
const educational = ref(null);
const educational_other = ref('');
const educational_branch = ref(null);
const educational_branch_other = ref('');
const course = ref(null);
const course_other = ref('');
const institute_info = ref(''); const training_generation = ref(''); const car_inspector_address = ref('');
const county_id = ref(''); const tel = ref(''); const phone = ref('');
const img_profile = ref(''); const manageusers_pic_card = ref(''); const manageinspectioncenter_id = ref('');
const manageinspectioncenter_date = ref(''); const status = ref('1'); const history = ref('');
const wrongdoing = ref(''); const file_other = ref('');

const inspector_id1_edit = ref('');
const inspector_num_edit = ref('');
const authorized_date_edit = ref('');
const exp_date_edit = ref('');
const license_type_edit = ref('');
const prefix_edit = ref('');
const prefix_other_edit = ref('');
const first_name_edit = ref('');
const last_name_edit = ref('');
const idcard_edit = ref('');
const username_edit = ref('');
const password_edit = ref('');
const educational_edit = ref('');
const educational_other_edit = ref('');
const educational_branch_edit = ref('');
const educational_branch_other_edit = ref('');
const course_edit = ref('');
const course_other_edit = ref('');
const institute_info_edit = ref(''); const training_generation_edit = ref(''); const car_inspector_address_edit = ref('');
const county_id_edit = ref(''); const tel_edit = ref(''); const phone_edit = ref('');
const img_profile_edit = ref(''); const manageusers_pic_card_edit = ref(''); const manageinspectioncenter_id_edit = ref('');
const manageinspectioncenter_date_edit = ref(''); const status_edit = ref(''); const history_edit = ref('');
const wrongdoing_edit = ref(''); const file_other_edit = ref('');

const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}
const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}

onMounted(() => {
getdata_table();
state.modal_add = new Modal('#model-add', {});
state.modal_edit = new Modal('#model-edit', {});
})

function  B64img1(event) {
let input = event.target;
if (input.files && input.files[0]) {
if((input.files[0].size/1024/1024).toFixed(2) <= 10){
  let reader = new FileReader();
    reader.onload = (e) => {
      img_profile.value = e.target.result;
    // console.log(e.target.result)
  }
  reader.readAsDataURL(input.files[0]);
}
}
}

function  B64img2(event) {
let input = event.target;
if (input.files && input.files[0]) {
if((input.files[0].size/1024/1024).toFixed(2) <= 10){
  let reader = new FileReader();
    reader.onload = (e) => {
      manageusers_pic_card.value = e.target.result;
    // console.log(e.target.result)
  }
  reader.readAsDataURL(input.files[0]);
}
}
}

function  B64pdf(event) {
let input = event.target;
if (input.files && input.files[0]) {
if((input.files[0].size/1024/1024).toFixed(2) <= 10){
  let reader = new FileReader();
    reader.onload = (e) => {
      file_other.value = e.target.result;
    // console.log(e.target.result)
  }
  reader.readAsDataURL(input.files[0]);
}
}
}

async function getdata_table(){
Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()

const res = await axios.post(`usertechnicianfalse`,{
uid: storeAuth.id,
gid: storeAuth.group_id,
tid: 1,
});
if(res.data.status == 1){
for (let i = 0; i < res.data.datas.length; i++) {
  let status_detail = '';
  if(res.data.datas[i].status == 2){
    status_detail = 'ลาออก'
  }else if(res.data.datas[i].status == 3){
    status_detail = 'ถูกยกเลิกบัตร'
  }else if(res.data.datas[i].status == 4){
    status_detail = 'สิ้นสุดใบอนุญาต(เปลี่ยนประเภท)'
  }else if(res.data.datas[i].status == 5){
    status_detail = 'สิ้นสุดใบอนุญาต(ขาดการต่ออายุ)'
  }
  datatable.value.push([res.data.datas[i].id,res.data.datas[i].inspector_num,res.data.datas[i].first_name,res.data.datas[i].last_name,status_detail,new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),res.data.datas[i].username,res.data.datas[i].idcard,res.data.datas[i].nameaddresscar,res.data.datas[i].license_number,res.data.datas[i].names,
  res.data.datas[i].img_profile?`<a href="${storeAuth.UrlImagesTechProfile}${res.data.datas[i].img_profile}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
  res.data.datas[i].img_addresscar?`<a href="${storeAuth.UrlImagesTechCard}${res.data.datas[i].img_addresscar}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
  res.data.datas[i].tel,
  res.data.datas[i].file_other?`<a href="${storeAuth.UrlFileTech}${res.data.datas[i].file_other}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
  res.data.datas[i].id]);
}
Swal.close();
}else{
Swal.close()
}
}

async function getadd(){
Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()
if(searchcounty_value.value.length > 1){
  Swal.close()
  openModaladd()
}else{
  const res = await axios.post(`getdatash`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id
  });
  if(res.data.status == 1){
    searchcounty_value.value = res.data.datas.datactall;
    tro_name_id_value.value = res.data.datas.datag4;
    Swal.close()
    openModaladd()
  }
}
}

async function adddata() {
if(!username.value || !password.value || !inspector_num.value || !authorized_date.value || !exp_date.value || !license_type.value || !first_name.value || !last_name.value || !county_id.value || !manageinspectioncenter_id.value || !educational.value || !manageinspectioncenter_date.value){
Swal.fire({
    title: "คำเตือน",
    text: "กรุณากรอกข้อมูลให้ครบถ้วน",
    icon: "warning"
}); 
}else{
Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()
const res = await axios.post(`usertechnicianfalse`,{
  uid: storeAuth.id,
  gid: storeAuth.group_id,
  tid: 3,
  datas: [
      inspector_id1.value,
      inspector_num.value,
      authorized_date.value,
      exp_date.value,
      license_type.value,
      prefix.value,
      prefix_other.value,
      first_name.value,
      last_name.value,
      idcard.value,
      username.value,
      password.value,
      educational.value,
      educational_other.value,
      educational_branch.value,
      educational_branch_other.value,
      course.value,
      course_other.value,
      institute_info.value, training_generation.value, car_inspector_address.value,
      county_id.value, tel.value, phone.value,
      img_profile.value, manageusers_pic_card.value, manageinspectioncenter_id.value,
      manageinspectioncenter_date.value, status.value, history.value,
      wrongdoing.value, file_other.value,
    ]
});
if(res.data.status == 1){

  inspector_id1.value = '';
  inspector_num.value = '';
  authorized_date.value = '';
  exp_date.value = '';
  license_type.value = '';
  prefix.value = '';
  prefix_other.value = '';
  first_name.value = '';
  last_name.value = '';
  idcard.value = '';
  username.value = '';
  password.value = '';
  educational.value = '';
  educational_other.value = '';
  educational_branch.value = '';
  educational_branch_other.value = '';
  course.value = '';
  course_other.value = '';
  institute_info.value = ''; training_generation.value = ''; car_inspector_address.value = '';
  county_id.value = ''; tel.value = ''; phone.value = '';
  img_profile.value = ''; manageusers_pic_card.value = ''; manageinspectioncenter_id.value = '';
  manageinspectioncenter_date.value = ''; status.value = '1'; history.value = '';
  wrongdoing.value = ''; file_other.value = '';

  datatable.value = []
  getdata_table();
  Swal.close();
  closeModaladd()
  Swal.fire({
    title: "สำเร็จ",
    text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
    icon: "success"
  }); 
}else if(res.data.status == 4){
  Swal.fire({
    title: "คำเตือน",
    text: "ชื่อผู้ใช้งานมีในระบบแล้ว กรุณาเปลี่ยนชื่อผู้ใช้งาน",
    icon: "warning"
  }); 
}else{
  Swal.fire({
    title: "เกิดข้อผิดพลาด",
    text: "เพิ่มข้อมูลไม่สำเร็จ",
    icon: "error"
  }); 
}
}
}

async function geteditdata(id) {
Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()
const resData = await axios.post(`usertechnicianfalse`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 4,
    datas: [id]
  });
if(resData.data.status == 1){
  console.log(resData.data.datas[0])
  if(searchcounty_value.value.length > 1){

      dataedituser.value = resData.data.datas[0].username;
      dataeditid.value = id;
      inspector_id1_edit.value = resData.data.datas[0].inspector_id1;
      inspector_num_edit.value = resData.data.datas[0].inspector_num;
      authorized_date_edit.value = resData.data.datas[0].authorized_date;
      exp_date_edit.value = resData.data.datas[0].exp_date;
      license_type_edit.value = resData.data.datas[0].license_type;
      prefix_edit.value = resData.data.datas[0].prefix;
      prefix_other_edit.value = resData.data.datas[0].prefix_other;
      first_name_edit.value = resData.data.datas[0].first_name;
      last_name_edit.value = resData.data.datas[0].last_name;
      idcard_edit.value = resData.data.datas[0].idcard;
      username_edit.value = resData.data.datas[0].username;
      password_edit.value = '';
      educational_edit.value = resData.data.datas[0].educational;
      educational_other_edit.value = resData.data.datas[0].educational_other;
      educational_branch_edit.value = resData.data.datas[0].educational_branch;
      educational_branch_other_edit.value = resData.data.datas[0].educational_branch_other;
      course_edit.value = resData.data.datas[0].course;
      course_other_edit.value = resData.data.datas[0].course_other;
      institute_info_edit.value = resData.data.datas[0].institute_info; training_generation_edit.value = resData.data.datas[0].training_generation; car_inspector_address_edit.value = resData.data.datas[0].car_inspector_address;
      county_id_edit.value = {id:resData.data.datas[0].county_id,names:resData.data.datas[0].names}; tel_edit.value = resData.data.datas[0].tel; phone_edit.value = resData.data.datas[0].phone;
      img_profile_edit.value = resData.data.datas[0].img_profile; manageusers_pic_card_edit.value = resData.data.datas[0].img_addresscar; manageinspectioncenter_id_edit.value = {id:resData.data.datas[0].manageinspectioncenter_id,nameaddresscar:resData.data.datas[0].nameaddresscar,license_number:resData.data.datas[0].license_number};
      manageinspectioncenter_date_edit.value = resData.data.datas[0].manageinspectioncenter_date; status_edit.value = resData.data.datas[0].status; history_edit.value = resData.data.datas[0].history;
      wrongdoing_edit.value = resData.data.datas[0].wrongdoing; file_other_edit.value = resData.data.datas[0].file_other;
      Swal.close();
      openModaledit()
  }else{
    const res = await axios.post(`getdatash`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id
    });
    if(res.data.status == 1){
      searchcounty_value.value = res.data.datas.datactall;
      tro_name_id_value.value = res.data.datas.datag4;

      dataedituser.value = resData.data.datas[0].username;
      dataeditid.value = id;
      inspector_id1_edit.value = resData.data.datas[0].inspector_id1;
      inspector_num_edit.value = resData.data.datas[0].inspector_num;
      authorized_date_edit.value = resData.data.datas[0].authorized_date;
      exp_date_edit.value = resData.data.datas[0].exp_date;
      license_type_edit.value = resData.data.datas[0].license_type;
      prefix_edit.value = resData.data.datas[0].prefix;
      prefix_other_edit.value = resData.data.datas[0].prefix_other;
      first_name_edit.value = resData.data.datas[0].first_name;
      last_name_edit.value = resData.data.datas[0].last_name;
      idcard_edit.value = resData.data.datas[0].idcard;
      username_edit.value = resData.data.datas[0].username;
      password_edit.value = '';
      educational_edit.value = resData.data.datas[0].educational;
      educational_other_edit.value = resData.data.datas[0].educational_other;
      educational_branch_edit.value = resData.data.datas[0].educational_branch;
      educational_branch_other_edit.value = resData.data.datas[0].educational_branch_other;
      course_edit.value = resData.data.datas[0].course;
      course_other_edit.value = resData.data.datas[0].course_other;
      institute_info_edit.value = resData.data.datas[0].institute_info; training_generation_edit.value = resData.data.datas[0].training_generation; car_inspector_address_edit.value = resData.data.datas[0].car_inspector_address;
      county_id_edit.value = {id:resData.data.datas[0].county_id,names:resData.data.datas[0].names}; tel_edit.value = resData.data.datas[0].tel; phone_edit.value = resData.data.datas[0].phone;
      img_profile_edit.value = resData.data.datas[0].img_profile; manageusers_pic_card_edit.value = resData.data.datas[0].img_addresscar; manageinspectioncenter_id_edit.value = {id:resData.data.datas[0].manageinspectioncenter_id,nameaddresscar:resData.data.datas[0].nameaddresscar,license_number:resData.data.datas[0].license_number};
      manageinspectioncenter_date_edit.value = resData.data.datas[0].manageinspectioncenter_date; status_edit.value = resData.data.datas[0].status; history_edit.value = resData.data.datas[0].history;
      wrongdoing_edit.value = resData.data.datas[0].wrongdoing; file_other_edit.value = resData.data.datas[0].file_other;
      Swal.close();
      openModaledit()
    }
  }
}
}

async function editdata() {
if(!username_edit.value || !inspector_num_edit.value || !authorized_date_edit.value || !exp_date_edit.value || !license_type_edit.value || !first_name_edit.value || !last_name_edit.value || !county_id_edit.value || !manageinspectioncenter_id_edit.value || !educational_edit.value || !manageinspectioncenter_date_edit.value){
Swal.fire({
    title: "คำเตือน",
    text: "กรุณากรอกข้อมูลให้ครบถ้วน",
    icon: "warning"
}); 
}else{
Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()

const res = await axios.post(`usertechnicianfalse`,{
  uid: storeAuth.id,
  gid: storeAuth.group_id,
  tid: 2,
  datas: [dataeditid.value,dataedituser.value,
      inspector_id1_edit.value ,
      inspector_num_edit.value ,
      authorized_date_edit.value ,
      exp_date_edit.value ,
      license_type_edit.value ,
      prefix_edit.value ,
      prefix_other_edit.value ,
      first_name_edit.value ,
      last_name_edit.value ,
      idcard_edit.value ,
      username_edit.value ,
      password_edit.value ,
      educational_edit.value ,
      educational_other_edit.value ,
      educational_branch_edit.value ,
      educational_branch_other_edit.value ,
      course_edit.value ,
      course_other_edit.value ,
      institute_info_edit.value , training_generation_edit.value , car_inspector_address_edit.value ,
      county_id_edit.value ,tel_edit.value ,phone_edit.value ,
      img_profile.value ,manageusers_pic_card.value ,manageinspectioncenter_id_edit.value ,
      manageinspectioncenter_date_edit.value , status_edit.value , history_edit.value ,
      wrongdoing_edit.value , file_other.value ,
  ],
});
if(res.data.status == 1){

  inspector_id1_edit.value = '';
  inspector_num_edit.value = '';
  authorized_date_edit.value = '';
  exp_date_edit.value = '';
  license_type_edit.value = '';
  prefix_edit.value = '';
  prefix_other_edit.value = '';
  first_name_edit.value = '';
  last_name_edit.value = '';
  idcard_edit.value = '';
  username_edit.value = '';
  password_edit.value = '';
  educational_edit.value = '';
  educational_other_edit.value = '';
  educational_branch_edit.value = '';
  educational_branch_other_edit.value = '';
  course_edit.value = '';
  course_other_edit.value = '';
  institute_info_edit.value = ''; training_generation_edit.value = ''; car_inspector_address_edit.value = '';
  county_id_edit.value = '';tel_edit.value = '';phone_edit.value = '';
  img_profile.value = '';manageusers_pic_card.value = '';manageinspectioncenter_id_edit.value = '';
  manageinspectioncenter_date_edit.value = ''; status_edit.value = ''; history_edit.value = '';
  wrongdoing_edit.value = ''; file_other.value = '';
  datatable.value = []
  getdata_table();
  Swal.close();
  closeModaledit()
  Swal.fire({
    title: "สำเร็จ",
    text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
    icon: "success"
  }); 
}else if(res.data.status == 4){
  Swal.fire({
    title: "คำเตือน",
    text: "ชื่อผู้ใช้งานมีในระบบแล้ว กรุณาเปลี่ยนชื่อผู้ใช้งาน",
    icon: "warning"
  }); 
}else{
  Swal.fire({
    title: "เกิดข้อผิดพลาด",
    text: "แก้ไขข้อมูลไม่สำเร็จ",
    icon: "error"
  }); 
}
}
}

function deldata(data) {
Swal.fire({
title: "คำเตือน",
text: "ต้องการลบข้อมูลใช่หรือไม่",
icon: "warning",
showCancelButton: true,
confirmButtonText: "ตกลง",
cancelButtonText: "ยกเลิก",
}).then(async (result) => {
if (result.isConfirmed) {
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`delete`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 6,
    datas: [data],
  });
  if(res.data.status == 1){
    datatable.value = []
    getdata_table();
    Swal.close();
    Swal.fire({
      title: "สำเร็จ",
      text: "ลบข้อมูลเรียบร้อยแล้ว",
      icon: "success"
    }); 
  }else{
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "ลบข้อมูลไม่สำเร็จ",
      icon: "error"
    }); 
  }
}
});
}

const options = reactive({
dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
buttons: [
  {   
      extend: 'copyHtml5',
      text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
      titleAttr: 'Copy'
  },
  {
      extend: 'excelHtml5',
      text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
      titleAttr: 'Excel'
  },
  {
      extend: 'csvHtml5',
      text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
      titleAttr: 'CSV'
  },
  {
      extend: 'pdfHtml5',
      text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
      titleAttr: 'PDF',
      customize: function ( doc ) {
        processDoc(doc);
      }
  }
],
pageLength: 10,
responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
// select: {
//       style: 'multi',
//       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
//   },
lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
order: [[0, 'desc']],
language: dataTextTH,
columnDefs: [
    { width: '10%', targets: 0 },
    { width: '10%', targets: 3 },
    { width: '20%', targets: 6 },
    // {
    //     data: null,
    //     render: function (data) {
    //       return '';
    //     },
    //     targets: -1
    // },
]
});



</script>