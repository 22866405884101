
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลประวัติการทำงานของระบบ 500 รายการล่าสุด"/>

        <div class="row starter-main">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                  <thead>
                      <tr>
                        <th>หมายเลขอ้างอิง</th>
                        <th>ไอดีผู้ใช้งาน</th>
                        <th>การกระทำ</th>
                        <th>รายละเอียด</th>
                        <th>วันที่ เวลา</th>
                      </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref,onMounted,inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const Swal = inject('$swal')
const datatable = ref([]);

onMounted(() => {
  getdata_table();
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`history`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].id_user,res.data.datas[i].action,res.data.datas[i].detail,new Date(res.data.datas[i].date).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" )]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'DESC']],
  language: dataTextTH,
  columnDefs: [
      { width: '15%', targets: 0 },
      { width: '12%', targets: 1 },
      { width: '30%', targets: 3 },
    ]
});

</script>