import { createStore } from "vuex";

export const storeAuth = createStore({
    id: '',
    group_id: '',
    first_name: '',
    last_name: '',
    nameaddresscar: '',
    smoke_setting: '',
    county_id: '',
    inspector_num: '',
    licenseid: '',
    car_type: '',
    UrlImagesCar: '',
    UrlImagesTruck: '',
    UrlImagesPopup: '',
    UrlImagesToolsType:'',
    UrlFileToolsType:'',
    UrlImagesTools:'',
    UrlFileTools:'',
    UrlImagesTroOffice:'',
    UrlImagesTro:'',
    UrlFileTro:'',
    UrlImagesTechProfile:'',
    UrlImagesTechCard:'',
    UrlFileTech:''
})