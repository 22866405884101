
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการลงโทษผู้ตรวจสภาพรถ"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                    
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">จังหวัด/เขต</label>
                        <div class="col-sm-5" >
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">การพิจารณาโทษ</label>
                        <div class="col-sm-5" >
                            <select class="form-select" v-model="status_id">
                                <option selected="" value="">กรุณาเลือก</option>
                                <option value="1">ตักเตือน</option>
                                <option value="2">ระงับการตรวจสภาพ</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ตั้งแต่วันที่ตรวจ</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ถึง</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ดูรายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                        <div id="printableTable" class="col-sm-12 text-center table-responsive">
                                <!-- <div :key="index" v-for="(item,index) in countdata">
                                    {{ item }}
                                </div> -->
                            <table class="table table-bordered" ref="tbdata">
                                <thead>
                                <tr>
                                    <th v-if="countdata.province" colspan="3"><h4>สถิติการลงโทษผู้ตรวจสภาพรถ จังหวัด : {{ countdata.province }}</h4></th>
                                    <th v-else colspan="3"><h4>สถิติการลงโทษผู้ตรวจสภาพรถ ทั้งหมด</h4></th>
                                </tr>
                                <tr>
                                    <th colspan="3">ตั้งแต่วันที่(ตรวจ) {{new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}} ถึงวันที่(ตรวจ) {{new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}}</th>
                                </tr>
                                <tr>
                                    <th colspan="3">การพิจารณาโทษ : {{ cartype }}</th>
                                </tr>
                                <tr>
                                    <td>วันที่</td>
                                    <td>ชื่อ นามสกุลช่าง</td>
                                    <td>จำนวนทั้งหมด (ครั้ง)</td>
                                </tr>
                                    <!-- start v-for -->
                                </thead>
                                <tbody v-for="table_lists in table_list" :key="table_lists.id">
                                <tr>
                                    <td>{{ table_lists.date_check }}</td>
                                    <td>{{ table_lists.first_name }} {{ table_lists.last_name }}</td>
                                    <td>{{ table_lists.count }}</td>
                                </tr>
                                </tbody>
                                    
                                    <!-- end v-for -->
                            </table>
                        </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          status_id:'',
          county_id:'',
          date_start:'',
          date_end:'',
          countdata:[],
          table_list:[],
          searchcounty_area_value:[]
          }
      }, 
  methods:{
    getdata(){
        if(this.searchcounty_area_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) { 
        return `${names}`
      },
      statistics(){
          if(!this.status_id || !this.date_start || !this.date_end){
            this.$swal({
                title:'คำเตือน', 
                text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ดูรายงาน"',
                icon:'warning', 
              })  
          }else{
                let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        dt:141,
                        datas:[this.county_id,this.status_id,this.date_start,this.date_end]
                      }
              this.getcount(data);
          }
      },

      getcount(data){
          this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.$swal.showLoading()

          axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
              if(rs.data.datas){
                this.$swal.close();
                this.countdata = rs.data.datas
                this.table_list = this.countdata.datas
              }else{
                this.$swal({
                  title:'ไม่พบข้อมูล', 
                  text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                  icon:'info', 
                }) 
              }
            }else{
              this.$swal({
                title:'เกิดข้อผิดพลาด', 
                text:'ไม่สามารถค้นหาข้อมูลได้',
                icon:'error', 
              }) 
            }
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
          })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'สถิติการลงโทษผู้ตรวจสภาพรถ.' + type);
      },
      async printDiv() {
        await this.$htmlToPaper('printableTable');
      }
  }, 
  computed:{
      cartype(){
          if(this.status_id == 1){
              return 'ตักเตือน';
          }else if(this.status_id == 2){
              return 'ระงับการตรวจสภาพ';
          }
      },
  }, 
  watch:{}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>