
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ป๊อปอัพ แจ้งเตือนข้อความและรูปภาพ"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึกข้อมูล</h5>
              </div>
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="validationCustom03"><b>หัวข้อประกาศ</b></label>
                      <input class="form-control" type="text" v-model="title_popup">
                    </div>
                    <div class="col-md-12 mt-3">
                        <label for="validationCustom03"><b>สถานะ ปิด/เปิด ป๊อปอัพ</b></label>
                        <div class="media-body text-start icon-state">
                            <label class="switch">
                                <input type="checkbox" :true-value="1" :false-value="0" v-model="status_popup" value="1"><span class="switch-state"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label for="validationCustom03"><b>เนื้อหาประกาศ</b></label>
                        <ckeditor
                            v-model="content"
                            :editor="editor"
                            :config="editorConfig"
                        />
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="form-group">
                        <label for="my-file"><b>อัพโหลดรูปภาพ</b> <b class="text-danger">(ขนาดรูปที่เหมาะสมคือ 1000px X 500px)</b></label>
                        <input type="file" accept="image/*" @change="previewImage" class="form-control"> 
                        <div v-if="!preview" class="border p-2 mt-3">
                            <p>ตัวอย่างรูปภาพเดิม</p>
                            <img :src="storeAuth.UrlImagesPopup + image_popup" class="img-fluid" />
                        </div>
                        <div v-if="preview" class="border p-2 mt-3">
                            <p>ตัวอย่างรูปภาพใหม่</p>
                            <img :src="preview" class="img-fluid" />
                            <p class="mb-0">ชื่อไฟล์ : {{ image.name }}</p>
                            <p class="mb-0">ขนาด : {{ (image.size/1024).toFixed(2) }}KB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <button class="btn btn-pill btn-primary btn-air-primary" type="button" @click="uploadpopup">บันทึก</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

import { ClassicEditor, SourceEditing, Heading, BlockQuote, Bold, Italic, Font, Link, List, Table, TableToolbar} from 'ckeditor5';
import coreTranslations from 'ckeditor5/translations/th.js';
import 'ckeditor5/ckeditor5.css';

const title_popup =  ref('');
const status_popup = ref(1);
const image_popup = ref('');
const content = ref('');
const preview = ref('');
const image = ref('');

const editor = ClassicEditor
const editorConfig = {
                plugins: [ SourceEditing, Heading, BlockQuote, Bold, Italic, Font, Link, List, Table, TableToolbar ],
                toolbar: [ 
                  'sourceEditing',
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'fontSize',
                  'fontFamily',
                  'fontColor',
                  '|',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'insertTable' 
                ],
                // Other configuration options...
                translations: [ coreTranslations ],
                table: {
                  defaultHeadings: { rows: 1, columns: 1 }
                }
            }

onMounted(() => {
  getdata_table();
})

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`popup`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    // console.log(res.data.datas[0])
    title_popup.value = res.data.datas[0].title;
    status_popup.value = res.data.datas[0].status;
    image_popup.value = res.data.datas[0].image;
    content.value = res.data.datas[0].detail;
    Swal.close();
  }else{
    Swal.close();
  }
}

function  previewImage(event) {
    var input = event.target;
    if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          preview.value = e.target.result;
          // console.log(preview.value)
        }
        image.value=input.files[0];
        reader.readAsDataURL(input.files[0]);
    }
  }

function uploadpopup(){
    if(!title_popup.value){
      Swal.fire({
          title:'คำเตือน', 
          text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "บันทึก"',
          icon:'warning', 
        }) 
    }else{
        let data = {
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          tid: 2,
          datas:[title_popup.value,status_popup.value,content.value,preview.value]
        }
        getupdate(data);
    }
}

async function getupdate(data){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`popup`,data);
  if(res.data.status == 1){
    Swal.close();
    Swal.fire({
      title: "สำเร็จ",
      text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
      icon: "success"
    }); 
  }else{
    Swal.close();
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "แก้ไขข้อมูลไม่สำเร็จ",
      icon: "error"
    }); 
  }
}
</script>

<style>
  .ck-editor__editable {
    min-height: 200px;
   }
</style>