
<template>

    <div class="container-fluid">
        <Breadcrumbs main="พื้นที่เก็บข้อมูลเซิฟเวอร์ของระบบ V6(ตรอ)"/>

        <div class="row starter-main">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body progress-showcase">

                <div class="row">
                      <!-- <div class="col">
                        <h6 class="mb-2">พื้นที่เก็บข้อมูลของรูปภาพ(คลาว 1) ทั้งหมด <b class="text-primary"><?php echo convert($disk_total_space,'GB');?></b> ถูกใช้ไปแล้ว <b class="text-danger"><?php echo convert($disk_total_space - $disk_free_space,'GB');?></b> คงเหลือ <b class="text-success"><?php echo convert($disk_free_space,'GB');?></b></h6>
                        <div class="progress mb-4" style="line-height: 1.9;font-size: 0.95rem;height: 1.8rem;">
                          <div class="progress-bar-animated progress-bar-striped bg-primary text-center" role="progressbar" style="width: <?php echo $disk_c1_per_use;?>%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">ใช้ไปแล้ว <?php echo $disk_c1_per_use;?>  %</div>
                        </div>
                        <h6 class="mb-2">พื้นที่เก็บข้อมูลของฐานข้อมูล(คลาว 3) ทั้งหมด <b class="text-primary"><?php echo convert(($disk_total_space / 2),'GB');?></b> ถูกใช้ไปแล้ว <b class="text-danger"><?php echo convert($total_tb,'GB');?></b> คงเหลือ <b class="text-success"><?php echo convert(($disk_total_space / 2)-$total_tb,'GB');?></b></h6>
                        <div class="progress mb-4" style="line-height: 1.9;font-size: 0.95rem;height: 1.8rem;">
                          <div class="progress-bar-animated progress-bar-striped bg-info text-center" role="progressbar" style="width: <?php echo $disk_c2_per_use;?>%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">ใช้ไปแล้ว <?php echo $disk_c2_per_use;?> %</div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row">
                      <h6><u>รายละเอียดของฐานข้อมูล(เรียงตามจำนวนพื้นที่ที่ใช้ไป)</u></h6>
                      <table class="table table-bordered">
                      <thead>
                        <tr class="table-primary">
                          <th scope="col">ข้อมูล</th>
                          <th scope="col">จำนวนรายการข้อมูล(รายการ)</th>
                          <th scope="col">จำนวนพื้นที่เก็บข้อมูลทั้งหมด(MB)</th>
                          <th scope="col">จำนวนพื้นที่เก็บข้อมูลหลัก(MB)</th>
                          <th scope="col">จำนวนพื้นที่เก็บข้อมูลเสริม(MB)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(datatables,i) in datatable" :key="i">
                          <th scope="row">{{ tbname(datatables.table_name) }}</th>
                          <td>{{ datatables.row_count }}</td>
                          <td>{{ convert(datatables.include_ndx,"MB") }}</td>
                          <td>{{ convert(datatables.whole_table,"MB") }}</td>
                          <td>{{ convert(datatables.indexes,"MB") }}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref,onMounted,inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')
const datatable = ref([]);

onMounted(() => {
  getdata_table();
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`serverdetail`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id
  });
  if(res.data.status == 1){
    datatable.value = res.data.datas;
    Swal.close()
  }else{
    Swal.close()
  }
}

function convert(size,unit) 
{
 if(unit == "KB")
 {
  return  parseFloat(size / 1024).toFixed(4) + ' KB';	
 }
 if(unit == "MB")
 {
  return parseFloat(size / 1024 / 1024).toFixed(4) + ' MB';	
 }
 if(unit == "GB")
 {
  return parseFloat(size / 1024 / 1024 / 1024).toFixed(4) + ' GB';	
 }
 if(unit == "TB")
 {
  return parseFloat(size / 1024 / 1024 / 1024 / 1024).toFixed(4) + ' TB';	
 }
}

function tbname(data) {
    if(data == "data"){
     return "ตารางข้อมูลรายงานผลการตรวจสภาพรถ (ตามกฎหมายว่าด้วยรถยนต์)";
    }else if(data == "tb_users_log"){
     return "ตารางข้อมูลประวัติการแก้ไขยูสเซอร์";
    }else if(data == "tb_users"){
     return "ตารางข้อมูลยูสเซอร์";
    }else if(data == "tb_status_cctv"){
     return "ตารางข้อมูลประวัติการเช็คสถานะกล้อง CCTV";
    }else if(data == "data_truck"){
     return "ตารางข้อมูลรายงานผลการตรวจสภาพรถ (ตามกฎหมายว่าด้วยรถขนส่ง)";
    }else if(data == "tb_history"){
     return "ตารางข้อมูลประวัติการใช้งานของยูสเซอร์ทั้งหมด";
    }else if(data == "tb_cctv"){
     return "ตารางข้อมูลกล้อง CCTV";
    }else if(data == "tb_examination"){
     return "ตารางข้อมูลการตรวจสถานตรวจสภาพ";
    }else if(data == "tb_tools"){
     return "ตารางข้อมูลเครื่องมือของ ตรอ.";
    }else if(data == "data_log"){
     return "ตารางข้อมูลรายงานผลการตรวจสภาพรถซ้ำ ในกรณีที่รอบแรกไม่ผ่าน(ตามกฎหมายว่าด้วยรถยนต์)";
    }else if(data == "tb_carband"){
     return "ตารางข้อมูลยี่ห้อรถ";
    }else if(data == "tb_alert"){
     return "ตารางข้อมูลข่าวสานภายใน";
    }else if(data == "tb_district"){
     return "ตารางข้อมูลอำเภอ";
    }else if(data == "tb_toools_car"){
     return "ตารางข้อมูลประเภทเครื่องมือ";
    }else if(data == "punishment"){
     return "ตารางข้อมูลการลงโทษ";
    }else if(data == "tb_county"){
     return "ตารางข้อมูลจังหวัด";
    }else if(data == "tb_operational_personnel"){
     return "ตารางข้อมูลการตรวจการทำงานช่างตรวจสภาพ";
    }else if(data == "tb_comment"){
     return "ตารางข้อมูลเรื่องร้องเรียน";
    }else if(data == "tb_popup"){
     return "ตารางข้อมูลป๊อปอัพข่าวสาร";
    }else if(data == "formula_car"){
     return "ตารางข้อมูลการตั้งค่าระบบ";
    }else if(data == "data_truck_log"){
     return "ตารางข้อมูลรายงานผลการตรวจสภาพรถซ้ำ ในกรณีที่รอบแรกไม่ผ่าน(ตามกฎหมายว่าด้วยรถขนส่ง)";
    }else if(data == "tb_post"){
     return "ตารางข้อมูลข่าวสานภายนอก";
    }else if(data == "tb_hw"){
     return "ตารางข้อมูลฮาร์ดแสร์ของสถานตรวจสภาพรถ";
    }else if(data == "tb_history_mdm_pull"){
      return "ตารางข้อมูลการดึงข้อมูลผ่าน API (SOAP) จากระบบอื่นๆ";
    }
}

</script>

<style>
</style>