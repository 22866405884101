
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ตั้งค่าสถานะการทำงานของระบบ"/>

        <div class="row starter-main">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึกข้อมูล</h5>
              </div>
              <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="validationCustom01">สถานะการทำงานของระบบ</label>
                      <select class="form-control" id="validationCustom01" v-model="status_server">
                        <option value='1'>ปกติ</option>
                        <option value='2'>มีปัญหาในบางส่วน</option>
                        <option value='3'>ไม่ปกติ</option>
                        <option value='4'>แจ้งเตือน</option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label for="validationCustom02">รายละเอียดของสถานะการทำงานของระบบ</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" v-model="status_server_detail" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-check">
                      <div class="checkbox p-0">
                        <input class="form-check-input" id="invalidCheck" type="checkbox" v-model="confirmsetting">
                        <label class="form-check-label" for="invalidCheck">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</label>
                      </div>
                      <div class="invalid-feedback">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</div>
                    </div>
                  </div>
                  <button class="btn btn-primary" type="button" @click="savedata">บันทึกข้อมูล</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref,onMounted,inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

const status_server_detail = ref();
const status_server = ref();
const confirmsetting = ref();

onMounted(() => {
  getdata_table();
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`setting`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 3,
  });
  if(res.data.status == 1){
    status_server_detail.value = res.data.datas[0].status_server_detail
    status_server.value = res.data.datas[0].status_server
    Swal.close()
  }else{
    Swal.close()
  }
}

async function savedata() {

Swal.fire({
  title: 'ระบบกำลังทำงาน',
  html: 'กรุณารอสักครู่ ...',
  allowEscapeKey: false,
  allowOutsideClick: false,
});
Swal.showLoading()

if(!confirmsetting.value){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณาติ๊กถูกที่ \"กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง\" เพื่อยืนยันก่อน",
        icon: "warning"
    });
}else{
    if(!status_server_detail.value || !status_server.value){
        Swal.fire({
            title: "คำเตือน",
            text: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
            icon: "warning"
        });
    }else{
        const res = await axios.post(`setting`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            tid: 4,
            status_server_detail : status_server_detail.value,
            status_server : status_server.value
        });
        if(res.data.status == 1){
            confirmsetting.value = '';
            getdata_table();
            Swal.close();
            Swal.fire({
                title: "สำเร็จ",
                text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                icon: "success"
            }); 
        }else{
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: "บันทึกข้อมูลไม่สำเร็จ",
                icon: "error"
            }); 
        }
    }
}
}


</script>

<style>
</style>