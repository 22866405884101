
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ดาวน์โหลดรายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="alert alert-primary">
                  <strong>คำแนะนำ!</strong> ตัวเลือกค้นหาให้เลือกวันที่และเลขที่ใบอนุญาตหรือจังหวัดที่กำกับดูแลควบคู่กันทุกครั้ง นอกจากจะค้นหาจากทะเบียนรถไม่ต้องเลือกวันที่แต่ต้องกรอกรายละเอียดของทะเบียนรถให้ครบทั้ง อักษรเลขทะเบียน,หมายเลขเลขทะเบียน,จังหวัดทะเบียน <u>เครน๊ะ</u>
                </div>
                <div class="needs-validation mt-4">
                  <div class="row">
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">เลขที่ใบอนุญาต</label>
                        <div class="col-sm-5">
                            <multiselect :options="tro_name_id_value" :searchable="true" v-model="license_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                    </div>
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >จังหวัดที่กำกับดูแล</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="searchcounty_by" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="col-sm-12 mt-4 mb-4">
                        <div class="text-center text-primary">____________________ หรือค้นหาจากทะเบียนรถ ____________________</div>
                    </div>
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >อักษรเลขทะเบียน</label>
                        <div class="col-sm-5">
                        <input v-model='searchid' class="form-control" type="text" maxlength="20">
                        </div>
                    </div>
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >หมายเลขเลขทะเบียน</label>
                        <div class="col-sm-5">
                        <input v-model='searchnum' class="form-control" type="text" maxlength="4">
                        </div>
                    </div>
                    <div class="row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >จังหวัด</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_value" :searchable="true" v-model="searchcounty" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ค้นหารายละเอียด</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                      <div id="printableTable" class="col-sm-12 text-center table-responsive">
                        <table class="table table-bordered" ref="tbdata">
                            <thead>
                            <tr>
                                <th colspan="39"><h4>พบรายละเอียดทั้งหมดจำนวน : {{ countdata.count_total }} รายการ</h4></th>
                            </tr>
                            <tr v-if="countdata.count_total > 0">
                                <th colspan="22">รายละเอียดของผลการตรวจ</th>
                                <th colspan="9">รายละเอียดผลตรวจเบรค</th>
                                <th colspan="8">รายละเอียดค่าโคมไฟ</th>
                            </tr>
                            <tr v-if="countdata.count_total > 0">
                                <td>หมายเลขอ้างอิงผลตรวจ</td>
                                <td>วันที่ส่งผลการตรวจและทดสอบ</td>
                                <td>ชื่อสถานตรวจสภาพรถ</td>
                                <td>ทะเบียนรถ</td>
                                <td>ประเภท รย.</td>
                                <td>ยี่ห้อรถ</td>
                                <td>สี</td>
                                <td>ชนิดเชื้อเพลิง</td>
                                <td>น้ำหนักรถ</td>
                                <td>ผลการตรวจ(1 ผ่าน / 0 ไม่ผ่าน)</td>
                                <td>ค่า เสียง</td>
                                <td>ค่า CO(รย.12 หรือเกณฑ์เก่า)</td>
                                <td>ค่า HC(รย.12 หรือเกณฑ์เก่า)</td>
                                <td>ค่า CO(รอบต่ำ น้ำมัน)</td>
                                <td>ค่า HC(รอบต่ำ น้ำมัน)</td>
                                <td>ค่า CO(รอบต่ำ ก๊าซ)</td>
                                <td>ค่า HC(รอบต่ำ ก๊าซ)</td>
                                <td>ค่า CO(รอบสูง น้ำมัน)</td>
                                <td>ค่า HC(รอบสูง น้ำมัน)</td>
                                <td>ค่า CO(รอบสูง ก๊าซ)</td>
                                <td>ค่า HC(รอบสูง ก๊าซ)</td>
                                <td>ค่า ควันดำ</td>
                                <td>แรงห้ามล้อ เพลา 1 ซ้าย</td>
                                <td>แรงห้ามล้อ เพลา 1 ขวา</td>
                                <td>แรงห้ามล้อ เพลา 2 ซ้าย</td>
                                <td>แรงห้ามล้อ เพลา 2 ขวา</td>
                                <td>น้ำหนักลงเพลา 1</td>
                                <td>น้ำหนักลงเพลา 2</td>
                                <td>แรงเบรคมือซ้าย</td>
                                <td>แรงเบรคมือขวา</td>
                                <td>ศูนย์ล้อ</td>
                                <td>ค่าโคมไฟพุ่งไกล ด้านซ้าย</td>
                                <td>ค่าโคมไฟพุ่งไกล ด้านขวา</td>
                                <td>ค่าโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                                <td>ค่าโคมไฟพุ่งต่ำ ด้านขวา</td>
                                <td>ตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย</td>
                                <td>ตำแหน่งโคมไฟพุ่งไกล ด้านขวา</td>
                                <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                                <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา</td>
                            </tr>
                            </thead>
                            <tbody v-for="data_totals in countdata.data_total" :key="data_totals.id">
                            <tr>
                                <td>{{ data_totals.ID }}</td>
                                <td>{{ new Date(data_totals.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ) }}</td>
                                <td>{{ data_totals.TRO_NAME }}</td>
                                <td>{{ data_totals.CAR_LIC_ID_CHAR }} - {{ data_totals.CAR_LIC_ID_NUM }}</td>
                                <td>{{ data_totals.CAR_TYPE }}</td>
                                <td>{{ data_totals.RY_NUMBER_ID }}</td>
                                <td>{{ data_totals.CAR_COLOR }}</td>
                                <td>{{ data_totals.PRB_TYPE }}</td>
                                <td>{{ data_totals.CAR_WEIGHT }}</td>
                                <td>{{ data_totals.STATUS_CHECK }}</td>
                                <td>{{ data_totals.VOICE_VALUE }}</td>
                                <td>{{ data_totals.CO_VALUE }}</td>
                                <td>{{ data_totals.HC_VALUE }}</td>
                                <td>{{ data_totals.CO_VALUE_L1 }}</td>
                                <td>{{ data_totals.HC_VALUE_L1 }}</td>
                                <td>{{ data_totals.CO_VALUE_L2 }}</td>
                                <td>{{ data_totals.HC_VALUE_L2 }}</td>
                                <td>{{ data_totals.CO_VALUE_H1 }}</td>
                                <td>{{ data_totals.HC_VALUE_H1 }}</td>
                                <td>{{ data_totals.CO_VALUE_H2 }}</td>
                                <td>{{ data_totals.HC_VALUE_H2 }}</td>
                                <td>{{ data_totals.PIPE_VALUE }}</td>
                                <td>{{ data_totals.TESTBRAKE_FORCE_AXLE1L }}</td>
                                <td>{{ data_totals.TESTBRAKE_FORCE_AXLE1R }}</td>
                                <td>{{ data_totals.TESTBRAKE_FORCE_AXLE2L }}</td>
                                <td>{{ data_totals.TESTBRAKE_FORCE_AXLE2R }}</td>
                                <td>{{ data_totals.TESTBRAKE_WEIGHT_AXLE1 }}</td>
                                <td>{{ data_totals.TESTBRAKE_WEIGHT_AXLE2 }}</td>
                                <td>{{ data_totals.TESTBRAKE_HAND_VAULE_L }}</td>
                                <td>{{ data_totals.TESTBRAKE_HAND_VAULE_R }}</td>
                                <td>{{ data_totals.TESTBRAKE_WHEEL_ALIGHTMENT }}</td>
                                <td>{{ data_totals.LIGHT_DN_L_VALUE }}</td>
                                <td>{{ data_totals.LIGHT_DN_R_VALUE }}</td>
                                <td>{{ data_totals.LIGHT_DN_L_LEVEL }}</td>
                                <td>{{ data_totals.LIGHT_DN_R_LEVEL }}</td>
                                <td>{{ data_totals.LIGHT_UP_L_VALUE }}</td>
                                <td>{{ data_totals.LIGHT_UP_R_VALUE }}</td>
                                <td>{{ data_totals.LIGHT_UP_L_LEVEL }}</td>
                                <td>{{ data_totals.LIGHT_UP_R_LEVEL }}</td>
                            </tr>
                            </tbody>
                        </table>
                      </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

 export default {
    components: {
    },
    data(){
      return{
        searchid:'',
        searchnum:'',
        searchcounty:'',
        searchcounty_by:'',
        date_start:'',
        license_id:'',
        countdata:{
          count_status:'',
          count_total:'',
          data_total:[],
        },
        searchcounty_value:[],
        tro_name_id_value:'',
        searchcounty_area_value:'',
      }
    }, 
    methods:{
      getdata(){
        if(this.searchcounty_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.searchcounty_value = res.data.datas.datact;
            this.tro_name_id_value = res.data.datas.datag4;
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_select({names}) {
        return `${names}`
      },
      searchcounty_area_select({names}) { 
        return `${names}`
      },
      tro_name_id_select({license_number,nameaddresscar}) {
        return `${license_number} / ${nameaddresscar}`
      },
      statistics(){
          if((!this.date_start || (!this.searchcounty_by && !this.license_id)) && (!this.searchid && !this.searchnum && !this.searchcounty)){
            this.$swal({
              title:'คำเตือน', 
              text:'กรุณาเลือกวันที่หรือเลขทะเบียน ที่ต้องกาค้นหาก่อนกดปุ่ม "ค้นหารายละเอียด"',
              icon:'warning', 
              }) 
          }else{
                let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        dt:11,
                        datas:[this.searchid,this.searchnum,this.searchcounty,this.date_start,this.searchcounty_by,this.license_id],
                      }
              this.getcount(data);
          }
      },
      async getcount(data){
        this.countdata.count_total = '';
        this.countdata.data_total = [];
        this.countdata.count_status = '';
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

        await  axios.post(`download`,data).then(rs=>{
            if(rs.data.status == 1){
              this.$swal.close();
              this.countdata.count_total = rs.data.datas.count_total?rs.data.datas.count_total:0;
              this.countdata.data_total = rs.data.datas.data_array;
              this.countdata.count_status = rs.data.status;
            }else{
              this.$swal({
                title:'เกิดข้อผิดพลาด', 
                text:'ไม่สามารถค้นหาข้อมูลได้',
                icon:'error', 
              }) 
            }
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
        })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'รายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์.' + type);
      },
      async printDiv() {
         await this.$htmlToPaper('printableTable');
       }
    }, 
    computed:{}, 
    watch: {}, 
    beforeCreate(){}, 
    created(){
      this.getdata()
    }, 
    beforeMount(){}, 
    mounted(){}, 
    beforeUpdate(){}, 
    updated(){}, 
    beforeDestroy(){}, 
    destroyed(){}, 
}
</script>