
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติจำนวนผู้ตรวจสภาพรถ(ช่างผู้ตรวจสภาพ)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">จังหวัด/เขต</label>
                        <div class="col-sm-5" >
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">สถานะการใช้งานระบบ</label>
                        <div class="col-sm-5" >
                            <select class="form-select" v-model="status_id">
                                <option value="0">กรุณาเลือก</option>
                                <option value="1">ระงับการใช้งาน</option>
                                <option value="2">ใช้งานปกติ</option>
                                <option value="3">ลาออก</option>
                                <option value="4">ถูกยกเลิกบัตร</option>
                            </select>
                        </div>
                    </div>
                      <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ดูรายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                        <div id="printableTable" class="col-sm-12 text-center table-responsive">
                                <!-- <div :key="index" v-for="(item,index) in countdata">
                                    {{ item }}
                                </div> -->
                            <table class="table table-bordered" ref="tbdata">
                                <thead>
                                <tr>
                                    <th v-if="countdata.province_name" colspan="2"><h4>สถิติจำนวนผู้ตรวจสภาพรถ จังหวัด : {{ countdata.province_name }}</h4></th>
                                    <th v-else colspan="2"><h4>สถิติจำนวนผู้ตรวจสภาพรถ ทั้งหมด</h4></th>
                                </tr>
                                    <!-- start v-for -->
                                </thead>
                                <tbody>
                                  <tr>
                                      <td v-if="status_id == 1">จำนวนทั้งหมด(คน) ที่มีสถานะ ระงับการใช้งาน</td>
                                      <td v-else-if="status_id == 2">จำนวนทั้งหมด(คน) ที่มีสถานะ ใช้งานปกติ</td>
                                      <td v-else-if="status_id == 3">จำนวนทั้งหมด(คน) ที่มีสถานะ ลาออก</td>
                                      <td v-else-if="status_id == 4">จำนวนทั้งหมด(คน) ที่มีสถานะ ถูกยกเลิกบัตร</td>
                                      <td v-else-if="status_id == 10">จำนวนทั้งหมด (คน)</td>
                                      <td>{{ countdata.count }}</td>
                                  </tr>
                                </tbody>
                                    <!-- end v-for -->
                            </table>
                        </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
        return{
            county_id:'',
            status_id:0,
            countdata:{
              status:'',
              count:'',
              province_name:'',
            },
            searchcounty_area_value:[]
            }
        }, 
    methods:{
      getdata(){
        if(this.searchcounty_area_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) { 
        return `${names}`
      },
        statistics(){
          if(this.status_id == 0 && !this.county_id){
            this.$swal({
                title:'คำเตือน', 
                text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ดูรายงาน"',
                icon:'warning', 
              })  
          }else{
          let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        dt: 142,
                        datas:[this.county_id,this.status_id]
                  }
                this.getcount(data);
          }
            
        },
        getcount(data){
          this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.$swal.showLoading()

          axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
              if(rs.data.datas){
                this.$swal.close();
                this.countdata.status = rs.data.datas.count_status;
                this.countdata.count = rs.data.datas.datas_count;
              }else{
                this.$swal({
                  title:'ไม่พบข้อมูล', 
                  text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                  icon:'info', 
                }) 
              }
            }else{
              this.$swal({
                title:'เกิดข้อผิดพลาด', 
                text:'ไม่สามารถค้นหาข้อมูลได้',
                icon:'error', 
              }) 
            }
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
          })
        },
        excelExport(type){
          var data = this.$refs.tbdata;
          var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
          XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
          XLSX.writeFile(excelFile, 'สถิติจำนวนผู้ตรวจสภาพรถ(ช่างผู้ตรวจสภาพ).' + type);
        },
        async printDiv() {
          await this.$htmlToPaper('printableTable');
        }
    }, 
    computed:{}, 
    watch:{}, 
    components:{}, 
    beforeCreate(){}, 
    created(){
      this.getdata()
    }, 
    beforeMount(){}, 
    mounted(){}, 
    beforeUpdate(){}, 
    updated(){}, 
    beforeDestroy(){}, 
    destroyed(){},
}
</script>