
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการตรวจสภาพ(จังหวัด/เขต)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                    
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">จังหวัด/เขต</label>
                        <div class="col-sm-5" >
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ตั้งแต่วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ถึง</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ดูรายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                    <div id="printableTable" class="col-sm-12 text-center table-responsive">
                      <table class="table table-bordered" ref="tbdata">
                        <tr>
                            <th colspan="14"><h4>สถิติตรวจสภาพรถ จังหวัด : {{ countdata.province }}</h4></th>
                        </tr>
                        <tr>
                            <th colspan="14">ตั้งแต่วันที่ {{ new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/') }} ถึงวันที่ {{ new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/') }}</th>
                        </tr>
                        <tr>
                                <td rowspan="2">ชื่อสถานตรวจสภาพ <br> เลขใบอนุญาตที่</td>
                                <td colspan="3">ประเภท รย.1 (คัน)</td>
                                <td colspan="3">ประเภท รย.2 (คัน)</td>
                                <td colspan="3">ประเภท รย.3 (คัน)</td>
                                <td colspan="3">ประเภท รย.12 (คัน)</td>
                                <td colspan="1">รวมทั้งหมด (คัน)</td>
                            </tr>
                          <tr>
                                <td>ผ่าน</td>
                                <td>ไม่ผ่าน</td>
                                <td>รวม</td>
                                <td>ผ่าน</td>
                                <td>ไม่ผ่าน</td>
                                <td>รวม</td>
                                <td>ผ่าน</td>
                                <td>ไม่ผ่าน</td>
                                <td>รวม</td>
                                <td>ผ่าน</td>
                                <td>ไม่ผ่าน</td>
                                <td>รวม</td>
                            </tr>
                            <!-- start v-for -->
                            <tr :key="index" v-for="(item,index) in countdata.datas">
                                <!-- <td>{{ item }}</td> -->
                                <td class="align-middle"> {{ item.TRO_NAME }} <br> {{ item.TRO_ID }}</td> 
                                <!-- รย.1 -->
                                <td>{{ item.t1_pass }}</td>
                                <td>{{ item.t1_nopass }}</td>
                                <td>{{ item.t1_total }}</td>
                                <!-- รย.2 -->
                                <td>{{ item.t2_pass }}</td>
                                <td>{{ item.t2_nopass }}</td>
                                <td>{{ item.t2_total }}</td>
                                <!-- รย.3 -->
                                <td>{{ item.t3_pass }}</td>
                                <td>{{ item.t3_nopass }}</td>
                                <td>{{ item.t3_total }}</td>
                                <!-- รย.12 -->
                                <td>{{ item.t12_pass }}</td>
                                <td>{{ item.t12_nopass }}</td>
                                <td>{{ item.t12_total }}</td>
                                <!-- รวมทั้งหมด -->
                                <td>{{ Number(item.t3_total) + Number(item.t2_total) + Number(item.t1_total) + Number(item.t12_total) }}</td>
                            </tr>
                            <!-- end v-for -->
                            <tr>
                              <td><b>รวม</b></td>
                              <td>{{sumx(countdata.datas,'t1_pass')}}</td>
                              <td>{{sumx(countdata.datas,'t1_nopass')}}</td>
                              <td>{{sumx(countdata.datas,'t1_total')}}</td>
                              <td>{{sumx(countdata.datas,'t2_pass')}}</td>
                              <td>{{sumx(countdata.datas,'t2_nopass')}}</td>
                              <td>{{sumx(countdata.datas,'t2_total')}}</td>
                              <td>{{sumx(countdata.datas,'t3_pass')}}</td>
                              <td>{{sumx(countdata.datas,'t3_nopass')}}</td>
                              <td>{{sumx(countdata.datas,'t3_total')}}</td>
                              <td>{{sumx(countdata.datas,'t12_pass')}}</td>
                              <td>{{sumx(countdata.datas,'t12_nopass')}}</td>
                              <td>{{sumx(countdata.datas,'t12_total')}}</td>
                              <td>{{ Number(sumx(countdata.datas,'t1_total')) + Number(sumx(countdata.datas,'t2_total')) + Number(sumx(countdata.datas,'t3_total')) + Number(sumx(countdata.datas,'t12_total')) }}</td>
                            </tr>
                      </table>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          county_id:'',
          date_start:'',
          date_end:'',
          countdata:[],
          searchcounty_area_value:[]
        }
      }, 
  methods:{
      getdata(){
        if(this.searchcounty_area_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) { 
        return `${names}`
      },
      statistics(){
          if(!this.county_id || !this.date_start || !this.date_end){
            this.$swal({
                title:'คำเตือน', 
                text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ดูรายงาน"',
                icon:'warning', 
              }) 
          }else{
                let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        dt:113,
                        datas:[this.county_id,this.date_start,this.date_end]
                      }
              this.getcount(data);
          }
      },
      getcount(data){
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
          
        axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
              if(rs.data.datas){
                this.$swal.close();
                this.countdata = rs.data.datas;
              }else{
                this.$swal({
                  title:'ไม่พบข้อมูล', 
                  text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                  icon:'info', 
                }) 
              }
            }else{
              this.$swal({
                title:'เกิดข้อผิดพลาด', 
                text:'ไม่สามารถค้นหาข้อมูลได้',
                icon:'error', 
              }) 
            }
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
        })
      },
      sumx(ars, col ){
        return ars.reduce((a, b) => (Number(a) + Number(b[col])), 0);
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'สถิติการตรวจสภาพ(จังหวัด/เขต).' + type);
      },
      async printDiv() {
        await this.$htmlToPaper('printableTable');
      }
  }, 
  computed:{}, 
  watch:{}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}

</script>