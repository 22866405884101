
<template>

    <div class="container-fluid">
        <Breadcrumbs main="รายการเรื่องร้องเรียนและข้อเสนอแนะ"/>

        <div class="row project-cards">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- model search -->
                        <div class="modal fade" id="model-view" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <b class="text-ptt-26">แก้ไขข้อมูล</b>
                                        <button type="button" @click="closeModalview()" class="btn-close" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                    <div class="row">
                                        <div class="col">
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >หมายเลขอ้างอิง</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.id" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >เวลาที่ส่งข้อมูล</label>
                                                <div class="col-sm-8">
                                                <input :value="new Date(datadetail.created_at).toLocaleString('en-GB').replace( /,/,'' )" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >หัวข้อ</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.comment_title" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >ชื่อผู้ส่ง</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.created_name" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >เบอร์ผู้ส่ง</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.created_tel" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >อีเมลผู้ส่ง</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.created_email" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >ประเภทข้อมูล</label>
                                                <div class="col-sm-8">
                                                <input :value="datadetail.comment_type == 1?'ข้อเสนอแนะ':'ร้องเรียน'" class="form-control" type="text" readonly>
                                                </div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-4 form-label text-right" >รายละเอียด</label>
                                                <div class="col-sm-8">
                                                <textarea :value="datadetail.comment_detail" class="form-control" rows="5" readonly></textarea>
                                                </div>
                                            </div>
                 
                                        </div>
                                    </div> 
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" @click="closeModalview()" class="btn btn-danger" >ปิด</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end model search -->
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                    <th>หมายเลขอ้างอิง</th>
                                    <th>เวลาที่ส่งข้อมูล</th>
                                    <th>หัวข้อ</th>
                                    <th>ชื่อผู้ส่ง</th>
                                    <th>เบอร์ผู้ส่ง</th>
                                    <th>อีเมลผู้ส่ง</th>
                                    <th>ประเภทข้อมูล</th>
                                    <th>จัดการ</th>
                                </tr>
                            </thead>
                            <template #column-7="props">
                              <div class="btn-group">
                                <button @click="viewdata(props.cellData)" type="button" class="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_view: null,
})

function openModalview(){ state.modal_view.show()}
function closeModalview(){state.modal_view.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const datadetail = ref([]);

onMounted(() => {
  getdata_table();
  state.modal_view = new Modal('#model-view', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  
  const res = await axios.post(`comment`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([res.data.datas[i].id,new Date(res.data.datas[i].created_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].comment_title,res.data.datas[i].created_name,res.data.datas[i].created_tel,res.data.datas[i].created_email,res.data.datas[i].comment_type == 1?'ข้อเสนอแนะ':'ร้องเรียน',res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

async function viewdata(id) {
    if(id){
        datadetail.value = []
        Swal.fire({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        Swal.showLoading()

        const res = await axios.post(`comment`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            tid: 2,
            id: id
        });
        if(res.data.status == 1){
            datadetail.value = res.data.datas[0];
            Swal.close()
            openModalview()
        }
    }
}
function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 4,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '13%', targets: 0 },
        { width: '35%', targets: 2 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>