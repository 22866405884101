
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลอุปกรณ์ฮาร์ดแวร์ทั้งหมดที่ลงทะเบียน"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    สามารถข้อมูลเพิ่มเติมได้
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <button @click="getsh()" class="btn btn-primary"><i class="fa fa-search"></i> ค้นหา</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model data log -->
              <div class="modal fade" id="model-sh" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ค้นหาข้อมูล</b>
                            <button type="button" @click="closeModalsh()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                  <div class="col-sm-8">
                                    <multiselect :options="tro_name_id_value" :searchable="true" v-model="datash[0]" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                  </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ประเภทฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <select class="form-select" v-model="datash[1]">
                                            <option value="" selected>กรุณาเลือก</option>
                                            <option value="1">กล้อง</option> 
                                            <option value="2">ซีพียู(UUID)</option>
                                            <option value="3">ฮาร์ดดิสก์</option>
                                            <option value="4">ไบออส</option>
                                    </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >สถานะ</label>
                                <div class="col-sm-8" >
                                    <select class="form-select" v-model="datash[2]">
                                            <option value="" selected>กรุณาเลือก</option>
                                            <option value="1">ได้รับอนุญาตแล้ว</option> 
                                            <option value="2">ยังไม่ได้รับอนุญาต</option>
                                    </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                              <label class="col-sm-4 col-form-label" >จังหวัด/เขต(ที่กำกับดูแล)</label>
                                <div class="col-sm-8" >
                                    <multiselect :options="searchcounty_value" :searchable="true" v-model="datash[3]" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                </div>
                              </div>

                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="sh()" class="btn btn-primary" >ค้นหา</button>
                          <button type="button" @click="closeModalsh()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model data log -->
              <!-- model search -->
              <div class="modal fade" id="model-vw" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">แก้ไขข้อมูล</b>
                            <button type="button" @click="closeModalvw()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขอ้างอิง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[0]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >สถานตรวจสภาพ</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[1]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ประเภทฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[2]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[3]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ชื่อไดร์กล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[4]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขกลุ่มกล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[5]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ประเภทของไดร์กล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[6]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ลงทะเบียนเมื่อ</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[7]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >สถานะฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[8]" readonly>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="closeModalvw()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>ชื่อสถานตรวจสภาพรภ</th>
                                <th>ประเภทฮาร์ดแวร์</th>
                                <th>ชื่อกล้อง</th>
                                <th>หมายเลขฮาร์ดแวร์</th>
                                <th class="none">ลงทะเบียนเมื่อ</th>
                                <th>สถานะ</th>
                                <th>จัดการ</th>
                              </tr>
                            </thead>
                            <template #column-6="props">
                                <button @click="editstatus(props.cellData,props.rowData)" type="button" class="btn" :class="props.cellData==2?'btn-danger':'btn-success'">{{ props.cellData == 2?'ยังไม่ได้รับอนุญาต':'ได้รับอนุญาตแล้ว' }}</button>
                            </template>
                            <template #column-7="props">
                              <div class="btn-group">
                                <button @click="getdata(props.cellData)" type="button" class="btn btn-info"><i class="fa fa-search"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_sh: null,
    modal_vw: null,
})

function openModalsh(){ state.modal_sh.show()}
function closeModalsh(){state.modal_sh.hide()}
function openModalvw(){ state.modal_vw.show()}
function closeModalvw(){state.modal_vw.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const datash = ref([]);
const datavw = ref([]);

const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}
const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}

onMounted(() => {
  getdata_table();
  state.modal_sh = new Modal('#model-sh', {});
  state.modal_vw = new Modal('#model-vw', {});
})

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`hw`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      let hwType = '';
      if(res.data.datas[i].hw_type == 1){ hwType = 'กล้อง'}else if(res.data.datas[i].hw_type == 2){hwType = 'ซีพียู(UUID)';}else if(res.data.datas[i].hw_type == 3){ hwType = 'ฮาร์ดดิสก์'}else if(res.data.datas[i].hw_type == 4){ hwType = 'ไบออส'}
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number + ' / ' +res.data.datas[i].nameaddresscar,hwType,res.data.datas[i].hw_cam_lable,res.data.datas[i].hw_number,new Date(res.data.datas[i].entry_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].status,res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close()
  }
}

async function getsh(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    if(searchcounty_value.value.length > 1){
      Swal.close()
      openModalsh()
    }else{
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id
      });
      if(res.data.status == 1){
        searchcounty_value.value = res.data.datas.datactall;
        tro_name_id_value.value = res.data.datas.datag4;
        Swal.close()
        openModalsh()
      }
    }
}

async function sh() {
  if(!datash.value[0] && !datash.value[1] && !datash.value[2] && !datash.value[3]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกข้อมูลที่ต้องการค้นก่อน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const res = await axios.post(`hw`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 2,
      datas: [datash.value[0],datash.value[1],datash.value[2],datash.value[3]],
    });
    console.log(res.data)
      if(res.data.status == 1){
        datash.value = []
        datatable.value = []
        for (let i = 0; i < res.data.datas.length; i++) {
        let hwType = '';
        if(res.data.datas[i].hw_type == 1){ hwType = 'กล้อง'}else if(res.data.datas[i].hw_type == 2){hwType = 'ซีพียู(UUID)';}else if(res.data.datas[i].hw_type == 3){ hwType = 'ฮาร์ดดิสก์'}else if(res.data.datas[i].hw_type == 4){ hwType = 'ไบออส'}
        datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number + ' / ' +res.data.datas[i].nameaddresscar,hwType,res.data.datas[i].hw_cam_lable,res.data.datas[i].hw_number,new Date(res.data.datas[i].entry_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].status,res.data.datas[i].id]);
      }
      Swal.close();
      closeModalsh()
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ค้นหาข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

async function getdata(id) {
  datavw.value = [];
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  
  const resData = await axios.post(`hw`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 4,
    datas:[id]
  });
  if(resData.data.status == 1){
    datavw.value[0] = resData.data.datas[0].id;
    datavw.value[1] = resData.data.datas[0].license_number + ' / ' + resData.data.datas[0].nameaddresscar;
    if(resData.data.datas[0].hw_type == 1){
      datavw.value[2] = 'กล้อง';
    }else if(resData.data.datas[0].hw_type == 2){
      datavw.value[2] = 'ซีพียู(UUID)';
    }else if(resData.data.datas[0].hw_type == 3){
      datavw.value[2] = 'ฮาร์ดดิสก์';
    }else if(resData.data.datas[0].hw_type == 4){
      datavw.value[2] = 'ไบออส';
    }
    datavw.value[3] = resData.data.datas[0].hw_number;
    datavw.value[4] = resData.data.datas[0].hw_cam_lable;
    datavw.value[5] = resData.data.datas[0].hw_cam_number_group;
    datavw.value[6] = resData.data.datas[0].hw_cam_type;
    datavw.value[7] = new Date(resData.data.datas[0].entry_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" );
    datavw.value[8] = resData.data.datas[0].status == 1?'ได้รับอนุญาตแล้ว':'ยังไม่ได้รับอนุญาต';
    datavw.value[9] = resData.data.datas[0].hw_type;
    Swal.close();
    openModalvw()
  }else{
    Swal.close();
  }    
}

function editstatus(staus,data){
  Swal.fire({
      title: "คำเตือน",
      text: "ต้องการแก้ไขสถานะหรือไม่ ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: "ยกเลิก",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading()

          const res = await axios.post(`hw`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            tid: 3,
            datas: [staus,data[0]],
          });

          if(res.data.status == 1){
            datatable.value = []
            getdata_table();
            Swal.close();
            Swal.fire({
              title: "สำเร็จ",
              text: "แก้ไขสถานะเรียบร้อยแล้ว",
              icon: "success"
            }); 
          }else{
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: "แก้ไขสถานะไม่สำเร็จ",
              icon: "error"
            }); 
          }

        }
      })
}

function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 9,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>