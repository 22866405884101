
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">

                      <div class="col-sm-12 text-center alert alert-info dark alert-dismissible fade show" role="alert" >
                        <p> หากต้องการแสดงข้อมูลทุกสถานตรวจสภาพรถไม่ต้องเลือก "เลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ" แต่จำเป็นต้องเลือกช่วงวันที่ต้องการดูข้อมูล.</p>
                      </div>
  
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">เลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ</label>
                        <div class="col-sm-5">
                          <multiselect :options="tro_name_id_value" :searchable="true" v-model="tro_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">จังหวัด/เขต</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ตั้งแต่วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ถึง</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ค้นหารายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                        <div id="printableTable" class="text-center table-responsive">
                                <!-- <div :key="index" v-for="(item,index) in countdata">
                                    {{ item }}
                                </div> -->
                            <table class="table table-bordered" ref="tbdata">
                                <tr>
                                    <th v-if="tro_id" colspan="135"><h4>สถิติตรวจสภาพรถ ของ : {{ tro_id.license_number + ' / ' + tro_id.nameaddresscar }}</h4></th>
                                    <th v-else-if="county_id" colspan="135"><h4>สถิติตรวจสภาพรถ ของ จังหวัด/เขต : {{ county_id.names }}</h4></th>
                                    <th v-else colspan="135"><h4>สถิติตรวจสภาพรถ ทั้งหมด</h4></th>
                                </tr>
                                <tr>
                                    <th colspan="135">ตั้งแต่วันที่ {{new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}} ถึงวันที่ {{new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}}</th>
                                </tr>
                                <tr>
                                        <td rowspan="3">ชื่อสถานตรวจสภาพ <br> เลขใบอนุญาตที่</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 1 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 2 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 3 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 4 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 5 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 6 (คัน)</td>
                                        <td colspan="4">รถโดยสารมาตรฐาน 7 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 1 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 2 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 3 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 4 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 5 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 6 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 7 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 8 (คัน)</td>
                                        <td colspan="4">รถบรรทุก ลักษณะ 9 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 1 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 2  (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 3 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 4 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 5 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 6 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 7 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 8 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 9 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 10 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 11 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 12 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 13 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 14 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 15 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 16 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 17 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 18 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 19 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 20 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 21 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 22  (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 23 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 24 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 25 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 26 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 27 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 28 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 39 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 30 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 31 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 32 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 33 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 34 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 35 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 36 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 37 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 38 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 39 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 40 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 41 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 42  (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 43 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 44 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 45 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 46 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 47 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 48 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 49 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 50 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 51 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 52 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 53 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 54 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 55 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 56 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 57 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 58 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 59 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 60 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 61 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 62  (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 63 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 64 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 65 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 66 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 67 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 68 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 69 (คัน)</td>
                                        <td rowspan="3">ไม่ผ่านข้อที่ 70 (คัน)</td>
                                    </tr>
                                  <tr>
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
              
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
              
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
              
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
              
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                        
                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>

                                        <td rowspan="2">ผ่าน</td>
                                        <td colspan="3">ไม่ผ่าน</td>
                                    </tr>
                                    <tr>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                        <td>1 ครั้ง</td>
                                        <td>2 ครั้ง</td>
                                        <td>มากกว่า 2 ครั้ง</td>
                                    </tr>
                                    <!-- start v-for -->
                                    <tr :key="index" v-for="(item,index) in countdata.datas">
                                        <!-- <td>{{ item }}</td> -->
                                        <td class="align-middle"> {{ item.TRO_NAME }} / {{ item.TRO_ID }}</td>
                                        <!-- A -->
                                        <td>{{ item.a1_pass }}</td>
                                        <td>{{ item.a1_nopass1 }}</td>
                                        <td>{{ item.a1_nopass2 }}</td>
                                        <td>{{ item.a1_nopassmore }}</td>
                                        <td>{{ item.a2_pass }}</td>
                                        <td>{{ item.a2_nopass1 }}</td>
                                        <td>{{ item.a2_nopass2 }}</td>
                                        <td>{{ item.a2_nopassmore }}</td>
                                        <td>{{ item.a3_pass }}</td>
                                        <td>{{ item.a3_nopass1 }}</td>
                                        <td>{{ item.a3_nopass2 }}</td>
                                        <td>{{ item.a3_nopassmore }}</td>
                                        <td>{{ item.a4_pass }}</td>
                                        <td>{{ item.a4_nopass1 }}</td>
                                        <td>{{ item.a4_nopass2 }}</td>
                                        <td>{{ item.a4_nopassmore }}</td>
                                        <td>{{ item.a5_pass }}</td>
                                        <td>{{ item.a5_nopass1 }}</td>
                                        <td>{{ item.a5_nopass2 }}</td>
                                        <td>{{ item.a5_nopassmore }}</td>
                                        <td>{{ item.a6_pass }}</td>
                                        <td>{{ item.a6_nopass1 }}</td>
                                        <td>{{ item.a6_nopass2 }}</td>
                                        <td>{{ item.a6_nopassmore }}</td>
                                        <td>{{ item.a7_pass }}</td>
                                        <td>{{ item.a7_nopass1 }}</td>
                                        <td>{{ item.a7_nopass2 }}</td>
                                        <td>{{ item.a7_nopassmore }}</td>
                                        <!-- B -->
                                        <td>{{ item.b1_pass }}</td>
                                        <td>{{ item.b1_nopass1 }}</td>
                                        <td>{{ item.b1_nopass2 }}</td>
                                        <td>{{ item.b1_nopassmore }}</td>
                                        <td>{{ item.b2_pass }}</td>
                                        <td>{{ item.b2_nopass1 }}</td>
                                        <td>{{ item.b2_nopass2 }}</td>
                                        <td>{{ item.b2_nopassmore }}</td>
                                        <td>{{ item.b3_pass }}</td>
                                        <td>{{ item.b3_nopass1 }}</td>
                                        <td>{{ item.b3_nopass2 }}</td>
                                        <td>{{ item.b3_nopassmore }}</td>
                                        <td>{{ item.b4_pass }}</td>
                                        <td>{{ item.b4_nopass1 }}</td>
                                        <td>{{ item.b4_nopass2 }}</td>
                                        <td>{{ item.b4_nopassmore }}</td>
                                        <td>{{ item.b5_pass }}</td>
                                        <td>{{ item.b5_nopass1 }}</td>
                                        <td>{{ item.b5_nopass2 }}</td>
                                        <td>{{ item.b5_nopassmore }}</td>
                                        <td>{{ item.b6_pass }}</td>
                                        <td>{{ item.b6_nopass1 }}</td>
                                        <td>{{ item.b6_nopass2 }}</td>
                                        <td>{{ item.b6_nopassmore }}</td>
                                        <td>{{ item.b7_pass }}</td>
                                        <td>{{ item.b7_nopass1 }}</td>
                                        <td>{{ item.b7_nopass2 }}</td>
                                        <td>{{ item.b7_nopassmore }}</td>
                                        <td>{{ item.b8_pass }}</td>
                                        <td>{{ item.b8_nopass1 }}</td>
                                        <td>{{ item.b8_nopass2 }}</td>
                                        <td>{{ item.b8_nopassmore }}</td>
                                        <td>{{ item.b9_pass }}</td>
                                        <td>{{ item.b9_nopass1 }}</td>
                                        <td>{{ item.b9_nopass2 }}</td>
                                        <td>{{ item.b9_nopassmore }}</td>
                                        <!-- ข้อที่ไม่ผ่าน  -->
                                        <td>{{ item.error1 }}</td>
                                        <td>{{ item.error2 }}</td>
                                        <td>{{ item.error3 }}</td>
                                        <td>{{ item.error4 }}</td>
                                        <td>{{ item.error5 }}</td>
                                        <td>{{ item.error6 }}</td>
                                        <td>{{ item.error7 }}</td>
                                        <td>{{ item.error8 }}</td>
                                        <td>{{ item.error9 }}</td>
                                        <td>{{ item.error10 }}</td>
                                        <td>{{ item.error11 }}</td>
                                        <td>{{ item.error12 }}</td>
                                        <td>{{ item.error13 }}</td>
                                        <td>{{ item.error14 }}</td>
                                        <td>{{ item.error15 }}</td>
                                        <td>{{ item.error16 }}</td>
                                        <td>{{ item.error17 }}</td>
                                        <td>{{ item.error18 }}</td>
                                        <td>{{ item.error19 }}</td>
                                        <td>{{ item.error20 }}</td>
                                        <td>{{ item.error21 }}</td>
                                        <td>{{ item.error22 }}</td>
                                        <td>{{ item.error23 }}</td>
                                        <td>{{ item.error24 }}</td>
                                        <td>{{ item.error25 }}</td>
                                        <td>{{ item.error26 }}</td>
                                        <td>{{ item.error27 }}</td>
                                        <td>{{ item.error28 }}</td>
                                        <td>{{ item.error29 }}</td>
                                        <td>{{ item.error30 }}</td>
                                        <td>{{ item.error31 }}</td>
                                        <td>{{ item.error32 }}</td>
                                        <td>{{ item.error33 }}</td>
                                        <td>{{ item.error34 }}</td>
                                        <td>{{ item.error35 }}</td>
                                        <td>{{ item.error36 }}</td>
                                        <td>{{ item.error37 }}</td>
                                        <td>{{ item.error38 }}</td>
                                        <td>{{ item.error39 }}</td>
                                        <td>{{ item.error40 }}</td>
                                        <td>{{ item.error41 }}</td>
                                        <td>{{ item.error42 }}</td>
                                        <td>{{ item.error43 }}</td>
                                        <td>{{ item.error44 }}</td>
                                        <td>{{ item.error45 }}</td>
                                        <td>{{ item.error46 }}</td>
                                        <td>{{ item.error47 }}</td>
                                        <td>{{ item.error48 }}</td>
                                        <td>{{ item.error49 }}</td>
                                        <td>{{ item.error50 }}</td>
                                        <td>{{ item.error51 }}</td>
                                        <td>{{ item.error52 }}</td>
                                        <td>{{ item.error53 }}</td>
                                        <td>{{ item.error54 }}</td>
                                        <td>{{ item.error55 }}</td>
                                        <td>{{ item.error56 }}</td>
                                        <td>{{ item.error57 }}</td>
                                        <td>{{ item.error58 }}</td>
                                        <td>{{ item.error59 }}</td>
                                        <td>{{ item.error60 }}</td>
                                        <td>{{ item.error61 }}</td>
                                        <td>{{ item.error62 }}</td>
                                        <td>{{ item.error63 }}</td>
                                        <td>{{ item.error64 }}</td>
                                        <td>{{ item.error65 }}</td>
                                        <td>{{ item.error66 }}</td>
                                        <td>{{ item.error67 }}</td>
                                        <td>{{ item.error68 }}</td>
                                        <td>{{ item.error69 }}</td>
                                        <td>{{ item.error70 }}</td>
                                    </tr>
                                    
                            </table>
                        </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          tro_id:'',
          county_id: '',
          date_start:'',
          date_end:'',
          countdata:[],
          province_name:'',
          searchcounty_area_value:[],
          tro_name_id_value:[]
          }
      }, 
  methods:{
    getdata(){
        if(this.searchcounty_area_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.tro_name_id_value = res.data.datas.datag4;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) { 
        return `${names}`
      },
      tro_name_id_select({license_number,nameaddresscar}) {
        return `${license_number} / ${nameaddresscar}`
      },
      statistics(){
          // console.log('--->',this.date_start);
          if(!this.date_start || !this.date_end){
            this.$swal({
                title:'คำเตือน', 
                text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ดูรายงาน"',
                icon:'warning', 
              })  
          }else{
                let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        dt:124,
                        datas:[this.tro_id,this.county_id,this.date_start,this.date_end]
                      }
              this.getcount(data);
          }
      },
      getcount(data){
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
          
          axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
              if(rs.data.datas){
                this.$swal.close();
                this.countdata = rs.data.datas;
              }else{
                this.$swal({
                  title:'ไม่พบข้อมูล', 
                  text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                  icon:'info', 
                }) 
              }
            }else{
              this.$swal({
                title:'เกิดข้อผิดพลาด', 
                text:'ไม่สามารถค้นหาข้อมูลได้',
                icon:'error', 
              }) 
            }
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
        })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ).' + type);
      },
      async printDiv() {
        await this.$htmlToPaper('printableTable');
      }
  }, 
  computed:{}, 
  watch:{}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>