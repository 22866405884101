import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body';
import { storeAuth } from "../store/modules/auth";
import axios from "../axios";
import Storage from 'vue-ls';

const options = {
  namespace: '', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

const { ls } = Storage.useStorage(options)

import Login from '../pages/frontend/Login.vue';
import Dashboard from '../pages/backend/dashboardPage.vue';
import inspectionReport from '../pages/backend/inspectionReport.vue';
import datacarDetail from '../pages/backend/datacardetailPage.vue';
import datacarDetails from '../pages/backend/datacardetailPages.vue'
import datacarPdf from '../pages/backend/datacarpdfPage.vue';
import datacarPdfs from '../pages/backend/datacarpdfPages.vue';
import inspectionReportLog from '../pages/backend/inspectionReportLog.vue';
import inspectionReportTruck from '../pages/backend/inspectionReportTruck.vue';
import datatruckDetail from '../pages/backend/datatruckdetailPage.vue'
import datatruckPdf from '../pages/backend/datatruckpdfPage.vue';
import inspectionownerPage from '../pages/backend/inspectionownerPage.vue';
import downloadPage from '../pages/backend/downloadPage.vue';
import faqPage from '../pages/backend/faqPage.vue';
import inspectionreportv5Page from '../pages/backend/inspectionReportV5.vue';
import inspectionreportv5truckPage from '../pages/backend/inspectionReportV5truck.vue';
import datacarv5detailPage from '../pages/backend/datacarv5detailPage.vue';
import datatruckv5detailPage from '../pages/backend/datatruckv5detailPage.vue';
import hitstoryPage from '../pages/backend/hitstoryPage.vue';
import serverdetailPage from '../pages/backend/serverdetailPages.vue';
import settingstatusPage from '../pages/backend/settingstatusPage.vue';
import settingsmokePage from '../pages/backend/settingsmokePage.vue';
import editpasswordPage from '../pages/backend/editpasswordPages.vue';
import countyPages from '../pages/backend/countyPages.vue';
import carbrandPage from '../pages/backend/carBrandPages.vue';
import districtPages from '../pages/backend/districtPages.vue';
import commentPages from '../pages/backend/commentPages.vue';
import cctvPages from '../pages/backend/cctvPages.vue';
import usercountyPages from '../pages/backend/usercountyPages.vue';
import useradminPages from '../pages/backend/useradminPages.vue';
import usercheckdataPages from '../pages/backend/usercheckdataPages.vue';
import userstatisticsPages from '../pages/backend/userstatisticsPages.vue';
import alertPages from '../pages/backend/alertPages.vue';
import postPages from '../pages/backend/postPages.vue';
import download11Pages from '../pages/backend/download11Pages.vue';
import download12Pages from '../pages/backend/download12Pages.vue';
import download21Pages from '../pages/backend/download21Pages.vue';
import download31Pages from '../pages/backend/download31Pages.vue';
import userOwnerPages from '../pages/backend/userOwnerPages.vue';
import userLiabilitiesPages from '../pages/backend/userLiabilitiesPages.vue';
import userAuthorizerPages from '../pages/backend/userAuthorizerPages.vue';
import userHWPages from '../pages/backend/userHWPages.vue';
import punishmentPages from '../pages/backend/punishmentPages.vue';
import userInspectorPages from '../pages/backend/userInspectorPages.vue';
import statistics116Pages from '../pages/backend/statistics116Pages.vue';
import statistics115Pages from '../pages/backend/statistics115Pages.vue';
import statistics114Pages from '../pages/backend/statistics114Pages.vue';
import statistics113Pages from '../pages/backend/statistics113Pages.vue';
import statistics112Pages from '../pages/backend/statistics112Pages.vue';
import statistics111Pages from '../pages/backend/statistics111Pages.vue';
import statistics124Pages from '../pages/backend/statistics124Pages.vue';
import statistics123Pages from '../pages/backend/statistics123Pages.vue';
import statistics122Pages from '../pages/backend/statistics122Pages.vue'; 
import statistics121Pages from '../pages/backend/statistics121Pages.vue';
import statistics133Pages from '../pages/backend/statistics133Pages.vue';
import statistics132Pages from '../pages/backend/statistics132Pages.vue';
import statistics131Pages from '../pages/backend/statistics131Pages.vue';
import statistics141Pages from '../pages/backend/statistics141Pages.vue';
import statistics142Pages from '../pages/backend/statistics142Pages.vue';
import inspectionPages from '../pages/backend/inspectionPages.vue';
import inspectionFalsePages from '../pages/backend/inspectionFalsePages.vue';
import inspectionUserPages from '../pages/backend/inspectionUserPages.vue';
import inspectionAddPages from '../pages/backend/inspectionAddPages.vue';
import PopUpPages from '../pages/backend/PopUpPages.vue';
import toolsTypePages from '../pages/backend/toolsTypePages.vue';
import toolsPages from '../pages/backend/toolsPages.vue';
import userTroPages from '../pages/backend/userTroPages.vue';
import userTechnicianPages from '../pages/backend/userTechnicianPages.vue';
import userTechnicianFalsePages from '../pages/backend/userTechnicianFalsePages.vue';

const routes =[
  {
    path: '/',
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | เข้าสู่ระบบ',
        }
      },

    ]
  },
  {
    path: '/user',
    component: Body,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | แดชบอร์ด',
          requiresAuth:true,
        }
      },
      {
        path: 'usertechnicianfalse',
        name: 'userTechnicianFalsePages',
        component: userTechnicianFalsePages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้ตรวจสภาพรถ (ลาออก / ถูกยกเลิกบัตร)',
          requiresAuth:true,
        }
      },
      {
        path: 'usertechnician',
        name: 'userTechnicianPages',
        component: userTechnicianPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้ตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'usertro',
        name: 'userTroPages',
        component: userTroPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'tools',
        name: 'toolsPages',
        component: toolsPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเครื่องตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'toolstype',
        name: 'toolsTypePages',
        component: toolsTypePages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลประเภทของเครื่องตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'popup',
        name: 'PopUpPages',
        component: PopUpPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ป๊อปอัพ แจ้งเตือนข้อความและรูปภาพ',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionadd',
        name: 'inspectionAddPages',
        component: inspectionAddPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | บันทึกการตรวจสอบผู้ตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionuser',
        name: 'inspectionUserPages',
        component: inspectionUserPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการตรวจสอบผู้ตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionfalse',
        name: 'inspectionFalsePages',
        component: inspectionFalsePages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการการตรวจสอบสถานตรวจสภาพรถ(รอการแก้ไข)',
          requiresAuth:true,
        }
      },
      {
        path: 'inspection',
        name: 'inspectionPages',
        component: inspectionPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการการตรวจสอบสถานตรวจสภาพรถ(แก้ไขแล้ว/ไม่ต้องแก้ไข)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics142',
        name: 'statistics142Pages',
        component: statistics142Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติจำนวนผู้ตรวจสภาพรถ(ช่างผู้ตรวจสภาพ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics141',
        name: 'statistics141Pages',
        component: statistics141Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการลงโทษผู้ตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics133',
        name: 'statistics133Pages',
        component: statistics133Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติจำนวนสถานตรวจสภาพรถ(ตรอ.)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics132',
        name: 'statistics132Pages',
        component: statistics132Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการลงโทษสถานตรวจสภาพรถ(จากการตรวจสอบ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics131',
        name: 'statistics131Pages',
        component: statistics131Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการลงโทษสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics124',
        name: 'statistics124Pages',
        component: statistics124Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics123',
        name: 'statistics123Pages',
        component: statistics123Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(จังหวัด/เขต)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics122',
        name: 'statistics122Pages',
        component: statistics122Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(สถานตรวจสภาพ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics121',
        name: 'statistics121Pages',
        component: statistics121Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(ประเภทรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics116',
        name: 'statistics116Pages',
        component: statistics116Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลแยกเป็นจังหวัด)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics115',
        name: 'statistics115Pages',
        component: statistics115Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลแยกเป็นจังหวัด)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics114',
        name: 'statistics114Pages',
        component: statistics114Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics113',
        name: 'statistics113Pages',
        component: statistics113Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(จังหวัด/เขต)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics112',
        name: 'statistics112Pages',
        component: statistics112Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(สถานตรวจสภาพ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics111',
        name: 'statistics111Pages',
        component: statistics111Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(ประเภทรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'userinspector',
        name: 'userInspectorPages',
        component: userInspectorPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้ตรวจสอบ',
          requiresAuth:true,
        }
      },
      {
        path: 'punishment',
        name: 'punishmentPages',
        component: punishmentPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการการลงโทษสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'hardware',
        name: 'userHWPages',
        component: userHWPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลอุปกรณ์ฮาร์ดแวร์ทั้งหมดที่ลงทะเบียน',
          requiresAuth:true,
        }
      },
      {
        path: 'userauthorizer',
        name: 'userAuthorizerPages',
        component: userAuthorizerPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้ได้รับมอบอำนาจ',
          requiresAuth:true,
        }
      },
      {
        path: 'userliabilities',
        name: 'userLiabilitiesPages',
        component: userLiabilitiesPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้มีอำนาจลงนามผูกพัน',
          requiresAuth:true,
        }
      },
      {
        path: 'userowner',
        name: 'userOwnerPages',
        component: userOwnerPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลผู้ได้รับใบอนุญาตจัดตั้ง',
          requiresAuth:true,
        }
      },
      {
        path: 'download31',
        name: 'download31Pages',
        component: download31Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลดรายละเอียดสถานตรวจสภาพรถ(วันและเวลาทำการ)',
          requiresAuth:true,
        }
      },
      {
        path: 'download21',
        name: 'download21Pages',
        component: download21Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลดสถิติการส่งรายงานการตรวจตรวจเข้า MDM',
          requiresAuth:true,
        }
      },
      {
        path: 'download12',
        name: 'download12Pages',
        component: download12Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลดรายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์ (แบบละเอียด)',
          requiresAuth:true,
        }
      },
      {
        path: 'download11',
        name: 'download11Pages',
        component: download11Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลดรายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'post',
        name: 'postPages',
        component: postPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข่าวประชาสัมพันธ์ (ภายนอก)',
          requiresAuth:true,
        }
      },
      {
        path: 'alert',
        name: 'alertPages',
        component: alertPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข่าวประชาสัมพันธ์ (ภายใน)',
          requiresAuth:true,
        }
      },
      {
        path: 'userstatistics',
        name: 'userstatisticsPages',
        component: userstatisticsPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเจ้าหน้าที่สถิติการขนส่ง',
          requiresAuth:true,
        }
      },
      {
        path: 'usercheckdata',
        name: 'usercheckdataPages',
        component: usercheckdataPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเจ้าหน้าที่ตรวจสอบข้อมูล',
          requiresAuth:true,
        }
      },
      {
        path: 'useradmin',
        name: 'useradminPages',
        component: useradminPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเจ้าหน้าที่วิศวกรรมยานยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'usercounty',
        name: 'usercountyPages',
        component: usercountyPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเจ้าหน้าที่ สขพ./สขจ.',
          requiresAuth:true,
        }
      },
      {
        path: 'cctv',
        name: 'cctvPages',
        component: cctvPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลกล้องวงจรปิดของสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'comment',
        name: 'commentPages',
        component: commentPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการเรื่องร้องเรียนและข้อเสนอแนะ',
          requiresAuth:true,
        }
      },
      {
        path: 'district',
        name: 'districtPages',
        component: districtPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลอำเภอ',
          requiresAuth:true,
        }
      },
      {
        path: 'carbrand',
        name: 'carbrandPage',
        component: carbrandPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลยี่ห้รถ',
          requiresAuth:true,
        }
      },
      {
        path: 'county',
        name: 'countyPage',
        component: countyPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลจังหวัดและเขตการปกครอง',
          requiresAuth:true,
        }
      },
      {
        path: 'editpassword',
        name: 'editpasswordPage',
        component: editpasswordPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | แก้ไขรหัสผ่าน',
          requiresAuth:true,
        }
      },
      {
        path: 'settingstatus',
        name: 'settingstatusPage',
        component: settingstatusPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตั้งค่าสถานะการทำงานของระบบ',
          requiresAuth:true,
        }
      },
      {
        path: 'settingsmoke',
        name: 'settingsmokePage',
        component: settingsmokePage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตั้งค่าเกณฑ์มาตรฐานต่างๆ',
          requiresAuth:true,
        }
      },
      {
        path: 'serverdetail',
        name: 'serverdetailPage',
        component: serverdetailPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลประวัติการทำงานของระบบ',
          requiresAuth:true,
        }
      },
      {
        path: 'history',
        name: 'hitstoryPage',
        component: hitstoryPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลประวัติการทำงานของระบบ',
          requiresAuth:true,
        }
      },
      {
        path: 'datatruckv5detail/:id',
        name: 'datatruckv5detailPage',
        component: datatruckv5detailPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดผลการตรวจสภาพรถขนส่ง v5',
          requiresAuth:true,
        }
      },
      {
        path: 'datacarv5detail/:id',
        name: 'datacarv5detailPage',
        component: datacarv5detailPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดผลการตรวจสภาพรถ v5',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreportv5truck',
        name: 'inspectionreportv5truckPage',
        component: inspectionreportv5truckPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายงานผลการตรวจรถขนส่ง v5',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreportv5',
        name: 'inspectionreportv5Page',
        component: inspectionreportv5Page,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายงานผลการตรวจรถยนต์ v5',
          requiresAuth:true,
        }
      },
      {
        path: 'faq',
        name: 'faqPage',
        component: faqPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | วิธีการใช้งาน',
          requiresAuth:true,
        }
      },
      {
        path: 'download',
        name: 'downloadPage',
        component: downloadPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลด',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionowner',
        name: 'inspectionownerPage',
        component: inspectionownerPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'datatruckpdf/:id',
        name: 'datatruckPdf',
        component: datatruckPdf,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายว่ารถขนส่งไฟล์ PDF',
          requiresAuth:true,
        }
      },
      {
        path: 'datatruckdetail/:id',
        name: 'datatruckDetail',
        component: datatruckDetail,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถขนส่ง',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreporttruck',
        name: 'inspectionreporttruck',
        component: inspectionReportTruck,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถขนส่ง',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreportlog',
        name: 'inspectionreportlog',
        component: inspectionReportLog,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์ ที่ตรวจแก้',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreport',
        name: 'inspectionreport',
        component: inspectionReport,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'datacardetail/:id',
        name: 'datacarDetail',
        component: datacarDetail,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'datacardetails/:id',
        name: 'datacarDetails',
        component: datacarDetails,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถยนต์ ที่ตรวจแก้',
          requiresAuth:true,
        }
      },
      {
        path: 'datacarpdf/:id',
        name: 'datacarPdf',
        component: datacarPdf,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์ไฟล์ PDF',
          requiresAuth:true,
        }
      },
      {
        path: 'datacarpdfs/:id',
        name: 'datacarPdfs',
        component: datacarPdfs,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์ไฟล์ PDF ที่ตรวจแก้',
          requiresAuth:true,
        }
      },

    ]
  },
]
const router=createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(async (to, from, next) => {
  // if(to.meta.title){
  //   document.title = to.meta.title;
  //   const  path = ['/','/auth/login','/auth/register'];
  //   //  if(path.includes(to.path) || localStorage.getItem('User')){
  //   // if(path.includes(to.path)){
  //     return next();
  //   //  }else{
  //     // console.log(localStorage.getItem('public_token'))
  //     // return next('/auth/login');
  //   //  }
  // }

  try {
    document.title = to.meta.title;
    const authenticated = await is_authenticated();
    // check login 
    if (to.meta.requiresAuth && !authenticated.status) {
      return next('/');
    }
    if ((to.path === "/") && authenticated.status) {
      return next('/user/dashboard');
    }
    return next();
  } catch (err) {
    console.log('server is down');
  }
});

async function is_authenticated() {
  // const v6Store = localStorage.getItem('v6')
  const v6Store = ls.get('v6')
  if(v6Store){
    try {
      const response = await axios.post("/authenticated",{
        token : v6Store
      }); 
      // const BaseUrlImages = 'http://localhost:8002/';
      const BaseUrlImages = 'https://api.dlt.in.th/';
      if(response.data.status == 1){
        // console.log(response.data.datas.id)
        storeAuth.id = response.data.datas.id;
        storeAuth.group_id = response.data.datas.group_id;
        storeAuth.first_name = response.data.datas.first_name;
        storeAuth.last_name = response.data.datas.last_name;
        // image car and truck
        storeAuth.UrlImagesCar = BaseUrlImages+'inspection/';
        storeAuth.UrlImagesTruck = BaseUrlImages+'inspection/truck/';
        // Popup
        storeAuth.UrlImagesPopup = BaseUrlImages+'popup/';
        // ToolsType 
        storeAuth.UrlImagesToolsType = BaseUrlImages+'upload/geequipmentstools/';
        storeAuth.UrlFileToolsType = BaseUrlImages+'upload/geequipmentstools_file/';
        // Tools
        storeAuth.UrlImagesTools = BaseUrlImages+'upload/inspectionsmanageequipments/';
        storeAuth.UrlFileTools = BaseUrlImages+'upload/inspectionsmanageequipments_file/';
        // UserTro
        storeAuth.UrlImagesTroOffice = BaseUrlImages+'upload/manageinspectioncenter/office/';
        storeAuth.UrlImagesTro = BaseUrlImages+'upload/manageinspectioncenter/tro/';
        storeAuth.UrlFileTro = BaseUrlImages+'upload/manageinspectioncenter_file/';
        // UserTech
        storeAuth.UrlImagesTechProfile = BaseUrlImages+'upload/manageusers/';
        storeAuth.UrlImagesTechCard = BaseUrlImages+'upload/manageusers/card/';
        storeAuth.UrlFileTech = BaseUrlImages+'upload/manageusers_file/';
        return{
          status : true,
        }
      }
    } catch (err) {
      return{
        status : false,
      }
    }
  }else{
    return{
      status : false,
    }
  }
}
export default router