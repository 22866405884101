
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการตรวจสภาพ(สถานตรวจสภาพ)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">เลขที่ใบอนุญาต</label>
                        <div class="col-sm-5">
                            <multiselect :options="tro_name_id_value" :searchable="true" v-model="license_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ตั้งแต่วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ถึง</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ดูรายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                    <div id="printableTable" class="col-sm-12 text-center table-responsive">
                      <table class="table table-bordered" ref="tbdata">
                          <tr>
                              <th colspan="3"><h4>สถิติตรวจสภาพรถ เลขที่ใบอนุญาต : {{ license_id.license_number + ' / ' + license_id.nameaddresscar }}</h4></th>
                          </tr>
                          <tr>
                              <th colspan="3">ตั้งแต่วันที่ {{ new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/') }} ถึงวันที่ {{ new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/') }}</th>
                          </tr>
                          <tr>
                              <th colspan="3">ประเภท รย.1 จำนวนรถที่ตรวจสภาพ (คัน)</th>
                          </tr>
                          <tr>
                              <td>ผ่าน</td>
                              <td>ไม่ผ่าน</td>
                              <td>รวม</td>
                          </tr>
                          <tr>
                              <td>{{ countdata.count_true_1 }}</td>
                              <td>{{ countdata.count_false_1 }}</td>
                              <td>{{ countdata.count_total_1 }}</td>
                          </tr>
                          <tr>
                              <th colspan="3">ประเภท รย.2 จำนวนรถที่ตรวจสภาพ (คัน)</th>
                          </tr>
                          <tr>
                              <td>ผ่าน</td>
                              <td>ไม่ผ่าน</td>
                              <td>รวม</td>
                          </tr>
                          <tr>
                              <td>{{ countdata.count_true_2 }}</td>
                              <td>{{ countdata.count_false_2 }}</td>
                              <td>{{ countdata.count_total_2 }}</td>
                          </tr>
                          <tr>
                              <th colspan="3">ประเภท รย.3 จำนวนรถที่ตรวจสภาพ (คัน)</th>
                          </tr>
                          <tr>
                              <td>ผ่าน</td>
                              <td>ไม่ผ่าน</td>
                              <td>รวม</td>
                          </tr>
                          <tr>
                              <td>{{ countdata.count_true_3 }}</td>
                              <td>{{ countdata.count_false_3 }}</td>
                              <td>{{ countdata.count_total_3 }}</td>
                          </tr>
                          <tr>
                              <th colspan="3">ประเภท รย.12 จำนวนรถที่ตรวจสภาพ (คัน)</th>
                          </tr>
                          <tr>
                              <td>ผ่าน</td>
                              <td>ไม่ผ่าน</td>
                              <td>รวม</td>
                          </tr>
                          <tr>
                              <td>{{ countdata.count_true_12 }}</td>
                              <td>{{ countdata.count_false_12 }}</td>
                              <td>{{ countdata.count_total_12 }}</td>
                          </tr>
                      </table>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          license_id:'',
          date_start:'',
          date_end:'',
          countdata:{
              count_status:'',
              count_true_1:'',
              count_false_1:'',
              count_total_1:'',
              count_true_2:'',
              count_false_2:'',
              count_total_2:'',
              count_true_3:'',
              count_false_3:'',
              count_total_3:'',
              count_true_12:'',
              count_false_12:'',
              count_total_12:'',
            },
            tro_name_id_value:[]
          }
      }, 
  methods:{
      getdata(){
        if(this.tro_name_id_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.tro_name_id_value = res.data.datas.datag4;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      tro_name_id_select({license_number,nameaddresscar}) {
        return `${license_number} / ${nameaddresscar}`
      },
      statistics(){
          if(!this.license_id || !this.date_start || !this.date_end){
            this.$swal({
              title:'คำเตือน', 
              text:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ดูรายงาน"',
              icon:'warning', 
              }) 
          }else{
                let data = {
                      uid: storeAuth.id,
                      gid: storeAuth.group_id,
                      dt:112,
                      datas:[this.license_id,this.date_start,this.date_end]
                    }
              this.getcount(data);
          }
      },
      getcount(data){
          this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.$swal.showLoading()
      
          axios.post(`statistics`,data).then(rs=>{
              if(rs.data.status == 1){
                if(rs.data.datas){
                  this.$swal.close();
                  this.countdata = rs.data.datas;
                }else{
                  this.$swal({
                    title:'ไม่พบข้อมูล', 
                    text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                    icon:'info', 
                  }) 
                }
              }else{
                this.$swal({
                  title:'เกิดข้อผิดพลาด', 
                  text:'ไม่สามารถค้นหาข้อมูลได้',
                  icon:'error', 
                }) 
              }
            }).catch(err=>{
              swal.close();
              console.log(err)
          })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'สถิติการตรวจสภาพ(สถานตรวจสภาพ).' + type);
      },
      async printDiv() {
         await this.$htmlToPaper('printableTable');
       }
  }, 
  computed:{}, 
  watch:{}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>