
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ตั้งค่าเกณฑ์มาตรฐานต่างๆ"/>

        <div class="row starter-main">
          <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึกข้อมูล</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="validationCustom01">ค่าควันดำแบบกระดาษกรอง Filter</label>
                            <input class="form-control" id="validationCustom01" type="number" v-model="smoke_filter" required="">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="validationCustom02">ค่าควันดำแบบกระดาษกรอง Opacity</label>
                            <input class="form-control" id="validationCustom02" type="number" v-model="smoke_opacity" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="validationCustom01">เปิด/ปิด ฟังชั่นการตรวจสอบฮาร์ดแวร์ที่หน้าตรวจสภาพรถ</label>
                            <div class="media-body text-start switch-lg icon-state"><label class="switch"><input type="checkbox"  :true-value="1" :false-value="0" v-model="check_hw"><span class="switch-state bg-secondary"></span></label></div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="validationCustom02">เปิด/ปิด ฟังชั่นการตรวจสอบค่าเบรคซ้ำที่หน้าตรวจสภาพรถ</label>
                            <div class="media-body text-start switch-lg icon-state"><label class="switch"><input type="checkbox" :true-value="1" :false-value="0" v-model="check_repeat"><span class="switch-state bg-warning"></span></label></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <div class="checkbox p-0">
                            <input class="form-check-input" id="invalidCheck" type="checkbox" required="" v-model="confirmsetting">
                            <label class="form-check-label" for="invalidCheck">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</label>
                            </div>
                            <div class="invalid-feedback">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</div>
                        </div>
                    </div>
                    <button class="btn btn-primary" type="button" @click="savedata">บันทึกข้อมูล</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref,onMounted,inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

const smoke_filter = ref();
const smoke_opacity = ref();
const check_hw = ref();
const check_repeat = ref();
const confirmsetting = ref();

onMounted(() => {
  getdata_table();
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`setting`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    smoke_filter.value = res.data.datas[0].smoke_filter
    smoke_opacity.value = res.data.datas[0].smoke_opacity
    check_hw.value = res.data.datas[0].check_hw
    check_repeat.value = res.data.datas[0].check_repeat
    Swal.close()
  }else{
    Swal.close()
  }
}

async function savedata() {

    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    if(!confirmsetting.value){
        Swal.fire({
            title: "คำเตือน",
            text: "กรุณาติ๊กถูกที่ \"กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง\" เพื่อยืนยันก่อน",
            icon: "warning"
        });
    }else{
        if(!smoke_filter.value || !smoke_opacity.value){
            Swal.fire({
                title: "คำเตือน",
                text: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
                icon: "warning"
            });
        }else{
            console.log(check_repeat.value)
            const res = await axios.post(`setting`,{
                uid: storeAuth.id,
                gid: storeAuth.group_id,
                tid: 2,
                smoke_filter : smoke_filter.value,
                smoke_opacity : smoke_opacity.value,
                check_hw : check_hw.value,
                check_repeat : check_repeat.value,
            });
            if(res.data.status == 1){
                confirmsetting.value = '';
                getdata_table();
                Swal.close();
                Swal.fire({
                    title: "สำเร็จ",
                    text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                    icon: "success"
                }); 
            }else{
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "บันทึกข้อมูลไม่สำเร็จ",
                    icon: "error"
                }); 
            }
        }
    }
}


</script>

<style>
</style>