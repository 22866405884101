
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ดาวน์โหลดรายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์(ค้นหาแบบรายละเอียด)"/>

      <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body" >
                    <div class="alert alert-info">
                      <strong>คำแนะนำ!</strong> ตัวเลือกค้นหาให้เลือกวันที่ และ (เลขที่ใบอนุญาตหรือจังหวัดที่กำกับดูแลควบคู่กันทุกครั้ง)
                    </div>
                    <div class="needs-validation mt-4">
                      <div class="row">
                        <div class="form-group row col-sm-12 mb-2">
                            <label class="col-sm-5 col-form-label text-end">เลขที่ใบอนุญาต</label>
                            <div class="col-sm-5">
                                <multiselect :options="tro_name_id_value" :searchable="true" v-model="license_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                            </div>
                        </div>
                        <div class="form-group row col-sm-12 mb-2">
                            <label class="col-sm-5 col-form-label text-end" >จังหวัดที่กำกับดูแล</label>
                            <div class="col-sm-5">
                                <multiselect :options="searchcounty_area_value" :searchable="true" v-model="searchcounty_by" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                            </div>
                        </div>
                        <div class="form-group row col-sm-12 mb-2">
                          <label class="col-sm-5 col-form-label text-end">รูปแบบการค้นหา</label>
                          <div class="col-sm-5">
                              <select class="selectpicker form-control" data-live-search="true" v-model="download_type">
                                  <option value="">กรุณาเลือก</option>
                                  <option value="1">ข้อมูลซ้ำ</option>
                                  <option value="2">ข้อมูลที่ต้องการค้นหา</option>
                              </select>
                          </div>
                        </div>
                      </div>
                      <hr/>
                      <div v-if="download_type == 1" class="row">
                        <div class="form-group row col-sm-6">
                            <label class="col-sm-5 col-form-label text-end" >ตั้งวันที่</label>
                            <div class="col-sm-5">
                                <VueDatePicker locale="th" v-model="date_start" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                            </div>
                        </div>
                        <div class="form-group row col-sm-6">
                            <label class="col-sm-5 col-form-label text-end" >ถึงวันที่</label>
                            <div class="col-sm-5">
                                <VueDatePicker locale="th" v-model="date_to" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                            </div>
                        </div>
                        <div class="form-group row col-sm-12 mt-2">
                          <label class="col-sm-5 col-form-label text-end">ประเภทรถ</label>
                          <div class="col-sm-5">
                              <select class="form-control" v-model="checkbox15">
                                  <option value="">กรุณาเลือก</option>
                                  <option value="1">รย.1 / รย.2 / รย.3</option>
                                  <option value="12">รย.12</option>
                              </select>
                          </div>
                        </div>
                        <div v-if="checkbox15 == 1"  class="text-center col-sm-12 mt-4">
                            <h6><u>ห้ามล้อ</u></h6>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-1" type="checkbox" v-model="checkbox1">
                              <label class="form-check-label" for="checkbox-primary-1">เพลา 1</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-2" type="checkbox" v-model="checkbox2">
                              <label class="form-check-label" for="checkbox-primary-2">เพลา 2</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-3" type="checkbox" v-model="checkbox3">
                              <label class="form-check-label" for="checkbox-primary-3">เพลา 3</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-4" type="checkbox" v-model="checkbox4">
                              <label class="form-check-label" for="checkbox-primary-4">เพลา 4</label>
                            </div>
                        </div>
                        <div v-if="checkbox15 == 1"  class="text-center col-sm-12 mt-4">
                            <h6><u>น้ำหนัก</u></h6>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-9" type="checkbox" v-model="checkbox9">
                              <label class="form-check-label" for="checkbox-primary-9">เพลา 1</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-10" type="checkbox" v-model="checkbox10">
                              <label class="form-check-label" for="checkbox-primary-10">เพลา 2</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-11" type="checkbox" v-model="checkbox11">
                              <label class="form-check-label" for="checkbox-primary-11">เพลา 3</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-12" type="checkbox" v-model="checkbox12">
                              <label class="form-check-label" for="checkbox-primary-12">เพลา 4</label>
                            </div>
                        </div>
                        <div v-if="checkbox15" class="text-center col-sm-12 mt-4">
                            <h6><u>โคมไฟหน้า</u></h6>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-13" type="checkbox" v-model="checkbox13">
                              <label class="form-check-label" for="checkbox-primary-13">ค่าโคมไฟ</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-14" type="checkbox" v-model="checkbox14">
                              <label class="form-check-label" for="checkbox-primary-14">ตำแหน่งโคมไฟ</label>
                            </div>
                        </div>
                        <div v-if="checkbox15" class="text-center col-sm-12 mt-4">
                            <h6><u>อื่นๆ</u></h6>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-5" type="checkbox" v-model="checkbox5">
                              <label class="form-check-label" for="checkbox-primary-5">ศูนย์ล้อ</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-6" type="checkbox" v-model="checkbox6">
                              <label class="form-check-label" for="checkbox-primary-6">เสียง</label>
                            </div>
                            <h6><u>ค่า CO/HC (รย.12 หรือเกณฑ์เก่า)</u></h6>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-7" type="checkbox" v-model="checkbox7">
                              <label class="form-check-label" for="checkbox-primary-7">CO</label>
                            </div>
                            <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-8" type="checkbox" v-model="checkbox8">
                              <label class="form-check-label" for="checkbox-primary-8">HC</label>
                            </div>
                            <h6 v-if="checkbox15 == 1"><u>ค่า CO/HC (รอบต่ำ น้ำมัน)</u></h6>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-7-1" type="checkbox" v-model="checkbox7_1">
                              <label class="form-check-label" for="checkbox-primary-7-1">CO</label>
                            </div>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-8-1" type="checkbox" v-model="checkbox8_1">
                              <label class="form-check-label" for="checkbox-primary-8-1">HC</label>
                            </div>
                            <h6 v-if="checkbox15 == 1"><u>ค่า CO/HC (รอบต่ำ ก๊าซ)</u></h6>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-7-2" type="checkbox" v-model="checkbox7_2">
                              <label class="form-check-label" for="checkbox-primary-7-2">CO</label>
                            </div>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-8-2" type="checkbox" v-model="checkbox8_2">
                              <label class="form-check-label" for="checkbox-primary-8-2">HC</label>
                            </div>
                            <h6 v-if="checkbox15 == 1"><u>ค่า CO/HC (รอบสูง น้ำมัน)</u></h6>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-7-3" type="checkbox" v-model="checkbox7_3">
                              <label class="form-check-label" for="checkbox-primary-7-3">CO</label>
                            </div>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-8-3" type="checkbox" v-model="checkbox8_3">
                              <label class="form-check-label" for="checkbox-primary-8-3">HC</label>
                            </div>
                            <h6 v-if="checkbox15 == 1"><u>ค่า CO/HC (รอบสูง ก๊าซ)</u></h6>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-7-4" type="checkbox" v-model="checkbox7_4">
                              <label class="form-check-label" for="checkbox-primary-7-4">CO</label>
                            </div>
                            <div v-if="checkbox15 == 1" class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                              <input class="form-check-input" id="checkbox-primary-8-4" type="checkbox" v-model="checkbox8_4">
                              <label class="form-check-label" for="checkbox-primary-8-4">HC</label>
                            </div>
                        </div>
                        <br/>
                        <div v-if="checkbox15" class="col-md-12 text-center mt-3">
                            <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics1" type="button">ค้นหารายละเอียด</button>
                        </div>
                      </div>
                      <div v-if="download_type == 2" class="row">
                        <div class="form-group row col-sm-12">
                            <label class="col-sm-5 col-form-label text-end" >วันที่</label>
                            <div class="col-sm-5">
                                <VueDatePicker locale="th" v-model="date_start" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                            </div>
                        </div>
                        <div class="text-center col-sm-12 mt-4">
                          <div class="form-check checkbox checkbox-primary mb-0 ">
                            <input class="form-check-input" id="checkbox-primary-1-1" type="checkbox" v-model="checkbox_1">
                            <label class="form-check-label" for="checkbox-primary-1-1">ห้ามล้อ</label>
                          </div>
                            <div v-if="checkbox_1 == 1">
                              <div class="row">
                                <div class="form-group row col-sm-2">
                                  <div class="form-check checkbox checkbox-primary mb-0">
                                    <input class="form-check-input" id="checkbox-primary-1-1-1" type="checkbox" v-model="checkbox_1_1">
                                    <label class="form-check-label" for="checkbox-primary-1-1-1">เพลา 1</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_1" :disabled="checkbox_1_1 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 1 ซ้าย" v-model="data_1_2" :disabled="checkbox_1_1 != 1">
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_3" :disabled="checkbox_1_1 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 1 ขวา" v-model="data_1_4" :disabled="checkbox_1_1 != 1">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group row col-sm-2">
                                   <div class="form-check checkbox checkbox-primary mb-0">
                                    <input class="form-check-input" id="checkbox-primary-1-1-2" type="checkbox" v-model="checkbox_1_2">
                                    <label class="form-check-label" for="checkbox-primary-1-1-2">เพลา 2</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_5" :disabled="checkbox_1_2 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 2 ซ้าย" v-model="data_1_6" :disabled="checkbox_1_2 != 1">
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_7" :disabled="checkbox_1_2 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 2 ขวา" v-model="data_1_8" :disabled="checkbox_1_2 != 1">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group row col-sm-2">
                                  <div class="form-check checkbox checkbox-primary mb-0">
                                    <input class="form-check-input" id="checkbox-primary-1-1-3" type="checkbox" v-model="checkbox_1_3">
                                    <label class="form-check-label" for="checkbox-primary-1-1-3">เพลา 3</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_9" :disabled="checkbox_1_3 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 3 ซ้าย" v-model="data_1_10" :disabled="checkbox_1_3 != 1">
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_11" :disabled="checkbox_1_3 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 3 ขวา" v-model="data_1_12" :disabled="checkbox_1_3 != 1">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group row col-sm-2">
                                   <div class="form-check checkbox checkbox-primary mb-0">
                                      <input class="form-check-input" id="checkbox-primary-1-1-4" type="checkbox" v-model="checkbox_1_4">
                                      <label class="form-check-label" for="checkbox-primary-1-1-4">เพลา 4</label>
                                    </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_13" :disabled="checkbox_1_4 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 4 ซ้าย" v-model="data_1_14" :disabled="checkbox_1_4 != 1">
                                  </div>
                                </div>
                                <div class="form-group row col-sm-5">
                                  <div class="col-sm-4">
                                      <select class="form-control" v-model="data_1_15" :disabled="checkbox_1_4 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-8">
                                    <input class="form-control" type="number" placeholder="ระบุข้อมูลแรงห้ามล้อ 4 ขวา" v-model="data_1_16" :disabled="checkbox_1_4 != 1">
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="text-center col-sm-12 mt-4">
                            <div class="form-check checkbox checkbox-primary mb-0 ">
                              <input class="form-check-input" id="checkbox-primary-1-2" type="checkbox" v-model="checkbox_2">
                              <label class="form-check-label" for="checkbox-primary-1-2">น้ำหนัก</label>
                            </div>
                            <div v-if="checkbox_2 == 1">
                              <div class="row">
                                <div class="form-group row col-sm-4">
                                   <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                      <input class="form-check-input" id="checkbox-primary-1-2-1" type="checkbox" v-model="checkbox_2_1">
                                      <label class="form-check-label" for="checkbox-primary-1-2-1">เพลา 1</label>
                                    </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                  <div class="col-sm-5">
                                      <select class="form-control" v-model="data_2_1" :disabled="checkbox_2_1 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                    <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                    <div class="col-sm-5">
                                        <input class="form-control" type="number" v-model="data_2_2" :disabled="checkbox_2_1 != 1">
                                    </div>
                                </div>
                              </div>
                               <div class="row">
                                <div class="form-group row col-sm-4">
                                  <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                    <input class="form-check-input" id="checkbox-primary-1-2-2" type="checkbox" v-model="checkbox_2_2">
                                    <label class="form-check-label" for="checkbox-primary-1-2-2">เพลา 2</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                  <div class="col-sm-5">
                                      <select class="form-control" v-model="data_2_3" :disabled="checkbox_2_2 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                    <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                    <div class="col-sm-5">
                                        <input class="form-control" type="number" v-model="data_2_4" :disabled="checkbox_2_2 != 1">
                                    </div>
                                </div>
                              </div>
                               <div class="row">
                                <div class="form-group row col-sm-4">
                                  <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                    <input class="form-check-input" id="checkbox-primary-1-2-3" type="checkbox" v-model="checkbox_2_3">
                                    <label class="form-check-label" for="checkbox-primary-1-2-3">เพลา 3</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                  <div class="col-sm-5">
                                      <select class="form-control" v-model="data_2_5" :disabled="checkbox_2_3 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                    <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                    <div class="col-sm-5">
                                        <input class="form-control" type="number" v-model="data_2_6" :disabled="checkbox_2_3 != 1">
                                    </div>
                                </div>
                              </div>
                               <div class="row">
                                <div class="form-group row col-sm-4">
                                  <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                    <input class="form-check-input" id="checkbox-primary-1-2-4" type="checkbox" v-model="checkbox_2_4">
                                    <label class="form-check-label" for="checkbox-primary-1-2-4">เพลา 4</label>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                  <div class="col-sm-5">
                                      <select class="form-control" v-model="data_2_7" :disabled="checkbox_2_4 != 1">
                                          <option value="1">เท่ากับ</option>
                                          <option value="2">น้อยกว่า</option>
                                          <option value="3">มากกว่า</option>
                                      </select>
                                  </div>
                                </div>
                                <div class="form-group row col-sm-4">
                                    <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                    <div class="col-sm-5">
                                        <input class="form-control" type="number" v-model="data_2_8" :disabled="checkbox_2_4 != 1">
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="text-center col-sm-12 mt-4">
                          <div class="form-check checkbox checkbox-primary mb-0 ">
                            <input class="form-check-input" id="checkbox-primary-1-4" type="checkbox" v-model="checkbox_4">
                            <label class="form-check-label" for="checkbox-primary-1-4">โคมไฟหน้า</label>
                          </div>
                          <div v-if="checkbox_4 == 1">
                            <div class="row">
                              <div class="form-group row col-sm-2">
                                <div class="form-check checkbox checkbox-primary mb-0">
                                  <input class="form-check-input" id="checkbox-primary-1-4-1" type="checkbox" v-model="checkbox_4_1">
                                  <label class="form-check-label" for="checkbox-primary-1-4-1">ค่าโคมไฟพุ่งไกล</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_1" :disabled="checkbox_4_1 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลค่าโคมไฟพุ่งไกล ด้านซ้าย" v-model="data_4_2" :disabled="checkbox_4_1 != 1">
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_3" :disabled="checkbox_4_1 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลค่าโคมไฟพุ่งไกล ด้านขวา" v-model="data_4_4" :disabled="checkbox_4_1 != 1">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-2">
                                  <div class="form-check checkbox checkbox-primary mb-0">
                                  <input class="form-check-input" id="checkbox-primary-1-4-2" type="checkbox" v-model="checkbox_4_2">
                                  <label class="form-check-label" for="checkbox-primary-1-4-2">ค่าโคมไฟพุ่งต่ำ</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_5" :disabled="checkbox_4_2 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลค่าโคมไฟพุ่งต่ำ ด้านซ้าย" v-model="data_4_6" :disabled="checkbox_4_2 != 1">
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_7" :disabled="checkbox_4_2 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลค่าโคมไฟพุ่งต่ำ ด้านขวา" v-model="data_4_8" :disabled="checkbox_4_2 != 1">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-2">
                                <div class="form-check checkbox checkbox-primary mb-0">
                                  <input class="form-check-input" id="checkbox-primary-1-4-3" type="checkbox" v-model="checkbox_4_3">
                                  <label class="form-check-label" for="checkbox-primary-1-4-3">ตำแหน่งโคมไฟพุ่งไกล</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_9" :disabled="checkbox_4_3 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย" v-model="data_4_10" :disabled="checkbox_4_3 != 1">
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_11" :disabled="checkbox_4_3 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลตำแหน่งโคมไฟพุ่งไกล ด้านขวา" v-model="data_4_12" :disabled="checkbox_4_3 != 1">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-2">
                                  <div class="form-check checkbox checkbox-primary mb-0">
                                    <input class="form-check-input" id="checkbox-primary-1-4-4" type="checkbox" v-model="checkbox_4_4">
                                    <label class="form-check-label" for="checkbox-primary-1-4-4">ตำแหน่งโคมไฟพุ่งต่ำ</label>
                                  </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_13" :disabled="checkbox_4_4 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย" v-model="data_4_14" :disabled="checkbox_4_4 != 1">
                                </div>
                              </div>
                              <div class="form-group row col-sm-5">
                                <div class="col-sm-4">
                                    <select class="form-control" v-model="data_4_15" :disabled="checkbox_4_4 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                                <div class="col-sm-8">
                                  <input class="form-control" type="number" placeholder="ระบุข้อมูลตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา" v-model="data_4_16" :disabled="checkbox_4_4 != 1">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center col-sm-12 mt-4">
                          <div class="form-check checkbox checkbox-primary mb-0 ">
                            <input class="form-check-input" id="checkbox-primary-1-3" type="checkbox" v-model="checkbox_3">
                            <label class="form-check-label" for="checkbox-primary-1-3">อื่น(ศูนย์ล้อ,เสียง,CO,HC,ประเภทเครื่องยนต์,กดตรวจสอบข้อมูล,ผลการตรวจ)</label>
                          </div>
                          <div v-if="checkbox_3 == 1">
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-1" type="checkbox" v-model="checkbox_3_1">
                                  <label class="form-check-label" for="checkbox-primary-1-3-1">ศูนย์ล้อ</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_1" :disabled="checkbox_3_1 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_2" :disabled="checkbox_3_1 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-2" type="checkbox" v-model="checkbox_3_2">
                                  <label class="form-check-label" for="checkbox-primary-1-3-2">เสียง</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_3" :disabled="checkbox_3_2 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_4" :disabled="checkbox_3_2 != 1">
                                  </div>
                              </div>
                            </div>
                            <h6><u>ค่า CO/HC (รย.12 หรือเกณฑ์เก่า)</u></h6>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-3" type="checkbox" v-model="checkbox_3_3">
                                  <label class="form-check-label" for="checkbox-primary-1-3-3">CO</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_5" :disabled="checkbox_3_3 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_6" :disabled="checkbox_3_3 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-4" type="checkbox" v-model="checkbox_3_4">
                                  <label class="form-check-label" for="checkbox-primary-1-3-4">HC</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_7" :disabled="checkbox_3_4 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_8" :disabled="checkbox_3_4 != 1">
                                  </div>
                              </div>
                            </div>
                            <h6><u>ค่า CO/HC (รอบต่ำ น้ำมัน)</u></h6>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-3-1" type="checkbox" v-model="checkbox_3_3_1">
                                  <label class="form-check-label" for="checkbox-primary-1-3-3-1">CO</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_5_1" :disabled="checkbox_3_3_1 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_6_1" :disabled="checkbox_3_3_1 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-4-1" type="checkbox" v-model="checkbox_3_4_1">
                                  <label class="form-check-label" for="checkbox-primary-1-3-4-1">HC</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_7_1" :disabled="checkbox_3_4_1 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_8_1" :disabled="checkbox_3_4_1 != 1">
                                  </div>
                              </div>
                            </div>
                            <h6><u>ค่า CO/HC (รอบต่ำ ก๊าซ)</u></h6>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-3-2" type="checkbox" v-model="checkbox_3_3_2">
                                  <label class="form-check-label" for="checkbox-primary-1-3-3-2">CO</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_5_2" :disabled="checkbox_3_3_2 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_6_2" :disabled="checkbox_3_3_2 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-4-2" type="checkbox" v-model="checkbox_3_4_2">
                                  <label class="form-check-label" for="checkbox-primary-1-3-4-2">HC</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_7_2" :disabled="checkbox_3_4_2 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_8_2" :disabled="checkbox_3_4_2 != 1">
                                  </div>
                              </div>
                            </div>
                            <h6><u>ค่า CO/HC (รอบสูง น้ำมัน)</u></h6>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-3-3" type="checkbox" v-model="checkbox_3_3_3">
                                  <label class="form-check-label" for="checkbox-primary-1-3-3-3">CO</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_5_3" :disabled="checkbox_3_3_3 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_6_3" :disabled="checkbox_3_3_3 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-4-3" type="checkbox" v-model="checkbox_3_4_3">
                                  <label class="form-check-label" for="checkbox-primary-1-3-4-3">HC</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_7_3" :disabled="checkbox_3_4_3 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_8_3" :disabled="checkbox_3_4_3 != 1">
                                  </div>
                              </div>
                            </div>
                            <h6><u>ค่า CO/HC (รอบสูง ก๊าซ)</u></h6>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-3-4" type="checkbox" v-model="checkbox_3_3_4">
                                  <label class="form-check-label" for="checkbox-primary-1-3-3-4">CO</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_5_4" :disabled="checkbox_3_3_4 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_6_4" :disabled="checkbox_3_3_4 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group row col-sm-4">
                                <div class="form-check checkbox checkbox-primary mb-0  form-check-inline">
                                  <input class="form-check-input" id="checkbox-primary-1-3-4-4" type="checkbox" v-model="checkbox_3_4_4">
                                  <label class="form-check-label" for="checkbox-primary-1-3-4-4">HC</label>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                <label class="col-sm-5 col-form-label">รูปแบบการค้นหา</label>
                                <div class="col-sm-5">
                                    <select class="form-control" v-model="data_3_7_4" :disabled="checkbox_3_4_4 != 1">
                                        <option value="1">เท่ากับ</option>
                                        <option value="2">น้อยกว่า</option>
                                        <option value="3">มากกว่า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-4">
                                  <label class="col-sm-5 col-form-label">ค่าที่ต้องการ</label>
                                  <div class="col-sm-5">
                                      <input class="form-control" type="number" v-model="data_3_8_4" :disabled="checkbox_3_4_4 != 1">
                                  </div>
                              </div>
                            </div>
                            <div class="row mt-4">
                              <div class="form-group row col-sm-6 mt-2">
                                <label class="col-sm-6 col-form-label">ประเภทเครื่องยนต์</label>
                                <div class="col-sm-6">
                                    <select class="form-control" v-model="data_3_9">
                                        <option value="">กรุณาเลือก</option>
                                        <option value="1">เครืองยนต์สันดาปภายใน</option>
                                        <option value="2">ไฮบริด/ปลั๊ก-อิน ไฮบริด</option>
                                        <option value="3">ไฟฟ้า</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-6 mt-2">
                                <label class="col-sm-6 col-form-label">กดตรวจสอบข้อมูล</label>
                                <div class="col-sm-6">
                                    <select class="form-control" v-model="data_3_10">
                                        <option value="">กรุณาเลือก</option>
                                        <option value="1">พบข้อมูล</option>
                                        <option value="2">ไม่พบข้อมูล</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-6 mt-2">
                                <label class="col-sm-6 col-form-label">ผลการตรวจ</label>
                                <div class="col-sm-6">
                                    <select class="form-control" v-model="data_3_11">
                                        <option value="">กรุณาเลือก</option>
                                        <option value="1">ผ่าน</option>
                                        <option value="2">ไม่ผ่าน</option>
                                    </select>
                                </div>
                              </div>
                              <div class="form-group row col-sm-6 mt-2">
                                <label class="col-sm-6 col-form-label">หมายเลขตัวรถ</label>
                                <div class="col-sm-6">
                                    <input class="form-control" type="text" v-model="data_3_12">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div class="col-md-12 text-center mt-3">
                            <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics2" type="button">ค้นหารายละเอียด</button>
                        </div>
                      </div>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="countdata.count_status == 1" class="container-fluid">

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-md-12 text-center">
                  <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                  <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
              </div>
            </div>
            <div class="card-body text-center">
              <div class="needs-validation">
                <div id="printableTable" class="dt-ext table-responsive">
                  <table ref="tbdata" class="display table-bordered nowrap" cellspacing="0" width="100%">
                    <thead>
                      <tr class="text-left">
                          <th colspan="44"><h4>พบรายละเอียดทั้งหมดจำนวน : {{ countdata.count_total?countdata.count_total:0 }} รายการ</h4></th>
                      </tr>
                      <tr>
                          <th colspan="21">รายละเอียดของผลการตรวจ</th>
                          <th colspan="9">รายละเอียดผลตรวจเบรค</th>
                          <th colspan="8">รายละเอียดค่าโคมไฟ</th>
                          <th colspan="6">รายละเอียดเพิ่มเติม</th>
                      </tr>
                      <tr >
                          <td>หมายเลขอ้างอิงผลตรวจ</td>
                          <td>วันที่ส่งผลการตรวจและทดสอบ</td>
                          <td>ชื่อสถานตรวจสภาพรถ</td>
                          <td>ทะเบียนรถ</td>
                          <td>ประเภท รย.</td>
                          <td>ยี่ห้อรถ</td>
                          <td>สี</td>
                          <td>ชนิดเชื้อเพลิง</td>
                          <td>น้ำหนักรถ</td>
                          <td>ผลการตรวจ(1 ผ่าน / 0 ไม่ผ่าน)</td>
                          <td>ค่า เสียง</td>
                          <td>ค่า CO(รย.12 หรือเกณฑ์เก่า)</td>
                          <td>ค่า HC(รย.12 หรือเกณฑ์เก่า)</td>
                          <td>ค่า CO(รอบต่ำ น้ำมัน)</td>
                          <td>ค่า HC(รอบต่ำ น้ำมัน)</td>
                          <td>ค่า CO(รอบต่ำ ก๊าซ)</td>
                          <td>ค่า HC(รอบต่ำ ก๊าซ)</td>
                          <td>ค่า CO(รอบสูง น้ำมัน)</td>
                          <td>ค่า HC(รอบสูง น้ำมัน)</td>
                          <td>ค่า CO(รอบสูง ก๊าซ)</td>
                          <td>ค่า HC(รอบสูง ก๊าซ)</td>
                          <td>ค่า ควันดำ</td>
                          <td>แรงห้ามล้อ เพลา 1 ซ้าย</td>
                          <td>แรงห้ามล้อ เพลา 1 ขวา</td>
                          <td>แรงห้ามล้อ เพลา 2 ซ้าย</td>
                          <td>แรงห้ามล้อ เพลา 2 ขวา</td>
                          <td>น้ำหนักลงเพลา 1</td>
                          <td>น้ำหนักลงเพลา 2</td>
                          <td>แรงเบรคมือซ้าย</td>
                          <td>แรงเบรคมือขวา</td>
                          <td>ศูนย์ล้อ</td>
                          <td>ค่าโคมไฟพุ่งไกล ด้านซ้าย</td>
                          <td>ค่าโคมไฟพุ่งไกล ด้านขวา</td>
                          <td>ค่าโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                          <td>ค่าโคมไฟพุ่งต่ำ ด้านขวา</td>
                          <td>ตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย</td>
                          <td>ตำแหน่งโคมไฟพุ่งไกล ด้านขวา</td>
                          <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                          <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา</td>
                          <td>หมายเลขช่างที่ตรวจ</td>
                          <td>เลขเครื่องยนต์</td>
                          <td>เลขตัวรถ</td>
                          <td>ลักษณะรถ</td>
                          <td>เพิ่มเติม</td>
                      </tr>
                    </thead>
                    <tbody v-for="data_totals in countdata.data_total" :key="data_totals.id">
                      <tr>
                        <td>{{ data_totals.ID }}</td>
                        <td>{{ new Date(data_totals.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ) }}</td>
                        <td>{{ data_totals.TRO_NAME }}</td>
                        <td>{{ data_totals.CAR_LIC_ID_CHAR }} - {{ data_totals.CAR_LIC_ID_NUM }} {{ data_totals.names }}</td>
                        <td>{{ data_totals.CAR_TYPE }}</td>
                        <td>{{ data_totals.RY_NUMBER_ID }}</td>
                        <td>{{ data_totals.CAR_COLOR }}</td>
                        <td>{{ data_totals.PRB_TYPE }}</td>
                        <td>{{ data_totals.CAR_WEIGHT }}</td>
                        <td>{{ data_totals.STATUS_CHECK }}</td>
                        <td>{{ data_totals.VOICE_VALUE }}</td>
                        <td>{{ data_totals.CO_VALUE }}</td>
                        <td>{{ data_totals.HC_VALUE }}</td>
                        <td>{{ data_totals.CO_VALUE_L1 }}</td>
                        <td>{{ data_totals.HC_VALUE_L1 }}</td>
                        <td>{{ data_totals.CO_VALUE_L2 }}</td>
                        <td>{{ data_totals.HC_VALUE_L2 }}</td>
                        <td>{{ data_totals.CO_VALUE_H1 }}</td>
                        <td>{{ data_totals.HC_VALUE_H1 }}</td>
                        <td>{{ data_totals.CO_VALUE_H2 }}</td>
                        <td>{{ data_totals.HC_VALUE_H2 }}</td>
                        <td>{{ data_totals.PIPE_VALUE }}</td>
                        <td>{{ data_totals.TESTBRAKE_FORCE_AXLE1L }}</td>
                        <td>{{ data_totals.TESTBRAKE_FORCE_AXLE1R }}</td>
                        <td>{{ data_totals.TESTBRAKE_FORCE_AXLE2L }}</td>
                        <td>{{ data_totals.TESTBRAKE_FORCE_AXLE2R }}</td>
                        <td>{{ data_totals.TESTBRAKE_WEIGHT_AXLE1 }}</td>
                        <td>{{ data_totals.TESTBRAKE_WEIGHT_AXLE2 }}</td>
                        <td>{{ data_totals.TESTBRAKE_HAND_VAULE_L }}</td>
                        <td>{{ data_totals.TESTBRAKE_HAND_VAULE_R }}</td>
                        <td>{{ data_totals.TESTBRAKE_WHEEL_ALIGHTMENT }}</td>
                        <td>{{ data_totals.LIGHT_DN_L_VALUE }}</td>
                        <td>{{ data_totals.LIGHT_DN_R_VALUE }}</td>
                        <td>{{ data_totals.LIGHT_DN_L_LEVEL }}</td>
                        <td>{{ data_totals.LIGHT_DN_R_LEVEL }}</td>
                        <td>{{ data_totals.LIGHT_UP_L_VALUE }}</td>
                        <td>{{ data_totals.LIGHT_UP_R_VALUE }}</td>
                        <td>{{ data_totals.LIGHT_UP_L_LEVEL }}</td>
                        <td>{{ data_totals.LIGHT_UP_R_LEVEL }}</td>

                        <td>{{ data_totals.USER1 + ' , ' + data_totals.USER2 }}</td>
                        <td>{{ data_totals.ENGINE_NO }}</td>
                        <td>{{ data_totals.CHAISSE_NO }}</td>
                        <td>{{ data_totals.CHASSIS_NO_TYPE }}</td>
                        <tb><div class="btn-group"> <a :href="'/user/datacardetail/' + data_totals.ID" target="_blank" class="btn btn-success" data-bs-original-title="" title=""><i class="fa fa-search"></i></a><a :href="'/user/datacarpdf/'+data_totals.ID" target="_blank" class="btn btn-info" data-bs-original-title="" title=""><i class="fa fa-print"></i></a></div></tb>
                    </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
    return{
            id:'',
            name:'',
            email:'',
            dataTable:null,
            download_type:'',
            searchcounty_by:'',
            date_start:'',
            date_to:'',
            license_id:'',
            // type_search 1 
            checkbox1:'',checkbox2:'',checkbox3:'',checkbox4:'',checkbox5:'',checkbox6:'',
            checkbox7:'',checkbox8:'',
            checkbox7_1:'',checkbox8_1:'',
            checkbox7_2:'',checkbox8_2:'',
            checkbox7_3:'',checkbox8_3:'',
            checkbox7_4:'',checkbox8_4:'',
            checkbox9:'',checkbox10:'',checkbox11:'',checkbox12:'',checkbox13:'',checkbox14:'',checkbox15:'',
            // type_search 2
            data_1_1:'1',data_1_2:'',data_1_3:'1',data_1_4:'',data_1_5:'1',data_1_6:'',data_1_7:'1',data_1_8:'',data_1_9:'1',data_1_10:'',data_1_11:'1',data_1_12:'',data_1_13:'1',data_1_14:'',data_1_15:'1',data_1_16:'',
            data_2_1:'1',data_2_2:'',data_2_3:'1',data_2_4:'',data_2_5:'1',data_2_6:'',data_2_7:'1',data_2_8:'',
            data_4_1:'1',data_4_2:'',data_4_3:'1',data_4_4:'',data_4_5:'1',data_4_6:'',data_4_7:'1',data_4_8:'',data_4_9:'1',data_4_10:'',data_4_11:'1',data_4_12:'',data_4_13:'1',data_4_14:'',data_4_15:'1',data_4_16:'',
            data_3_1:'1',data_3_2:'',data_3_3:'1',data_3_4:'',
            data_3_5:'1',data_3_6:'',data_3_7:'1',data_3_8:'',
            data_3_5_1:'1',data_3_6_1:'',data_3_7_1:'1',data_3_8_1:'',
            data_3_5_2:'1',data_3_6_2:'',data_3_7_2:'1',data_3_8_2:'',
            data_3_5_3:'1',data_3_6_3:'',data_3_7_3:'1',data_3_8_3:'',
            data_3_5_4:'1',data_3_6_4:'',data_3_7_4:'1',data_3_8_4:'',
            data_3_9:'',data_3_10:'',data_3_11:'',data_3_12:'',
            checkbox_1:'',checkbox_1_1:'',checkbox_1_2:'',checkbox_1_3:'',checkbox_1_4:'',
            checkbox_2:'',checkbox_2_1:'',checkbox_2_2:'',checkbox_2_3:'',checkbox_2_4:'',
            checkbox_3:'',checkbox_3_1:'',checkbox_3_2:'',
            checkbox_3_3:'',checkbox_3_4:'',
            checkbox_3_3_1:'',checkbox_3_4_1:'',
            checkbox_3_3_2:'',checkbox_3_4_2:'',
            checkbox_3_3_3:'',checkbox_3_4_3:'',
            checkbox_3_3_4:'',checkbox_3_4_4:'',
            checkbox_4:'',checkbox_4_1:'',checkbox_4_2:'',checkbox_4_3:'',checkbox_4_4:'',
            countdata:{
              count_status:'',
              count_total:0,
              data_total:[],
            },
            tro_name_id_value:'',
            searchcounty_area_value:'',
          }
        },
          methods:{
            getdata(){
              if(this.tro_name_id_value.length <= 0)
              this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
              });
              this.$swal.showLoading()
              axios.post(`getdatash`,{
                uid: storeAuth.id,
                gid: storeAuth.group_id
              }).then(res=>{
                  this.tro_name_id_value = res.data.datas.datag4;
                  this.searchcounty_area_value = res.data.datas.datactarea;
                  this.$swal.close();
                }).catch(err=>{
                  console.log(err)
                  this.$swal.close();
              })
            },
            searchcounty_area_select({names}) {
              return `${names}`
            },
            tro_name_id_select({license_number,nameaddresscar}) {
              return `${license_number} / ${nameaddresscar}`
            },
            statistics1(){
                    
                    if((!this.date_start || !this.date_to || (!this.searchcounty_by && !this.license_id)) || (!this.checkbox1 && !this.checkbox2 && !this.checkbox3 && !this.checkbox4 && !this.checkbox5 && !this.checkbox6 && !this.checkbox7 && !this.checkbox8 && !this.checkbox9 && !this.checkbox10 && !this.checkbox11 && !this.checkbox12 && !this.checkbox13 && !this.checkbox14)){
                        this.$swal({
                          title:'คำเตือน', 
                          text: 'กรุณาเลือกวันที่หรือชื่อ ตรอ หรือจังหวัดที่กำกับดูแล และข้อมูลอื่นๆที่ต้องกาค้นหาก่อนกดปุ่ม "ค้นหารายละเอียด"', 
                          icon:'warning', 
                        }) 
                      }else if((!this.checkbox15)){
                        this.$swal({
                          title:'คำเตือน', 
                          text: 'กรุณาเลือกประเภทรถก่อน', 
                          icon:'warning', 
                        }) 
                    }else{
                         let data = {
                                    type_search:1,
                                    date_start:this.date_start,
                                    date_to:this.date_to,
                                    searchcounty_by:this.searchcounty_by,
                                    license_id:this.license_id,
                                    checkbox15:this.checkbox15,
                                    checkbox1:this.checkbox1,checkbox2:this.checkbox2,checkbox3:this.checkbox3,checkbox4:this.checkbox4,checkbox5:this.checkbox5,checkbox6:this.checkbox6,
                                    checkbox7:this.checkbox7,checkbox8:this.checkbox8,
                                    checkbox7_1:this.checkbox7_1,checkbox8_1:this.checkbox8_1,
                                    checkbox7_2:this.checkbox7_2,checkbox8_2:this.checkbox8_2,
                                    checkbox7_3:this.checkbox7_3,checkbox8_3:this.checkbox8_3,
                                    checkbox7_4:this.checkbox7_4,checkbox8_4:this.checkbox8_4,
                                    checkbox9:this.checkbox9,checkbox10:this.checkbox10,checkbox11:this.checkbox11,checkbox12:this.checkbox12,checkbox13:this.checkbox13,checkbox14:this.checkbox14,
                                }
                        this.getcount(data);
                    }
                },
                statistics2(){
                    if(((!this.date_start || (!this.searchcounty_by && !this.license_id) || this.download_type != 2) && !this.data_3_12)){
                        this.$swal({ 
                          title:'คำเตือน', 
                          text: 'กรุณาเลือกวันที่หรือชื่อ ตรอ หรือจังหวัดที่กำกับดูแล และข้อมูลอื่นๆที่ต้องกาค้นหาก่อนกดปุ่ม "ค้นหารายละเอียด" ', 
                          icon:'warning', 
                        }) 
                    }else{
                         let data = {
                                    type_search:2,
                                    date_start:this.date_start,
                                    searchcounty_by:this.searchcounty_by,
                                    license_id:this.license_id,
                                    data_1_1:this.data_1_1,data_1_2:this.data_1_2,data_1_3:this.data_1_3,data_1_4:this.data_1_4,data_1_5:this.data_1_5,data_1_6:this.data_1_6,data_1_7:this.data_1_7,data_1_8:this.data_1_8,data_1_9:this.data_1_9,data_1_10:this.data_1_10,data_1_11:this.data_1_11,data_1_12:this.data_1_12,data_1_13:this.data_1_13,data_1_14:this.data_1_14,data_1_15:this.data_1_15,data_1_16:this.data_1_16,
                                    data_2_1:this.data_2_1,data_2_2:this.data_2_2,data_2_3:this.data_2_3,data_2_4:this.data_2_4,data_2_5:this.data_2_5,data_2_6:this.data_2_6,data_2_7:this.data_2_7,data_2_8:this.data_2_8,
                                    data_4_1:this.data_4_1,data_4_2:this.data_4_2,data_4_3:this.data_4_3,data_4_4:this.data_4_4,data_4_5:this.data_4_5,data_4_6:this.data_4_6,data_4_7:this.data_4_7,data_4_8:this.data_4_8,data_4_9:this.data_4_9,data_4_10:this.data_4_10,data_4_11:this.data_4_11,data_4_12:this.data_4_12,data_4_13:this.data_4_13,data_4_14:this.data_4_14,data_4_15:this.data_4_15,data_4_16:this.data_4_16,
                                    data_3_1:this.data_3_1,data_3_2:this.data_3_2,data_3_3:this.data_3_3,data_3_4:this.data_3_4,
                                    data_3_5:this.data_3_5,data_3_6:this.data_3_6,data_3_7:this.data_3_7,data_3_8:this.data_3_8,
                                    data_3_5_1:this.data_3_5_1,data_3_6_1:this.data_3_6_1,data_3_7_1:this.data_3_7_1,data_3_8_1:this.data_3_8_1,
                                    data_3_5_2:this.data_3_5_2,data_3_6_2:this.data_3_6_2,data_3_7_2:this.data_3_7_2,data_3_8_2:this.data_3_8_2,
                                    data_3_5_3:this.data_3_5_3,data_3_6_3:this.data_3_6_3,data_3_7_3:this.data_3_7_3,data_3_8_3:this.data_3_8_3,
                                    data_3_5_4:this.data_3_5_4,data_3_6_4:this.data_3_6_4,data_3_7_4:this.data_3_7_4,data_3_8_4:this.data_3_8_4,
                                    data_3_9:this.data_3_9,data_3_10:this.data_3_10,data_3_11:this.data_3_11,data_3_12:this.data_3_12,
                                }
                        this.getcount(data);
                    }
                },
                async getcount(data){
                  this.countdata.count_total = 0;
                  this.countdata.data_total = [];
                  this.countdata.count_status = 0;
                  // this.dataTable.clear().draw();
                  this.$swal({
                    title: 'ระบบกำลังทำงาน',
                    html: 'กรุณารอสักครู่ ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  this.$swal.showLoading()
                            
                  await axios.post(`download`,{
                      uid: storeAuth.id,
                      gid: storeAuth.group_id,
                      dt:12,
                      datas: data
                    }).then(rs=>{
                        this.$swal.close();
                        console.log(rs.data)
                        this.countdata.count_total = rs.data.datas.count_total;
                        this.countdata.data_total = rs.data.datas.data_total;
                        this.countdata.count_status = rs.data.status;
                     }).catch(err=>{
                        this.$swal.close();
                        console.log(err)
                    })
                },
                excelExport(type){
                  var data = this.$refs.tbdata;
                  var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
                  XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
                  XLSX.writeFile(excelFile, 'รายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์(แบบละเอียด).' + type);
                },
                async printDiv() {
                  await this.$htmlToPaper('printableTable');
                }
          },
          created(){
            this.getdata()
          }, 
          mounted(){}
}
</script>