<template>

  <div class="container-fluid">
      <Breadcrumbs main="รายละเอียดข้อมูลการตรวจสภาพรถตามกฏหมายรถขนส่ง"/>

      <div v-if="datas" class="row starter-main">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
                <button class="btn btn-pill btn-primary" @click="printDiv()" >ปริ้น (รายละเอียดการตรวจ)</button>

                <!--ปริ้น -->
                <div id="printableTable" style="display: none;">
                  <table style="width:100%; border: 1px solid black;border-collapse: collapse; font-family: Arial, sans-serif; font-size: 12px;">
                    <tr>
                      <th colspan="2">
                        รายละเอียดข้อมูลการตรวจสภาพรถตามกฏหมายรถขนส่ง<br/>
                        ทะเบียน {{ datas.CAR_LIC_ID_CHAR + ' ' + datas.CAR_LIC_ID_NUM + ' ' + datas.CAR_LIC_PROV }}                    
                      </th>
                      <th colspan="2">
                        <p>
                          <!-- <a href="../api/upload/{{ datas.PIC_CAR }}" target="_blank" class="previewImage">
                            <img src="../api/upload/{{ datas.PIC_CAR }}" width="200"  />
                          </a> -->
                        </p>                     
                      </th>
                    </tr>
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                      <td colspan="4" style="text-align: center;font-weight: bold;">รายละเอียดการตรวจ</td>
                    </tr>
                    <tr>
                      <td>ผลการตรวจ</td>
                      <td>{{ datas.STATUS_CHECK != 1?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ลำดับการตรวจ</td>
                      <td>{{ datas.ID }}</td>
                    </tr>
                    <tr>
                      <td>เวลาที่ตรวจ</td>
                      <td>{{ new Date(datas.SEND_DATETIME).toLocaleString('en-GB', { timeZone: 'UTC' }).replace( /,/,'' ) }}</td>
                      <td>เลขที่ใบอนุญาต</td>
                      <td>{{ datas.TRO_ID }}</td>
                    </tr>
                    <tr>
                      <td>ชื่อสถานตรวจสภาพรถ</td>
                      <td>{{ datas.TRO_NAME }}</td>
                      <td>ผู้ได้รับใบอนุญาต</td>
                      <td>{{ datas.TRO_OWNER_NAME }}</td>
                    </tr>
                    <tr>
                      <td>วันที่จดทะเบียน</td>
                      <td>{{ datas.CAR_REG_DATE }}</td>
                      <td>เลขตัวถังรถ</td>
                      <td>{{ datas.CHASSIS_NO }}</td>
                    </tr>
                    <tr>
                      <td>ชนิดโครงคัสซี</td>
                      <td>{{ datas.OPT_5_1 }}</td>
                      <td>ลักษณะรถ</td>
                      <td>{{ typecar(datas.CAR_TYPE) }}</td>
                    </tr>
                    <tr>
                      <td>ประเภทการขนส่ง</td>
                      <td>{{ typetruck(datas.PRB_TYPE) }}</td>
                      <td>เลขเครื่องยนต์</td>
                      <td>{{ datas.ENGINE_NO }}</td>
                    </tr>
                    <tr>
                      <td>เลขถังแก๊ส</td>
                      <td>{{ datas.GAS_NUMBER }}</td>
                      <td>PC IP</td>
                      <td>{{ datas.PC_IP }}</td>
                    </tr>
                    <tr>
                      <td>PC MAC</td>
                      <td>{{ datas.PC_MAC }}</td>
                    </tr>
                    <tr>
                      <td>ผู้ตรวจที่ 1</td>
                      <td>{{ datas.USER1 }}</td>
                      <td>ผู้ตรวจที่ 2</td>
                      <td>{{ datas.USER2 }}</td>
                    </tr>
                    <tr>
                      <td>1.ประสิทธิภาพห้ามล้อ</td>
                      <td>{{ datas.OPT_1 }}</td>
                      <td>เพลา 1 ซ้าย</td>
                      <td>{{ datas.OPT_1_1 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 1 ขวา</td>
                      <td>{{ datas.OPT_1_2 }}</td>
                      <td>เพลา 2 ซ้าย</td>
                      <td>{{ datas.OPT_1_3 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 2 ขวา</td>
                      <td>{{ datas.OPT_1_4 }}</td>
                      <td>เพลา 3 ซ้าย</td>
                      <td>{{ datas.OPT_1_5 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 3 ขวา</td>
                      <td>{{ datas.OPT_1_6 }}</td>
                      <td>เพลา 4 ซ้าย</td>
                      <td>{{ datas.OPT_1_7 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 4 ขวา</td>
                      <td>{{ datas.OPT_1_8 }}</td>
                      <td>เพลา 5 ซ้าย</td>
                      <td>{{ datas.OPT_1_9 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 5 ขวา</td>
                      <td>{{ datas.OPT_1_10 }}</td>
                      <td>แรงลงเพลา 1</td>
                      <td>{{ datas.OPT_1_11 }}</td>
                    </tr>
                    <tr>
                      <td>แรงลงเพลา 2</td>
                      <td>{{ datas.OPT_1_12 }}</td>
                      <td>แรงลงเพลา 3</td>
                      <td>{{ datas.OPT_1_13 }}</td>
                    </tr>
                    <tr>
                      <td>แรงลงเพลา 4</td>
                      <td>{{ datas.OPT_1_14 }}</td>
                      <td>แรงลงเพลา 5</td>
                      <td>{{ datas.OPT_1_15 }}</td>
                    </tr>
                    <tr>
                      <td>2.ประสิทธิภาพห้ามล้อมือ</td>
                      <td>{{ datas.OPT_2 }}</td>
                      <td>เพลา 1 ซ้าย</td>
                      <td>{{ datas.OPT_2_1 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 1 ขวา</td>
                      <td>{{ datas.OPT_2_2 }}</td>
                      <td>เพลา 2 ซ้าย</td>
                      <td>{{ datas.OPT_2_3 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 2 ขวา</td>
                      <td>{{ datas.OPT_2_4 }}</td>
                      <td>เพลา 3 ซ้าย</td>
                      <td>{{ datas.OPT_2_5 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 3 ขวา</td>
                      <td>{{ datas.OPT_2_6 }}</td>
                      <td>เพลา 4 ซ้าย</td>
                      <td>{{ datas.OPT_2_7 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 4 ขวา</td>
                      <td>{{ datas.OPT_2_8 }}</td>
                      <td>เพลา 5 ซ้าย</td>
                      <td>{{ datas.OPT_2_9 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา 5 ขวา</td>
                      <td>{{ datas.OPT_2_10 }}</td>
                    </tr>
                    <tr>
                      <td>3.เครื่องวัดความดันลมหรือสัญญาณเตือน</td>
                      <td>{{ datas.OPT_3 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>4.ห้ามล้อฉุกเฉิน</td>
                      <td>{{ datas.OPT_4 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>5.ชนิดเครื่องยนต์</td>
                      <td>{{ datas.OPT_5 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด ชนิดเครื่องยนต์</td>
                      <td>{{ datas.OPT_5_1 }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด แบบ</td>
                      <td>{{ datas.OPT_5_2 }}</td>
                      <td>6.ตำแหน่งเลขเครื่องยนต์</td>
                      <td>{{ datas.OPT_7 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด ตำแหน่งเลขเครื่องยนต์</td>
                      <td>{{ datas.OPT_7_1 }}</td>
                      <td>7.จำนวนสูบและซีซี</td>
                      <td>{{ datas.OPT_8 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด สูบ</td>
                      <td>{{ datas.OPT_8_1 }}</td>
                      <td>รายละเอียด ความจุ</td>
                      <td>{{ datas.OPT_8_2 }}</td>
                    </tr>


                    <tr>
                      <td>8.แรงม้า</td>
                      <td>{{ datas.OPT_9 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด กำลังม้า (B.H.P)</td>
                      <td>{{ datas.OPT_9_1 }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด ที่ รอบ/นาที</td>
                      <td>{{ datas.OPT_9_2 }}</td>
                    </tr>
                    <tr>
                      <td>9.ฝาครอบเลขเครื่องยนต์</td>
                      <td>{{ datas.OPT_10 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>10.ชนิดโครงคัสซี</td>
                      <td>{{ datas.OPT_11 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด ชนิดโครงคัสซี</td>
                      <td>{{ datas.OPT_11_1 }}</td>
                    </tr>
                    <tr>
                      <td>11.ตำแหน่งเลขตัวถังหรือโครงคัสซี</td>
                      <td>{{ datas.OPT_12 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด ตำแหน่งเลขตัวถังหรือโครงคัสซี</td>
                      <td>{{ datas.OPT_12_1 }}</td>
                    </tr>
                    <tr>
                      <td>12.ระบบไอเสีย</td>
                      <td>{{ datas.OPT_13 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด ควันดำ %</td>
                      <td>{{ datas.OPT_14_1 }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด ปริมาณก๊าซ CO (ระบบ NDIR)</td>
                      <td>{{ datas.OPT_14_2 }}</td>
                      <td>รายละเอียด ปริมาณก๊าซ HC % (ระบบ NDIR)</td>
                      <td>{{ datas.OPT_14_3 }}</td>
                    </tr>
                    <tr>
                      <td>13.ระดับเสียงรถ</td>
                      <td>{{ datas.OPT_15 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด ระดับเสียงรถ เดซิเบล เอ</td>
                      <td>{{ datas.OPT_15_1 }}</td>
                    </tr>
                    <tr>
                      <td>14.ระบบสตาร์ท</td>
                      <td>{{ datas.OPT_16 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>15.แตรสัญญาณ ระดับเสียง</td>
                      <td>{{ datas.OPT_17 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด แตรสัญญาณ ระดับเสียง (เดซิเบล เอ)</td>
                      <td>{{ datas.OPT_17_1 }}</td>
                    </tr>
                    <tr>
                      <td>16.แบตเตอรี่</td>
                      <td>{{ datas.OPT_18 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>17.สายไฟฟ้า</td>
                      <td>{{ datas.OPT_19 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>18.เครื่องปัดน้ำฝน</td>
                      <td>{{ datas.OPT_20 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>19.โคมไฟแสงพุ่งไกล</td>
                      <td>{{ datas.OPT_21 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>แสงพุ่งไกล ความสว่าง ซ้าย</td>
                      <td>{{ datas.OPT_21_1 }}</td>
                      <td>แสงพุ่งไกล ความสว่าง ขวา</td>
                      <td>{{ datas.OPT_21_2 }}</td>
                    </tr>
                    <tr>
                      <td>แสงพุ่งไกล ทิศทาง  ซ้าย</td>
                      <td>{{ datas.OPT_21_3 }}</td>
                      <td>แสงพุ่งไกล ทิศทาง  ขวา</td>
                      <td>{{ datas.OPT_21_4 }}</td>
                    </tr>
                    <tr>
                      <td>แสงพุ่งต่ำ ความสว่าง ซ้าย</td>
                      <td>{{ datas.OPT_22_1 }}</td>
                      <td>แสงพุ่งต่ำ ความสว่าง ขวา</td>
                      <td>{{ datas.OPT_22_2 }}</td>
                    </tr>
                    <tr>
                      <td>แสงพุ่งต่ำ ทิศทาง  ซ้าย</td>
                      <td>{{ datas.OPT_22_3 }}</td>
                      <td>แสงพุ่งต่ำ ทิศทาง  ขวา</td>
                      <td>{{ datas.OPT_22_4 }}</td>
                    </tr>
                    <tr>
                      <td>20.โคมไฟส่องป้ายแสดงเส้นทาง</td>
                      <td>{{ datas.OPT_23 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟส่องป้ายแสดงเส้นทาง จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_23_1 }}</td>
                    </tr>
                    <tr>
                      <td>21.โคมไฟแสดงส่วนสูง ส่วนกว้าง และประเภทรถ</td>
                      <td>{{ datas.OPT_24 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟแสดงส่วนสูง ส่วนกว้าง และประเภทรถ จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_24_1 }}</td>
                    </tr>
                    <tr>
                      <td>22.โคมไฟจอด</td>
                      <td>{{ datas.OPT_25 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟจอด จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_25_1 }}</td>
                    </tr>
                    <tr>
                      <td>23.โคมไฟท้าย</td>
                      <td>{{ datas.OPT_26 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟท้าย จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_26_1 }}</td>
                    </tr>
                    <tr>
                      <td>24.โคมไฟหยุด</td>
                      <td>{{ datas.OPT_27 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟหยุด จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_27_1 }}</td>
                    </tr>
                    <tr>
                      <td>25.โคมไฟส่องป้ายทะเบียน</td>
                      <td>{{ datas.OPT_28 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟส่องป้ายทะเบียน จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_28_1 }}</td>
                    </tr>
                    <tr>
                      <td>29.โคมไฟเลี้ยว</td>
                      <td>{{ datas.OPT_29 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟเลี้ยว จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_29_1 }}</td>
                    </tr>
                    <tr>
                      <td>30.วัตถุสะท้อนแสง</td>
                      <td>{{ datas.OPT_30 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด วัตถุสะท้อนแสง จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_30_1 }}</td>
                    </tr>
                    <tr>
                      <td>31.โคมไฟภายใน</td>
                      <td>{{ datas.OPT_31 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟภายใน จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_31_1 }}</td>
                    </tr>
                    <tr>
                      <td>ไฟบันได</td>
                      <td>{{ datas.OPT_31_2 }}</td>
                    </tr>
                    <tr>
                      <td>32.โคมไฟอื่นๆ</td>
                      <td>{{ datas.OPT_32 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด โคมไฟอื่น ๆ จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_32_1 }}</td>
                    </tr>
                    <tr>
                      <td>33.กริ่งสัญญาณหยุดรถ</td>
                      <td>{{ datas.OPT_33 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด กริ่งสัญญาณหยุดรถ จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_33_1 }}</td>
                    </tr>
                    <tr>
                      <td>34.เครื่องดับเพลิง</td>
                      <td>{{ datas.OPT_34 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด เครื่องดับเพลิง จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_34_1 }}</td>
                    </tr>
                    <tr>
                      <td>35.กระจกเงาสำหรับมองหลัง</td>
                      <td>{{ datas.OPT_35 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด กระจกเงาสำหรับมองหลัง จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_35_1 }}</td>
                    </tr>
                    <tr>
                      <td>36.กระจกกันลมหน้า หลัง และตัวถังส่วนที่เป็นกระจก</td>
                      <td>{{ datas.OPT_36 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>37.ศูนย์ล้อหน้า</td>
                      <td>{{ datas.OPT_37 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด วัตถุสะท้อนแสง จำนวน (ดวง)</td>
                      <td>{{ datas.OPT_37_1 }}</td>
                    </tr>
                    <tr>
                      <td>รายละเอียด ศูนย์ล้อหน้า m/km</td>
                      <td>{{ datas.OPT_37_2 }}</td>
                    </tr>
                    <tr>
                      <td>เพลา ล้อ และ ยาง</td>
                      <td>{{ datas.OPT_38 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>เพลา</td>
                      <td>{{ datas.OPT_38_1 }}</td>
                    </tr>
                    <tr>
                      <td>ยาง</td>
                      <td>{{ datas.OPT_38_3 }}</td>
                    </tr>
                    <tr>
                      <td>36.ยางและขนาดยาง</td>
                      <td>{{ datas.OPT_39 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>หน้า</td>
                      <td>{{ datas.OPT_39_1 }}</td>
                    </tr>
                    <tr>
                      <td>หลัง</td>
                      <td>{{ datas.OPT_39_2 }}</td>
                    </tr>
                    <tr>
                      <td>40.กันชน</td>
                      <td>{{ datas.OPT_40 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>41.แผ่นบังโคลน</td>
                      <td>{{ datas.OPT_41 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>42.อุปกรณ์ต่อพ่วง</td>
                      <td>{{ datas.OPT_42 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>43.เพลาล้อ</td>
                      <td>{{ datas.OPT_43 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>44.สปริงและเครื่องผ่อนคลายกันสะเทือน</td>
                      <td>{{ datas.OPT_44 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด สปริงและเครื่องผ่อนคลายกันสะเทือน (ชุด)</td>
                      <td>{{ datas.OPT_44_1 }}</td>
                    </tr>
                    <tr>
                      <td>45.เพลาส่งกำลัง ห่วงรองรับ และข้อต่อ</td>
                      <td>{{ datas.OPT_45 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>46.ถังเชื้อเพลิงและท่อส่ง</td>
                      <td>{{ datas.OPT_46 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>47.ระบบบังคับเลี้ยว</td>
                      <td>{{ datas.OPT_47 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>48.คลัทซ์ เฟือง เฟืองท้าย</td>
                      <td>{{ datas.OPT_48 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>49.เครื่องบันทึกความเร็ว ชนิด</td>
                      <td>{{ datas.OPT_49 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>ชนิด</td>
                      <td>{{ datas.OPT_49_1 }}</td>
                    </tr>
                    <tr>
                      <td>หมายเลข</td>
                      <td>{{ datas.OPT_49_2 }}</td>
                    </tr>
                    <tr>
                      <td>50.เครื่องวัดความเร็ว</td>
                      <td>{{ datas.OPT_50 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>ะยะทางวิ่ง(เลขไมล์) กม</td>
                      <td>{{ datas.OPT_50_1 }}</td>
                    </tr>
                    <tr>
                      <td>51.ตัวถังรถ</td>
                      <td>{{ datas.OPT_51 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>53.เครื่องหมาย</td>
                      <td>{{ datas.OPT_53 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>54.ประตูขึ้นลง</td>
                      <td>{{ datas.OPT_54 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>จำนวน</td>
                      <td>{{ datas.OPT_54_1 }}</td>
                    </tr>
                    <tr>
                      <td>ที่ตั้ง</td>
                      <td>{{ datas.OPT_54_2 }}</td>
                    </tr>
                    <tr>
                      <td>55.ประตูฉุกเฉิน</td>
                      <td>{{ datas.OPT_55 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>จำนวน</td>
                      <td>{{ datas.OPT_55_1 }}</td>
                    </tr>
                    <tr>
                      <td>ที่ตั้ง</td>
                      <td>{{ datas.OPT_55_2 }}</td>
                    </tr>
                    <tr>
                      <td>56.ราวยึดเหนี่ยว ราวประตู</td>
                      <td>{{ datas.OPT_56 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>57.อุปกรณ์ใช้เสียงและประชาสัมพันธ์</td>
                      <td>{{ datas.OPT_57 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>58.พื้นรถ</td>
                      <td>{{ datas.OPT_58 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>59.ที่นั่งผู้ขับรถ ที่กั้นห้องผู้ขับรถ</td>
                      <td>{{ datas.OPT_59 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>60.ที่นั่งผู้โดยสาร</td>
                      <td>{{ datas.OPT_60 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>61.หน้าต่าง</td>
                      <td>{{ datas.OPT_61 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>62.หลังคารถ</td>
                      <td>{{ datas.OPT_62 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>63.เครื่องปรับอากาศ</td>
                      <td>{{ datas.OPT_63 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>64.พัดลมดูดอากาศ</td>
                      <td>{{ datas.OPT_64 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>จำนวน</td>
                      <td>{{ datas.OPT_64_1 }}</td>
                    </tr>
                    <tr>
                      <td>65.ที่เตรียมอาหาร และเครื่องดื่ม</td>
                      <td>{{ datas.OPT_65 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>66.ห้องสุขภัณฑ์</td>
                      <td>{{ datas.OPT_66 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>67.ที่เก็บสัมภาระ</td>
                      <td>{{ datas.OPT_67 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>68.จำนวนผู้โดยสารนั่ง</td>
                      <td>{{ datas.OPT_68 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                    </tr>
                    <tr>
                      <td>นั่ง</td>
                      <td>{{ datas.OPT_68_1 }}</td>
                      <td>เลขตัวถังรถ</td>
                      <td>{{ datas.CHASSIS_NO }}</td>
                    </tr>
                    <tr>
                      <td>ยืน</td>
                      <td>{{ datas.OPT_68_2 }}</td>
                      <td>รวม</td>
                      <td>{{ datas.OPT_68_3 }}</td>
                    </tr>
                    <tr>
                      <td>69.น้ำหนักรถ</td>
                      <td>{{ datas.OPT_69 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>กก.</td>
                      <td>{{ datas.OPT_69_1 }}</td>
                    </tr>
                    <tr>
                      <td>79.น้ำหนักบรรทุก/น้ำหนังเพลา</td>
                      <td>{{ datas.OPT_70 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>กก.</td>
                      <td>{{ datas.OPT_70_1 }}</td>
                    </tr>
                    <tr>
                      <td>น้ำหนักรวม</td>
                      <td>{{ datas.OPT_70_2 }}</td>
                    </tr>
                    <tr>
                      <td>71.จำนวนถังบรรทุก</td>
                      <td>{{ datas.OPT_71 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>ถัง</td>
                      <td>{{ datas.OPT_71_1 }}</td>
                    </tr>
                    <tr>
                      <td>72.ปริมาตรถังบรรทุก(รวม)</td>
                      <td>{{ datas.OPT_72 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>ลูกบาศเมตร</td>
                      <td>{{ datas.OPT_72_1 }}</td>
                    </tr>
                    <tr>
                      <td>73.น้ำหนักลงเพลา</td>
                      <td>{{ datas.OPT_73 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>หน้า/ท้าย</td>
                      <td>{{ datas.OPT_73_1 }}</td>
                    </tr>
                    <tr>
                      <td>กก.</td>
                      <td>{{ datas.OPT_73_2 }}</td>
                    </tr>
                    <tr>
                      <td>74.ค่าความเข้มของฟิล์มกรองแสง</td>
                      <td>{{ datas.OPT_74 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>%</td>
                      <td>{{ datas.OPT_74_1 }}</td>
                    </tr>
                    <tr>
                      <td>75.อื่นๆ</td>
                      <td>{{ datas.OPT_75 != 1?"ไม่ผ่าน":"ผ่าน" }}</td>
                      <td>รายละเอียด</td>
                      <td>{{ datas.OPT_75_1 }}</td>
                    </tr>
                  </table>
                </div>


              </div>
              <div class="card-body" >
                <div class="row">
                  <div class="col">
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลำดับ</label>
                      <div class="col-sm-9">
                        <input class="form-control" :value="datas.ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เวลาที่ตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_DATETIME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เวลาที่ส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.SEND_DATETIME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขที่ใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_OWNER_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ได้รับใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_LIC_ID_CHAR+ ' ' +datas.CAR_LIC_ID_NUM + ' ' + datas.CAR_LIC_PROV" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">วันที่จดทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_REG_DATE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">PC IP</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_IP" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">PC MAC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_MAC" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลการตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.STATUS_CHECK != 1?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รูปภาพ</label>
                      <div class="col-sm-9">
                          <!-- <?php if($userExists[0]["PIC_CAR"]!=""){ ?>
                              <p><a href="../api/upload/truck/<?php echo htmlspecialchars($userExists[0]['PIC_CAR" target="_blank" class="previewImage">
                          <img src="../api/upload/truck/<?php echo htmlspecialchars($userExists[0]['PIC_CAR" border="0" width="200"  /></a></p>
                          <?php } else { ?>
                              <p><img src="../assets/images/no-image.png" border="0" width="50"  /></a></p>
                          <?php } ?> -->
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขตัวถังรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHASSIS_NO" style="text-transform: uppercase;" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชนิดโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_5_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลักษณะรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typecar(datas.CAR_TYPE)" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประเภทการขนส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typetruck(datas.PRB_TYPE)" type="text" readonly>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.ENGINE_NO" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขถังแก๊ส</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.GAS_NUMBER" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">1.ประสิทธิภาพห้ามล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_5" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_6" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_7" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_8" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_9" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_10" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_11" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_12" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 3</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_13" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 4</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_14" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 5</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_1_15" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">2.ประสิทธิภาพห้ามล้อมือ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_5" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_6" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_7" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_8" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_9" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_2_10" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">3.เครื่องวัดความดันลมหรือสัญญาณเตือน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_3 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">4.ห้ามล้อฉุกเฉิน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_4 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">5.ชนิดเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_5 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ชนิดเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_5_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด แบบ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_5_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">6.ตำแหน่งเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_7 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ตำแหน่งเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_7_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">7.จำนวนสูบและซีซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_8 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด สูบ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_8_1" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ความจุ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_8_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">8.แรงม้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_9 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด กำลังม้า (B.H.P)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_9_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ที่ รอบ/นาที</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_9_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">9.ฝาครอบเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_10 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">10.ชนิดโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_11 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ชนิดโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_11_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">11.ตำแหน่งเลขตัวถังหรือโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_12 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ตำแหน่งเลขตัวถังหรือโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_12_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">12.ระบบไอเสีย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_13 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ควันดำ %</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_14_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ปริมาณก๊าซ CO (ระบบ NDIR)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_14_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ปริมาณก๊าซ HC % (ระบบ NDIR)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_14_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">13.ระดับเสียงรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_15 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ระดับเสียงรถ เดซิเบล เอ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_15_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">14.ระบบสตาร์ท</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_16 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">15.แตรสัญญาณ ระดับเสียง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_17 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด แตรสัญญาณ ระดับเสียง (เดซิเบล เอ)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_17_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">16.แบตเตอรี่</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_18 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">17.สายไฟฟ้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_19 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">18.เครื่องปัดน้ำฝน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_20 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">19.โคมไฟแสงพุ่งไกล</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_21 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ความสว่าง ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_21_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ความสว่าง ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_21_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ทิศทาง  ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_21_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ทิศทาง  ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_21_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ความสว่าง ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_22_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ความสว่าง ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_22_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ทิศทาง  ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_22_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ทิศทาง  ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_22_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">20.โคมไฟส่องป้ายแสดงเส้นทาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_23 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟส่องป้ายแสดงเส้นทาง จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_23_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">21.โคมไฟแสดงส่วนสูง ส่วนกว้าง และประเภทรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_24 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟแสดงส่วนสูง ส่วนกว้าง และประเภทรถ จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_24_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">22.โคมไฟจอด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_25 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟจอด จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_25_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">23.โคมไฟท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_26 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟท้าย จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_26_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">24.โคมไฟหยุด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_27 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟหยุด จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_27_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">25.โคมไฟส่องป้ายทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_28 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟส่องป้ายทะเบียน จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_28_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">29.โคมไฟเลี้ยว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_29 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟเลี้ยว จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_29_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">30.วัตถุสะท้อนแสง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_30 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด วัตถุสะท้อนแสง จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_30_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">31.โคมไฟภายใน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_31 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟภายใน จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_31_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ไฟบันได</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_31_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">32.โคมไฟอื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_32 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด โคมไฟอื่น ๆ จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_32_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">33.กริ่งสัญญาณหยุดรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_33 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด กริ่งสัญญาณหยุดรถ จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_33_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">34.เครื่องดับเพลิง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_34 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด เครื่องดับเพลิง จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_34_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">35.กระจกเงาสำหรับมองหลัง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_35 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด กระจกเงาสำหรับมองหลัง จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_35_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">36.กระจกกันลมหน้า หลัง และตัวถังส่วนที่เป็นกระจก</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_36 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">37.ศูนย์ล้อหน้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_34 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด วัตถุสะท้อนแสง จำนวน (ดวง)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_37" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด ศูนย์ล้อหน้า m/km</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_37_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา ล้อ และ ยาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_38 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_38_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_38_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ยาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_38_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">36.ยางและขนาดยาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_39 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">หน้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_39_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">หลัง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_39_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">40.กันชน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_40 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">41.แผ่นบังโคลน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_41 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">42.อุปกรณ์ต่อพ่วง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_42 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">43.เพลาล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_43 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">44.สปริงและเครื่องผ่อนคลายกันสะเทือน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_44 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด สปริงและเครื่องผ่อนคลายกันสะเทือน (ชุด)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_44_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">45.เพลาส่งกำลัง ห่วงรองรับ และข้อต่อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_45 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">46.ถังเชื้อเพลิงและท่อส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_46 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">47.ระบบบังคับเลี้ยว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_47 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">48.คลัทซ์ เฟือง เฟืองท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_48 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">49.เครื่องบันทึกความเร็ว ชนิด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_49 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชนิด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_49_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">หมายเลข</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_49_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">50.เครื่องวัดความเร็ว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_50 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ระยะทางวิ่ง(เลขไมล์) กม</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_50_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">51.ตัวถังรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_51 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">53.เครื่องหมาย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_53 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">54.ประตูขึ้นลง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_54 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">จำนวน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_54_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่ตั้ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_54_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">55.ประตูฉุกเฉิน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_55 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">จำนวน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_55_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่ตั้ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_55_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">56.ราวยึดเหนี่ยว ราวประตู</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_56 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">57.อุปกรณ์ใช้เสียงและประชาสัมพันธ์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_57 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">58.พื้นรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_58 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">59.ที่นั่งผู้ขับรถ ที่กั้นห้องผู้ขับรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_59 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">60.ที่นั่งผู้โดยสาร</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_60 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผังที่นั่งแบบ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_60_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">61.หน้าต่าง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_61 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">62.หลังคารถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_62 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">63.เครื่องปรับอากาศ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_63 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">64.พัดลมดูดอากาศ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_64 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">จำนวน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_64_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">65.ที่เตรียมอาหาร และเครื่องดื่ม</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_65 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">66.ห้องสุขภัณฑ์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_66 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">67.ที่เก็บสัมภาระ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_67 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">68.จำนวนผู้โดยสารนั่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_68 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">นั่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_68_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ยืน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_68_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รวม</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_68_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">69.น้ำหนักรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_69 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">กก.</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_69_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">79.น้ำหนักบรรทุก/น้ำหนังเพลา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_70 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">กก.</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_70_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">น้ำหนักรวม</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_70_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">71.จำนวนถังบรรทุก</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_71 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ถัง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_71_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">72.ปริมาตรถังบรรทุก(รวม)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_72 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลูกบาศเมตร</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_72_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">73.น้ำหนักลงเพลา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_73 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">หน้า/ท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_73_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">กก.</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_73_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">74.ค่าความเข้มของฟิล์มกรองแสง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_74 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">%</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_74_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">75.อื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_75 != 1?'ไม่ผ่าน' : 'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รายละเอียด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.OPT_75_1" type="text" readonly>
                      </div>
                    </div>
                
                    
                  </div>
                </div>
              </div>
              <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
              <div class="card-footer text-center">
                <a href="javascript:window.open('','_self').close();" class="btn btn-pill btn-danger">ปิดหน้านี้</a>
              </div>
            </div>
          </div>
        </div>
      </div>


  </template>
  
  <script setup> 
  import { ref,onMounted,inject } from 'vue';
  import { useRoute } from 'vue-router'
  import { storeAuth } from "../../store/modules/auth";
  import axios from "../../axios";

  const route = useRoute()
  let datas = ref();
  const Swal = inject('$swal')

  onMounted( async () => {
    await getdata_table();
  })
  async function getdata_table(){
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`datatruckv5detail`,{
      rid : route.params.id,
      uid : storeAuth.id,
      gid : storeAuth.group_id
    });
    if(res.data.status == 1){
      datas.value = res.data.datas[0];
      Swal.close();
    }else{
      Swal.close();
    }
  }

function typecar(id) {
  if(id == 1){
        return 'รถโดยสารมาตรฐาน '+datas.value.CAR_TYPE_OPTION_1_1?datas.value.CAR_TYPE_OPTION_1_1:''+datas.value.CAR_TYPE_OPTION_1_2?datas.value.CAR_TYPE_OPTION_1_2:'';
    }else if(id == 2){
        return 'รถบรรทุก ลักษณะ '+datas.value.CAR_TYPE_OPTION_2?datas.value.CAR_TYPE_OPTION_2:'';
    }
  
}

function typetruck(id) {
  if(id == 1){ 
    return "ประจำทาง";
  }else if(id == '2'){
    return "ไม่ประจำทาง";
  }else{
    return"ส่วนบุคคล";
  }
}

//  PRINT
function printDiv() {
  window.frames["print_frame"].document.body.innerHTML = document.getElementById("printableTable").innerHTML;
  window.frames["print_frame"].window.focus();
  window.frames["print_frame"].window.print();
}
  </script>
  