
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ดาวน์โหลดรายละเอียดสถานตรวจสภาพรถ(วันและเวลาทำการ)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="alert alert-info">
                  <strong>คำแนะนำ!</strong> หากไม่เลือกจังหวัดระบบจะแสดงข้อมูลของ ตรอ. ทั้งหมด แต่หากเลือกจังหวัดจะแสดงข้อมูลของ ตรอ. ในจังหวัดนั้นๆ
                </div>
                <form class="needs-validation" novalidate="" action="" method="POST">
                  <div class="row">
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-5 col-form-label text-end" >จังหวัดที่กำกับดูแล</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="searchcounty" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mt-2">
                        <label class="col-sm-5 col-form-label text-end" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                        <div class="col-sm-5">
                          <multiselect :options="tro_name_id_value" :searchable="true" v-model="tro_name_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mt-2">
                        <label class="col-sm-5 col-form-label text-end" >วันทำการ</label>
                        <div class="col-sm-5">
                            <select v-model="searchday" class="form-control">
                                <option  value="">เลือกวันทำการ</option>
                                <option value="1">วันจันทร์</option>
                                <option value="2">วันอังคาร</option>
                                <option value="3">วันพุธ</option>
                                <option value="4">วันพฤหัสบดี</option>
                                <option value="5">วันศุกร์</option>
                                <option value="6">วันเสาร์</option>
                                <option value="7">วันอาทิตย์</option>
                            </select> 
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ค้นหารายละเอียด</button>
                    </div>
                  </div>
                  <br>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                    <div id="printableTable" class="col-sm-12 text-center table-responsive">
                      <table class="table" id="tblData">
                          <thead>
                          <tr>
                              <th colspan="26"><h4>วันและเวลาทำการ {{ totaldata.countydata }}</h4></th>
                          </tr>
                          <tr>
                              <th>ลำดับอ้างอิง</th>
                              <th>เลขที่ใบอนุญาต</th>
                              <th>ชื่อ ตรอ.</th>
                              <th>ละติจูด</th>
                              <th>ลองจิจูด</th>
                              <th>สถานะการทำงานวันจันทร์</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันอังคาร</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันพุธ</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันพฤหัสบดี</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันศุกร์</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันเสาร์</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                              <th>สถานะการทำงานวันอาทิตย์</th>
                              <th>เปิดเวลา</th>
                              <th>ปิดเวลา</th>
                          </tr>
                          </thead>
                          <tbody v-for="data_totals in totaldata.totaldata" :key="data_totals.id">
                          <tr>
                              <td>{{ data_totals.id }}</td>
                              <td>{{ data_totals.license_number }}</td>
                              <td>{{ data_totals.nameaddresscar }}</td>
                              <td>{{ data_totals.lat }}</td>
                              <td>{{ data_totals.lon }}</td>
                              <td v-if="data_totals.working_d1">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d1">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d1">{{ data_totals.working_d1_in }}</td>
                              <td v-if="data_totals.working_d1">{{ data_totals.working_d1_out }}</td>
                              <td v-if="!data_totals.working_d1">-</td>
                              <td v-if="!data_totals.working_d1">-</td>
                              <td v-if="data_totals.working_d2">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d2">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d2">{{ data_totals.working_d2_in }}</td>
                              <td v-if="data_totals.working_d2">{{ data_totals.working_d2_out }}</td>
                              <td v-if="!data_totals.working_d2">-</td>
                              <td v-if="!data_totals.working_d2">-</td>
                              <td v-if="data_totals.working_d3">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d3">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d3">{{ data_totals.working_d3_in }}</td>
                              <td v-if="data_totals.working_d3">{{ data_totals.working_d3_out }}</td>
                              <td v-if="!data_totals.working_d3">-</td>
                              <td v-if="!data_totals.working_d3">-</td>
                              <td v-if="data_totals.working_d4">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d4">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d4">{{ data_totals.working_d4_in }}</td>
                              <td v-if="data_totals.working_d4">{{ data_totals.working_d4_out }}</td>
                              <td v-if="!data_totals.working_d4">-</td>
                              <td v-if="!data_totals.working_d4">-</td>
                              <td v-if="data_totals.working_d5">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d5">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d5">{{ data_totals.working_d5_in }}</td>
                              <td v-if="data_totals.working_d5">{{ data_totals.working_d5_out }}</td>
                              <td v-if="!data_totals.working_d5">-</td>
                              <td v-if="!data_totals.working_d5">-</td>
                              <td v-if="data_totals.working_d6">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d6">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d6">{{ data_totals.working_d6_in }}</td>
                              <td v-if="data_totals.working_d6">{{ data_totals.working_d6_out }}</td>
                              <td v-if="!data_totals.working_d6">-</td>
                              <td v-if="!data_totals.working_d6">-</td>
                              <td v-if="data_totals.working_d7">เปิดทำการ</td>
                              <td v-if="!data_totals.working_d7">ไม่เปิดทำการ</td>
                              <td v-if="data_totals.working_d7">{{ data_totals.working_d7_in }}</td>
                              <td v-if="data_totals.working_d7">{{ data_totals.working_d7_out }}</td>
                              <td v-if="!data_totals.working_d7">-</td>
                              <td v-if="!data_totals.working_d7">-</td>
                          </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          searchcounty:'',
          date_start:'',
          searchday:'',
          tro_name_id:'',
          totaldata:{},
          tro_name_id_value:[],
          searchcounty_area_value:'',
          count_status: 0,
        }
      }, 
  methods:{
      getdata(){
        if(this.tro_name_id_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id
        }).then(res=>{
            this.tro_name_id_value = res.data.datas.datag4;
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) {
        return `${names}`
      },
      tro_name_id_select({license_number,nameaddresscar}) {
        return `${license_number} / ${nameaddresscar}`
      },
      statistics(){
        let data = {
                    date_start:this.date_start,
                    searchcounty:this.searchcounty,
                    searchday:this.searchday,
                    tro_name_id:this.tro_name_id,
                  }
        this.getcount(data);
          
      },
      async getcount(data){
        this.count_status = 0;
        this.totaldata = {}
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
          
          await axios.post(`download`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            dt: 14,
            datas: data
          }).then(rs=>{
              this.$swal.close();
              this.count_status = rs.data.status
              this.totaldata = rs.data.datas;
            }).catch(err=>{
              this.$swal.close();
          })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'รายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์(แบบละเอียด).' + type);
      },
      async printDiv() {
        await this.$htmlToPaper('printableTable');
      }
  }, 
  computed:{}, 
  watch: {}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>