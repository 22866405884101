
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการตรวจสภาพ(แสดงข้อมูลแยกเป็นจังหวัด)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ตั้งแต่วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">ถึง</label> 
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ค้นหารายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body">
                <div class="needs-validation">
                  <div class="row">
                        <div id="printableTable" class="text-center table-responsive">
                                <!-- <div :key="index" v-for="(item,index) in countdata">
                                    {{ item }}
                                </div> -->
                          <table class="table table-bordered" ref="tbdata">
                              <tr>
                                  <th colspan="39"><h4>สถิติตรวจสภาพรถ ทั้งหมด (แสดงข้อมูลแยกเป็นจังหวัด)</h4></th>
                              </tr>
                              <tr>
                                  <th colspan="39">ตั้งแต่วันที่ {{new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}} ถึงวันที่ {{new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}}</th>
                              </tr>
                              <tr>
                                      <td rowspan="3">ชื่อจังหวัด/เขตพื้นที่รับผิดชอบ</td>
                                      <td colspan="4">ประเภท รย.1 (คัน)</td>
                                      <td colspan="4">ประเภท รย.2 (คัน)</td>
                                      <td colspan="4">ประเภท รย.3 (คัน)</td>
                                      <td colspan="4">ประเภท รย.12 (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านผลต่าง(%) (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านประสิทธิภาพห้ามล้อ (%) (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านประสิทธิภาพห้ามล้อมือ (%) (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านศูนย์ล้อ (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านระดับเสียง (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านระบบไอเสีย "ควันดำ" (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านระบบไอเสีย "CO,HC" (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านแตรสัญญาณ (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านมาตรวัดความเร็ว (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านโคมไฟ (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านโคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านโคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านเครื่องปัดน้ำฝน (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านกระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านระบบบังคับเลี้ยวและพวงมาลัย (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านล้อและยาง (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านถังเชื้อเพลิงและท่อส่ง (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านเครื่องล่าง (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านโครงสร้างและตัวถัง (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านประตูและพื้นรถ (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านเข็มขัดนิรภัย (คัน)</td>
                                      <td rowspan="3">ไม่ผ่านอื่นๆ (คัน)</td>
                                  </tr>
                                <tr>
                                      <td>ผ่าน</td>
                                      <td colspan="3">ไม่ผ่าน</td>
                              
                                      <td>ผ่าน</td>
                                      <td colspan="3">ไม่ผ่าน</td>
            
                                      <td>ผ่าน</td>
                                      <td colspan="3">ไม่ผ่าน</td>
                      
                                      <td>ผ่าน</td>
                                      <td colspan="3">ไม่ผ่าน</td>
                              
                                  </tr>
                                  <tr>
                                      <td>1 ครั้ง</td>
                                      <td>2 ครั้ง</td>
                                      <td>มากกว่า 2 ครั้ง</td>
                                      <td>1 ครั้ง</td>
                                      <td>2 ครั้ง</td>
                                      <td>มากกว่า 2 ครั้ง</td>
                                      <td>1 ครั้ง</td>
                                      <td>2 ครั้ง</td>
                                      <td>มากกว่า 2 ครั้ง</td>
                                      <td>1 ครั้ง</td>
                                      <td>2 ครั้ง</td>
                                      <td>มากกว่า 2 ครั้ง</td>
                                  </tr>
                                  <!-- start v-for -->
                                  <tr :key="index" v-for="(item,index) in countdata.datas">
                                      <!-- <td>{{ item }}</td> -->
                                      <td class="align-middle"> {{ item.province_name }}</td>
                                      <!-- รย.1 -->
                                      <td>{{ item.t1_pass }}</td>
                                      <td>{{ item.t1_nopass1 }}</td>
                                      <td>{{ item.t1_nopass2 }}</td>
                                      <td>{{ item.t1_nopassmore }}</td>
                                      <!-- รย.2 -->
                                      <td>{{ item.t2_pass }}</td>
                                      <td>{{ item.t2_nopass1 }}</td>
                                      <td>{{ item.t2_nopass2 }}</td>
                                      <td>{{ item.t2_nopassmore }}</td>
                                      <!-- รย.3 -->
                                      <td>{{ item.t3_pass }}</td>
                                      <td>{{ item.t3_nopass1 }}</td>
                                      <td>{{ item.t3_nopass2 }}</td>
                                      <td>{{ item.t3_nopassmore }}</td>
                                      <!-- รย.12 -->
                                      <td>{{ item.t12_pass }}</td>
                                      <td>{{ item.t12_nopass1 }}</td>
                                      <td>{{ item.t12_nopass2 }}</td>
                                      <td>{{ item.t12_nopassmore }}</td>
                                      <!-- ข้อที่ไม่ผ่าน  --> 
                                      <td>{{ item.error1 }}</td>
                                      <td>{{ item.error2 }}</td>
                                      <td>{{ item.error3 }}</td>
                                      <td>{{ item.error4 }}</td>
                                      <td>{{ item.error5 }}</td>
                                      <td>{{ item.error61 }}</td>
                                      <td>{{ item.error62 }}</td>
                                      <td>{{ item.error7 }}</td>
                                      <td>{{ item.error8 }}</td>
                                      <td>{{ item.error9 }}</td>
                                      <td>{{ item.error10 }}</td>
                                      <td>{{ item.error11 }}</td>
                                      <td>{{ item.error20 }}</td>
                                      <td>{{ item.error12 }}</td>
                                      <td>{{ item.error13 }}</td>
                                      <td>{{ item.error14 }}</td>
                                      <td>{{ item.error15 }}</td>
                                      <td>{{ item.error16 }}</td>
                                      <td>{{ item.error17 }}</td>
                                      <td>{{ item.error18 }}</td>
                                      <td>{{ item.error19 }}</td>
                                      <!-- <td>{{ item.error20 }}</td> -->
                                      <td>{{ item.error21 }}</td>
                                  </tr>
                                  
                          </table>
                      </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
        return{
            date_start:'',
            date_end:'',
            countdata:[],
            }
        }, 
    methods:{
        statistics(){
            if(!this.date_start || !this.date_end){
              this.$swal({
                title:'คำเตือน', 
                text:'กรุณาเลือกช่วงวันที่ต้องการดูข้อมูลก่อน "ดูรายงาน"',
                icon:'warning', 
              }) 
            }else{
                  let data = {
                          uid: storeAuth.id,
                          gid: storeAuth.group_id,
                          dt:115,
                          datas:[this.date_start,this.date_end]
                        }
                this.getcount(data);
            }
        },
        getcount(data){
          this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.$swal.showLoading()
            
          axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
                if(rs.data.datas){
                  this.$swal.close();
                  this.countdata = rs.data.datas;
                }else{
                  this.$swal({
                    title:'ไม่พบข้อมูล', 
                    text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                    icon:'info', 
                  }) 
                }
              }else{
                this.$swal({
                  title:'เกิดข้อผิดพลาด', 
                  text:'ไม่สามารถค้นหาข้อมูลได้',
                  icon:'error', 
                }) 
              }
            }).catch(err=>{
              this.$swal.close();
              console.log(err)
          })
        },
        excelExport(type){
          var data = this.$refs.tbdata;
          var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
          XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
          XLSX.writeFile(excelFile, 'สถิติการตรวจสภาพ(แสดงข้อมูลแยกเป็นจังหวัด).' + type);
        },
        async printDiv() {
          await this.$htmlToPaper('printableTable');
        }
    }, 
    computed:{}, 
    watch:{}, 
    components:{}, 
    beforeCreate(){}, 
    created(){}, 
    beforeMount(){}, 
    mounted(){}, 
    beforeUpdate(){}, 
    updated(){}, 
    beforeDestroy(){}, 
    destroyed(){}, 
}

</script>