
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลเครื่องตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    สามารถเพิ่ม,แก้ไข,ลบข้อมูล ได้ในหน้านี้
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <button @click="getadd()" class="btn btn-primary"><i class="fa fa-search"></i> เพิ่มข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model data log -->
              <div class="modal fade" id="model-add" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">เพิ่มข้อมูล</b>
                            <button type="button" @click="closeModaladd()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tro_name_id_value" :searchable="true" v-model="dataadd[0]" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ประเภทของเครื่องตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tools_type_id_value" :searchable="true" v-model="dataadd[1]" :custom-label="tools_type_id_select" placeholder="เลือกประเภทของเครื่องตรวจสภาพรถ" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">หมายเลขประจำเครื่อง</label>
                                <div class="col-sm-8">
                                  <input class="form-control" type="text" v-model='dataadd[2]'>
                                </div>
                              </div>
                              <div class="mb-2 row">
                              <label class="col-sm-4 col-form-label">สถานะ</label>
                                <div class="col-sm-8">
                                    <select class="form-select" v-model="dataadd[3]">
                                        <option value="">เลือกสถานะ</option>
                                        <option value="1">ใช้งานอยู่</option>
                                        <option value="2">ชำรุด</option>
                                        <option value="3">เลิกใช้งาน</option>
                                    </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ข้อมูลหรือเอกสารอื่น ๆ  PDF <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                                <div class="col-sm-8">
                                  <input type="file" accept="application/pdf" @change="B64Pdf" class="form-control">
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">รูปถ่ายเครื่องตรวจสภาพ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                <div class="col-sm-8">
                                  <input type="file" accept="image/*" @change="B64Img" class="form-control">
                                </div>
                              </div>

                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="adddata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaladd()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model data log -->
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">แก้ไขข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tro_name_id_value" :searchable="true" v-model="dataedit[0]" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ประเภทของเครื่องตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <multiselect :options="tools_type_id_value" :searchable="true" v-model="dataedit[1]" :custom-label="tools_type_id_select" placeholder="เลือกประเภทของเครื่องตรวจสภาพรถ" />
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">หมายเลขประจำเครื่อง</label>
                                <div class="col-sm-8">
                                  <input class="form-control" type="text" v-model='dataedit[2]'>
                                </div>
                              </div>
                              <div class="mb-2 row">
                              <label class="col-sm-4 col-form-label">สถานะ</label>
                                <div class="col-sm-8">
                                    <select class="form-select" v-model="dataedit[3]">
                                        <option value="1">ใช้งานอยู่</option>
                                        <option value="2">ชำรุด</option>
                                        <option value="3">เลิกใช้งาน</option>
                                    </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ข้อมูลหรือเอกสารอื่น ๆ  PDF <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                                <div class="col-sm-8">
                                  <input type="file" accept="application/pdf" @change="B64Pdf" class="form-control">
                                  <a v-if="dataedit[4]" class="mt-1" :href="storeAuth.UrlFileTools+dataedit[4]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">รูปถ่ายเครื่องตรวจสภาพ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                <div class="col-sm-8">
                                  <input type="file" accept="image/*" @change="B64Img" class="form-control">
                                  <a v-if="dataedit[5]" class="mt-1" :href="storeAuth.UrlImagesTools+dataedit[5]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                </div>
                              </div>

                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                  <th>หมายเลขอ้างอิง</th>
                                  <th>เลขที่ใบอนุญาต - ชื่อสถานตรวจสภาพรถ</th>
                                  <th>จังหวัด/เขตพื้นที่ สถานตรวจสภาพรถ</th>
                                  <th>ประเภท - ชนิด แบบ/รุ่น ของเครื่องตรวจสภาพรถ</th>
                                  <th>หมายเลข</th>
                                  <th>บริษัทผู้ผลิตเครื่อง</th>
                                  <th class="none">รูปถ่าย</th>
                                  <th class="none">ไฟล์ PFD ที่แนบมา</th>
                                  <th>สถานะ</th>
                                  <th>จัดการ</th>
                                </tr>
                            </thead>
                            <template #column-9="props">
                              <div class="btn-group">
                                <button @click="geteditdata(props.cellData,props.rowData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_add: null,
    modal_edit: null,
})
function openModaladd(){ state.modal_add.show()}
function closeModaladd(){state.modal_add.hide()}
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const dataadd = ref(['','','','']);
const datapdf = ref();
const dataimg = ref();
const dataedit = ref([]);
const dataeditid = ref('');

const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}
const tools_type_id_value = ref([]);
function tools_type_id_select({type_tools,type,model,approved}) {
  let type_tools_detail = '';
  if(type_tools == 1){
    type_tools_detail = 'เครื่องทดสอบห้ามล้อ';
  }else if(type_tools == 2){
    type_tools_detail = 'เครื่องทดสอบศูนย์ล้อ';
  }else if(type_tools == 3){
    type_tools_detail = 'เครื่องทดสอบโคมไฟหน้า';
  }else if(type_tools == 4){
    type_tools_detail = 'เครื่องวัดควันดำ-ระบบวัดความทึบแสง';
  }else if(type_tools == 5){
    type_tools_detail = 'เครื่องวัดควันดำ-ระบบกระดาษกรอง';
  }else if(type_tools == 6){
    type_tools_detail = 'เครื่องกำเนิดเสียงมาตรฐาน';
  }else if(type_tools == 7){
    type_tools_detail = 'เครื่องวัดความเร็วรอบเครื่องยนต์';
  }else if(type_tools == 8){
    type_tools_detail = 'เครื่องวิเคราะห์ก๊าซ';
  }else if(type_tools == 9){
    type_tools_detail = 'เครื่องวัดก๊าซรั่ว';
  }else if(type_tools == 10){
    type_tools_detail = 'เครื่องยกรถ';
  }else if(type_tools == 11){
    type_tools_detail = 'เครื่องวัดระดับเสียง';
  }else if(type_tools == 12){
    type_tools_detail = 'เครื่องวัดความเข้มของฟิล์มกรองแสง';
  }
  return `${type_tools_detail} ชนิด ${type} แบบ/รุ่น ${model} บริษัทที่ได้รับความเห็นชอบ ${approved}`
}

onMounted(() => {
  getdata_table();
  state.modal_add = new Modal('#model-add', {});
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`tools`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    tools_type_id_value.value = res.data.datasToolsType;
    for (let i = 0; i < res.data.datas.length; i++) {
      let type_tools = '';
      if(res.data.datas[i].type_tools == 1){
        type_tools = 'เครื่องทดสอบห้ามล้อ';
      }else if(res.data.datas[i].type_tools == 2){
        type_tools = 'เครื่องทดสอบศูนย์ล้อ';
      }else if(res.data.datas[i].type_tools == 3){
        type_tools = 'เครื่องทดสอบโคมไฟหน้า';
      }else if(res.data.datas[i].type_tools == 4){
        type_tools = 'เครื่องวัดควันดำ-ระบบวัดความทึบแสง';
      }else if(res.data.datas[i].type_tools == 5){
        type_tools = 'เครื่องวัดควันดำ-ระบบกระดาษกรอง';
      }else if(res.data.datas[i].type_tools == 6){
        type_tools = 'เครื่องกำเนิดเสียงมาตรฐาน';
      }else if(res.data.datas[i].type_tools == 7){
        type_tools = 'เครื่องวัดความเร็วรอบเครื่องยนต์';
      }else if(res.data.datas[i].type_tools == 8){
        type_tools = 'เครื่องวิเคราะห์ก๊าซ';
      }else if(res.data.datas[i].type_tools == 9){
        type_tools = 'เครื่องวัดก๊าซรั่ว';
      }else if(res.data.datas[i].type_tools == 10){
        type_tools = 'เครื่องยกรถ';
      }else if(res.data.datas[i].type_tools == 11){
        type_tools = 'เครื่องวัดระดับเสียง';
      }else if(res.data.datas[i].type_tools == 12){
        type_tools = 'เครื่องวัดความเข้มของฟิล์มกรองแสง';
      }
      
      let status = '';
      if(res.data.datas[i].status == 1){
        status = 'ใช้งานอยู่';
      }else if(res.data.datas[i].status == 2){
        status = 'ชำรุด';
      }else{
        status = 'เลิกใช้งาน';
      }

      datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number + ' - ' + res.data.datas[i].nameaddresscar,res.data.datas[i].county_name,type_tools + ' - ชนิด ' + res.data.datas[i].type + ' แบบ/รุ่น ' + res.data.datas[i].model,res.data.datas[i].number,res.data.datas[i].approved,
      res.data.datas[i].img_tools?`<a href="${storeAuth.UrlImagesTools}${res.data.datas[i].img_tools}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      res.data.datas[i].file_tools?`<a href="${storeAuth.UrlFileTools}${res.data.datas[i].file_tools}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      status,res.data.datas[i].id]);
    }
    Swal.close()
  }else{
    Swal.close()
  }
}

async function getadd(){
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    if(tro_name_id_value.value.length < 1){
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id
      });
      if(res.data.status == 1){
        tro_name_id_value.value = res.data.datas.datag4;
        Swal.close()
        openModaladd()
      }
    }else{
      Swal.close()
      openModaladd()
    }
}

function  B64Pdf(event) {
  let input = event.target;
  if (input.files && input.files[0]) {
    if((input.files[0].size/1024/1024).toFixed(2) <= 10){
      let reader = new FileReader();
        reader.onload = (e) => {
        datapdf.value = e.target.result;
        // console.log(e.target.result)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

function  B64Img(event) {
  let input = event.target;
  if (input.files && input.files[0]) {
    if((input.files[0].size/1024/1024).toFixed(2) <= 2){
      let reader = new FileReader();
      reader.onload = (e) => {
        dataimg.value = e.target.result;
        // console.log(e.target.result)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

async function adddata() {
  if(!dataadd.value[0] || !dataadd.value[1] || !dataadd.value[2] || !dataadd.value[3]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const res = await axios.post(`tools`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 3,
      datas: [dataadd.value[0],dataadd.value[1],dataadd.value[2],dataadd.value[3],datapdf.value,dataimg.value],
    });
    if(res.data.status == 1){
      dataadd.value = ['','','','','']
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaladd()
      Swal.fire({
        title: "สำเร็จ",
        text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เพิ่มข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

async function geteditdata(id) {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`tools`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 4,
      datas:[id]
    });
    if(res.data.status == 1){
      if(tro_name_id_value.value.length < 1){
        const ressh = await axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id,
        });
        if(ressh.data.status == 1){
          tro_name_id_value.value = ressh.data.datas.datag4;
          dataedit.value = [];
          dataeditid.value = id
          dataedit.value[0] = {license_number:res.data.datas[0].license_number,nameaddresscar:res.data.datas[0].nameaddresscar,id:res.data.datas[0].license_id}
          dataedit.value[1] = {type_tools:res.data.datas[0].type_tools,type:res.data.datas[0].type,model:res.data.datas[0].model,approved:res.data.datas[0].approved,id:res.data.datas[0].type_car}
          dataedit.value[2] = res.data.datas[0].number
          dataedit.value[3] = res.data.datas[0].status,
          dataedit.value[4] = res.data.datas[0].file_tools
          dataedit.value[5] = res.data.datas[0].img_tools
          Swal.close();
          openModaledit()
        }
      }else{
        dataedit.value = [];
        dataeditid.value = id
        dataedit.value[0] = {license_number:res.data.datas[0].license_number,nameaddresscar:res.data.datas[0].nameaddresscar,id:res.data.datas[0].license_id}
        dataedit.value[1] = {type_tools:res.data.datas[0].type_tools,type:res.data.datas[0].type,model:res.data.datas[0].model,approved:res.data.datas[0].approved,id:res.data.datas[0].type_car}
        dataedit.value[2] = res.data.datas[0].number
        dataedit.value[3] = res.data.datas[0].status,
        dataedit.value[4] = res.data.datas[0].file_tools
        dataedit.value[5] = res.data.datas[0].img_tools
        Swal.close();
        openModaledit()
      }
    }else{
      Swal.close();
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ดึงข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
}

async function editdata() {
  if(!dataedit.value[0] || !dataedit.value[1] || !dataedit.value[2] || !dataedit.value[3]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`tools`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 2,
      datas: [dataeditid.value,dataedit.value[0],dataedit.value[1],dataedit.value[2],dataedit.value[3],datapdf.value,dataimg.value],
    });
    if(res.data.status == 1){
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaledit()
      Swal.fire({
        title: "สำเร็จ",
        text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "แก้ไขข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 14,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '15%', targets: 0 },
        { width: '15%', targets: 3 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>