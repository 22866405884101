
<template>
    <div class="container-fluid">
      <Breadcrumbs main="สถิติการตรวจสภาพ(ประเภทรถ)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                    
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">เลือกประเภทรถ</label>
                        <div class="col-sm-5" >
                            <select v-model="car_type" class="form-select">
                                <option value="">กรุณาเลือก</option>
                                <option value="1">ประจำทาง</option>
                                <option value="2">ไม่ประจำทาง</option>
                                <option value="3">ส่วนบุคคล</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ตั้งแต่วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >ถึง</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_end" auto-apply :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="statistics" type="button">ดูรายงาน</button>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countdata.count_status" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <div class="needs-validation">
                  <div class="row">
                        <div id="printableTable" class="col-sm-12 text-center table-responsive">
                            <table class="table table-bordered" ref="tbdata">
                                <tr>
                                    <th colspan="3"><h4>สถิติตรวจสภาพรถ ประเภทรถ : {{ cartype }}</h4></th>
                                </tr>
                                <tr>
                                    <th colspan="3">ตั้งแต่วันที่ {{new Date(date_start).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}} ถึงวันที่ {{new Date(date_end).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')}}</th>
                                </tr>
                                <tr>
                                    <th colspan="3">จำนวนรถที่ตรวจสภาพ (คัน)</th>
                                </tr>
                                <tr>
                                    <td>ผ่าน</td>
                                    <td>ไม่ผ่าน</td>
                                    <td>รวม</td>
                                </tr>
                                <tr>
                                    <td>{{ countdata.count_true }}</td>
                                    <td>{{ countdata.count_false }}</td>
                                    <td>{{ countdata.count_total }}</td>
                                </tr>
                            </table>
                        </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
data(){
      return{
          car_type:'',
          date_start:'',
          date_end:'',
          countdata:{
              count_true:'',
              count_false:'',
              count_total:'',
              count_status:'',
              },
          }
      }, 
  methods:{
      statistics(){
          if(!this.car_type || !this.date_start || !this.date_end){
            this.$swal({
                title:'คำเตือน', 
                text:'กรุณาเลือกช่วงวันที่ต้องการดูข้อมูลก่อน "ดูรายงาน"',
                icon:'warning', 
              }) 
          }else{
                let data = {
                          uid: storeAuth.id,
                          gid: storeAuth.group_id,
                          dt:121,
                          datas:[this.car_type,this.date_start,this.date_end]
                      }
              this.getcount(data);
          }
      },
      getcount(data){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.$swal.showLoading()
          
          axios.post(`statistics`,data).then(rs=>{
            if(rs.data.status == 1){
                if(rs.data.datas){
                  this.$swal.close();
                  this.countdata = rs.data.datas;
                }else{
                  this.$swal({
                    title:'ไม่พบข้อมูล', 
                    text:'ไม่พบข้อมูลสถิติตามที่ต้องการค้นหา',
                    icon:'info', 
                  }) 
                }
              }else{
                this.$swal({
                  title:'เกิดข้อผิดพลาด', 
                  text:'ไม่สามารถค้นหาข้อมูลได้',
                  icon:'error', 
                }) 
              }
            }).catch(err=>{
              this.$swal.close();
              console.log(err)
          })
      },
      excelExport(type){
          var data = this.$refs.tbdata;
          var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
          XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
          XLSX.writeFile(excelFile, 'สถิติการตรวจสภาพ(ประเภทรถ).' + type);
        },
        async printDiv() {
          await this.$htmlToPaper('printableTable');
        }
  }, 
  computed:{
      cartype(){
          if(this.car_type == 1){
              return 'ประจำทาง';
          }else if(this.car_type == 2){
              return 'ไม่ประจำทาง';
          }else if(this.car_type == 3){
              return 'ส่วนบุคคล';
          }
      },
  }, 
  watch: {}, 
  components:{}, 
  beforeCreate(){}, 
  created(){}, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>