
<template>

    <div class="container-fluid">
        <Breadcrumbs main="วิธีการใช้งานโปรแกรมและคำถามที่พบบ่อย"/>

        
        <div class="container-fluid">
            <div class="faq-wrap">
              <div class="row">
                <div class="col-xl-4 xl-100 box-col-6">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>สรุปการใช้งาน</h5>
                          <p>ขั้นตอนการทำงานนั้นจาก เริ่มการตั้งค่าข้อมูลตรวจสภาพรถในการตัวแลของตัวเอง(ระดับจังหวัด) > เพิ่มข้อูลผู้ตรวจสภาพ > เช็คข้อมูลรายงานผลการตรวจสภาพย้อนหลังได้ที่เมนู "ข้อมูลการตรวจสภาพ" > ตรวจสอบสถานตรวจสภาพและผู้ตรวจสภาพ.</p>
                        </div><i data-feather="check-square"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 xl-50 col-sm-6 box-col-6">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>คำเตือน</h5>
                          <p>การใช้งานทุกหน้าของระบบ V6 นั้นให้ท่านคำนึงถึงข้อมูลและความถุฏต้องของข้อมูลนั้นๆ เพื่อป้องกันการเกิดข้อผิดพลาดตามมา และถ้าหากพบข้อผิดพลาดของตัวระบบสามารถแจ้งแอดมินผู้ดูแลระบบให้ทราบเพื่อตรวจสอบแล้วทำการปรับปรุงแก้ไขต่อไป.</p>
                        </div><i data-feather="alert-triangle"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 xl-50 col-sm-6 box-col-12">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>ข้อมูลการติดต่อผู้ดูแลระบบ</h5>
                          <p>
                            ที่อยู่ : กรมการขนส่งทางบก 1032 ถนนพหลโยธิน แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900.<br>
                            โทรศัพท์ (หมายเลขกลาง) : 0-2271-8888 โทรสาร : 0-2271-8805 (เฉพาะวันเละเวลาราชการเท่านั้น)<br>
                          </p>
                        </div><i data-feather="phone-call"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="header-faq">
                    <h5 class="mb-0">วิดีโอสอนการใช้งานต่างๆของโปรแกรม V6</h5>
                  </div>
                  <div class="row default-according style-1 faq-accordion" id="accordionocv1" style="font-size: 18px;">
                    <div class=" col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon31" aria-expanded="false" aria-controls="collapseicon"><i data-feather="video"></i> วิดีโอสอนการตั้งค่าข้อมูลตรวจสภาพรถ โปรแกรม Inspection V6</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon31" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                          <div class="card-body">
                            <div class="videoWrapper">
                              <iframe width="100%" src="https://www.youtube.com/embed/LHN1KUtYfew" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon32" aria-expanded="false" aria-controls="collapseicon"><i data-feather="video"></i> วิดีโอ ขั้นตอนการดาวโหลด โปรแกรม Inspection V6</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon32" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                          <div class="card-body">
                            <div class="videoWrapper">
                              <iframe width="100%" src="https://www.youtube.com/embed/-3TZU7wubU8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon33" aria-expanded="false" aria-controls="collapseicon"><i data-feather="video"></i> วิดีโอ ขั้นตอนการตรวจสอบค่าข้อมูลตรวจสภาพ V5</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon33" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                          <div class="card-body">
                            <div class="videoWrapper">
                              <iframe width="100%" src="https://www.youtube.com/embed/z0zNRbeGHbw" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon34" aria-expanded="false" aria-controls="collapseicon"><i data-feather="video"></i> วิดีโอ ขั้นตอนการติดตั้ง โปรแกรม Inspection V6</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon34" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                          <div class="card-body">
                            <div class="videoWrapper">
                              <iframe width="100%" src="https://www.youtube.com/embed/8egAyC_t1QU" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="header-faq">
                    <h5 class="mb-0">วิธีการใช้งานเมนูต่างๆ</h5>
                  </div>
                  <div class="row default-according style-1 faq-accordion" id="accordionoc">
                    <div class=" col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon" aria-expanded="false" aria-controls="collapseicon"><i data-feather="clipboard"></i> เมนู "แดชบอร์ด"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                          <div class="card-body">เป็นเมนูที่สรุปรายงานผลการตรวจประจำวันโดยจะแยกตามกฎหมายว่าด้วยรถยนต์และรถขนส่งทางบก โดยจะแสดงรายการสุปเป็นระยะเวลา 7 วันล่าสุดที่ผ่านมา.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon2" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i> เมนู "ข่าวสาร/การแจ้งเตือน"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon2" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูแจ้งข่าวสารจากทางกรมขนส่งทางบกและทางจังหวัดนั้นๆ ที่สถานตรวจสภาพรถของท่านได้ขึ้นทะเบียน.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon7" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลการตรวจสภาพ > รถตามกฎหมายรถยนต์"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon7" data-bs-parent="#accordionoc">
                          <div class="card-body">เป็นราบการตรวจข้อมูลของรถตามกฎหมายรถยนต์ 1000 รายการล่าสุด โดยสามารถค้นหารายการย้อนหลังได้ และยังสามารถดูและปริ้นรายละเอียดของรายการตรวจสภาพที่ต้องการย้อนหลังได้.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon8" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลการตรวจสภาพ > รถตามกฎหมายขนส่ง"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon8" data-bs-parent="#accordionoc">
                          <div class="card-body">เป็นราบการตรวจข้อมูลของรถตามกฎหมายว่าด้วยขนส่งทางบก 1000 รายการล่าสุด โดยสามารถค้นหารายการย้อนหลังได้ และยังสามารถดูและปริ้นรายละเอียดของรายการตรวจสภาพที่ต้องการย้อนหลังได้.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon15" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูล สถานตรวจสภาพรถ > เพิ่มข้อมูล สถานตรวจสภาพรถ"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon15" data-bs-parent="#accordionoc">
                          <div class="card-body">เป็นเมนูสำหรับเพิ่มข้อมูลสถานตรวจสภาพรถโดยเริ่มจาก "ใบอนุญาตจัดตั้ง > สถานตรวจสภาพ > ผู้มีอำนาจลองนาม > เครื่องตรวจสภาพรถที่ใช้สำหรับวัดค่าต่างๆ" โดยระบบจะช่วยบันทึกตามขั้นตอนตามลำดับเพื่อให้ง่ายต่อการทำงาน.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon16" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูล สถานตรวจสภาพรถ > ค้นหาข้อมูล สถานตรวจสภาพรถ"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon16" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับค้นหาข้อมูลสถานตรวจสภาพรถ และสามารถแก้ไขหรือลบได้อีกด้วย.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon17" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการข้อมูล สถานตรวจสภาพรถ > ผู้ได้รับใบอนุญาตจัดตั้ง."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon17" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของใบอนุญาตจัดตั้งสถานตรวจสภาพ (เป็นเมนูแยกเฉพาะเอาไว้จัดการข้อมูล ในอนุญาตจัดตั้ง เท่านั้น).</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon18" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการข้อมูล สถานตรวจสภาพรถ > สถานตรวจสภาพรถ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon18" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของผู้ตรวจสภาพรถ (เป็นเมนูแยกเฉพาะเอาไว้จัดการข้อมูล ผู้ตรวจสภาพรถ เท่านั้น).</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon19" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการข้อมูล สถานตรวจสภาพรถ > ผู้ตรวจสภาพรถ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon19" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของสถานตรวจสภาพรถ (เป็นเมนูแยกเฉพาะเอาไว้จัดการข้อมูล สถานตรวจสภาพรถ เท่านั้น).</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon110" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการข้อมูล สถานตรวจสภาพรถ > ผู้มีอำนาจลงนามผูกพัน."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon110" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของผู้มีอำนาจลงนามผูกพัน (เป็นเมนูแยกเฉพาะเอาไว้จัดการข้อมูล ผู้มีอำนาจลงนามผูกพัน เท่านั้น).</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon111" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการข้อมูล สถานตรวจสภาพรถ > ผู้ได้รับมอบอำนาจ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon111" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของผู้ได้รับมอบอำนาจ (เป็นเมนูแยกเฉพาะเอาไว้จัดการข้อมูล ผู้ได้รับมอบอำนาจ เท่านั้น).</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon112" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "เครื่องตรวจสภาพรถ. > จัดการเครื่องตรวจสภาพรถตรวจสภาพ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon112" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของเครื่องตรวจสภาพรถตรวจสภาพ.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon113" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "เครื่องตรวจสภาพรถ. > จัดการประเภทของเครื่องตรวจสภาพรถ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon113" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของประเภทเครื่องตรวจสภาพรถ.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon114" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "การตรวจสอบสภาน สถานตรวจสภาพรถ > ข้อมูลผู้ตรวจสอบ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon114" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของผู้ที่มีหน้าที่ตรวจสอบสภานตรวจสภาพ โดยสามารถเพิ่มตำแหน่งตามรายละเอียดได้.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon115" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "การตรวจสอบสภาน สถานตรวจสภาพรถ > ข้อมูลการตรวจสอบสภานที่ตรวจสภาพ. > เพิ่มการตรวจสอบสถานตรวจสภาพ"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon115" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่มรายการตรวจสอบสภานตรวจสภาพรถซึ่งในการตรวจสอบนั้นจะมีผลเกี่ยวกับบทลงโทษด้วยทำให้มีผลกับการล็อคอินเข้าใช้งานระบบด้วย.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon116" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "การตรวจสอบสภาน สถานตรวจสภาพรถ > ข้อมูลการตรวจสอบสภานที่ตรวจสภาพ. > รายการตรวจสอบสถานตรวจสภาพ"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon116" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับแก้ไข ลบ รายการตรวจสอบสภานตรวจสภาพรถซึ่งในเมนูนี้รายการทั้งหมดจะเป็นรายการที่ถูกการแก้ไขข้อบกพร่องแล้วหรือรายการที่ไม่มีบทลงโทษร้ายแรง.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon117" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "การตรวจสอบสภาน สถานตรวจสภาพรถ > ข้อมูลการตรวจสอบสภานที่ตรวจสภาพ. > รายการตรวจสอบสถานตรวจสภาพ(ข้อมูลการลงโทษ)"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon117" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับแก้ไข ลบ รายการตรวจสอบสภานตรวจสภาพรถซึ่งในเมนูนี้รายการทั้งหมดจะเป็นรายการที่มีบทลงโทษร้านแรงและรอการแก้ไขอยู่.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon118" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "สถิติต่างๆ"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon118" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับตรวจข้อมูลทางสถิติ สามารถนำข้อมูลที่ได้ส่งออกเป็นไฟล์ excel เพื่อนำไปใช้ประโยชน์ต่อไปได้.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon119" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการผู้ใช้งาน > เจ้าหน้าที่ สขพ. / สขจ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon119" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของบุคคลที่เป็นระดับ สขพ. / สขจ.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon120" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการผู้ใช้งาน > กลุ่มสถิติการขนส่ง."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon120" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของบุคคลที่เป็นระดับ กลุ่มสถิติการขนส่ง.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon121" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "จัดการผู้ใช้งาน > สำนักวิศวกรรมยานยนต์."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon121" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของบุคคลที่เป็นระดับ สำนักวิศวกรรมยานยนต์.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon9" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลระบบกล้อง CCTV > เพิ่มข้อมูลระบบกล้อง CCTV"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon9" data-bs-parent="#accordionoc">
                          <div class="card-body">ท่านสามารถเพิ่มข้อมูลกล้อง CCTV ของสถานตรวจสภาพของท่านได้ที่เมนูนี้โดยท่านต้องกรอกข้อมูลให้ครบถ้วนก่อนกดปุ่ม "บันทึก".</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon10" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลระบบกล้อง CCTV > แก้ไข/ลบ ข้อมูลระบบกล้อง CCTV"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon10" data-bs-parent="#accordionoc">
                          <div class="card-body">ท่านสามารถแก้ไขหรือลบข้อมูลกล้อง CCTV ของสถานตรวจสภาพของท่านได้ที่เมนูนี้ โปรดตรวจสอบให้แน่ใจก่อนทำรายการทุกครั้ง.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon122" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข่าวประชาสัมพันธ์ > เพิ่มข่าวประชาสัมพันธ์ / รายการข่าวประชาสัมพันธ์."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon122" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของข่าวประชาสัมพันธ์ที่จะส่งข้อมูลข่าวสารแจ้งเตือนไปยังผู้ใช้ในระบบทุกระดับ โดยแบ่งแยกเป็น ข่าวที่แสดงทุกจังหวัด และข่าวที่แสดงในจังหวัดนั้น ขึ้นอยู่กับการเลือกจังหวัดตอนเพิ่มข่าวประชาสัมพันธ์</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon123" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข่าวประชาสัมพันธ์ > เพิ่มข่าวประชาสัมพันธ์ / Pop up แจ้งเตือนข้อความ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon123" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ข้อมูลที่แสดงขึ้นมาที่หน้าเว็บไซต์ทุกครั้งและทุกคนที่หน้าล็อคอิน สามารถเพิ่มเนื้อหาได้โดยใส่หัวข้อ บทความสั้นๆ และรูปภาพขนาดใหญ่ (ขนาดที่เหมาะสมจะอยู่ที่ 1000px X 500px)</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon124" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลยี่ห้อรถ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon124" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของข้อมูลยี่ห้อรถต่างๆ.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon125" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลจังหวัด."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon125" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของข้อมูลจังหวัด.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon126" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลอำเภอ."</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon126" data-bs-parent="#accordionoc">
                          <div class="card-body">เมนูสำหรับเพิ่ม แก้ไข ลบ ข้อมูลของข้อมูลอำเภอ.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon14" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลส่วนตัว > เปลี่ยนรหัสผ่าน"</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon14" data-bs-parent="#accordionoc">
                          <div class="card-body">ท่านสามารถเปลี่ยนรหัสผ่านการเข้าใช้งานของสถานตรวจสภาพรถของท่านได้ที่นี่.</div>
                        </div>
                      </div>
                      <div class="faq-title">
                        <h6>คำถามที่พบบ่อย</h6>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon21" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="help-circle"></i> สามารถติดต่อผู้ดูแลได้จากช่องทางไหนบ้าง ?</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon21" data-bs-parent="#accordionoc">
                          <div class="card-body">ท่านสามารถติดต่อผู้ดูแลระบบได้ที่ .</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon22" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="help-circle"></i> การแสดงผลหน้าจอดูยาก ข้อความไม่เรียงกันในแถวเดียวกัน ช่องกรอกข้อมูลอยู่ผิดตำแหน่ง ?</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon22" data-bs-parent="#accordionoc">
                          <div class="card-body">ท่านสามารถปรับการแสดงผลหน้าจอได้ตามขนาดหน้าจอของเครื่องที่ใช้อยู่ได้โดยลักษณะและตำแหน่งต่างๆบนหน้าเว็บไซต์จะแสดงผลได้ดีที่ขนาดหน้าจอ 1680 x 1050 และ การ Zoom ของ เว็บเบราว์เซอร์ อยู่ที่ 100% .</div>
                        </div>
                      </div>
                      <div class="faq-title">
                        <h6>ข้อเสนอแนะ</h6>
                      </div>
                       
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon32" aria-expanded="false"><i data-feather="alert-circle"></i> ระบบจะทำงานได้ดีเมื่อใช้เว็บเบราว์เซอร์ดังต่อไปนี้ !</button>
                          </h5>
                        </div>
                        <div class="collapse" id="collapseicon32" aria-labelledby="collapseicon32" data-bs-parent="#accordionoc">
                          <div class="card-body">
                          Internet Explorer 9  ขึ้นไป หรือ<br>
                          <b class="text-warning">Firefox</b> เวอร์ชั่นล่าสุด >>> <a class="_blank" href="https://www.mozilla.org/th/firefox/new/?gclid=CjwKEAjww_a8BRDB-O-OqZb_vRASJAA9yrc5tHYaVq1RnmMknAHY9hIRefb1Dnpk4HlkB5I8mRAj6xoCl3jw_wcB" target="_blank">ดาวน์โหลดที่นี่</a> <br>
                          <b class="text-danger">Chrome</b> เวอร์ชั่นล่าสุด >>> <a class="_blank" href="https://www.google.com/intl/th/chrome/browser/" target="_blank">ดาวน์โหลดที่นี่</a>
                          </div>
                        </div>
                      </div>
                   
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

  </div>
    <!-- </div> -->
</template>

<script setup>

</script>